<template>
  <BaseLayout :title="title">
    <div class="checkout-board">
      <div class="checkout-board-main">
        <CheckoutItemList
          :variant-list="variantList"
          @add="addVariant"
          @subtract="subtractVariant"
        />
      </div>
      <div class="checkout-board-compute">
        <CheckoutCompute :total="totalCost" :types="types" />
        <footer class="checkout-board-compute-footer">
          <div class="checkout-amount-status" :class="{ 'is-danger': diffTotal > 0 }">
            <template v-if="changeAmount > 0">
              <strong>{{ $t('checkout.change') }}</strong>
              <strong>{{ changeAmount | currency }}</strong>
            </template>
            <template v-else>
              <strong>{{ $t('checkout.balance') }}</strong>
              <strong>{{ diffTotal | currency }}</strong>
            </template>
          </div>
          <b-row>
            <b-col>
              <InlineButton :to="{ name: 'Orders' }" variant="outline-dark" size="md" block>
                {{ $t('checkout.buttonCancel') }}
              </InlineButton>
            </b-col>
            <b-col>
              <InlineButton
                @click="onSubmit()"
                variant="success"
                size="md"
                block
                :disabled="isDisabled"
              >
                <b-spinner v-if="isLoading" small />
                <strong v-else class="text-uppercase">
                  {{ $t('checkout.buttonComplete') }}
                </strong>
              </InlineButton>
            </b-col>
          </b-row>
        </footer>
      </div>
    </div>
  </BaseLayout>
</template>

<script>
import { mapGetters } from 'vuex';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import { SALE_REQUEST } from '@/store/actions/order';
import meMixin from '@/mixins/meMixin';
import BaseLayout from '@/components/ui/BaseLayout';
import CheckoutItemList from '@/components/manage/checkout/CheckoutItemList';
import CheckoutCompute from '@/components/manage/checkout/CheckoutCompute';
import InlineButton from '@/components/ui/button/InlineButton';

export const PAYMENT_TYPES = {
  CASH: 'CASH',
  CARD: 'CARD',
  DISCOUNT: 'DISCOUNT',
  BANK_TRANSFER: 'BANK_TRANSFER',
  QPAY: 'QPAY',
  OTHER: 'OTHER',
};

export default {
  mixins: [validationMixin, meMixin],

  props: {
    title: String,
  },

  data() {
    return {
      variantList: [],
      qpayInvoiceIsPaid: false,
      qpayInvoiceInterval: null,
      qpayInvoiceLeftSeconds: 0,
      qpayInvoiceRequested: false,
      discountTypeIndex: 2,
      types: [
        {
          paymentType: PAYMENT_TYPES.CASH,
          name: this.$t('paymentTypes.cash'),
          amount: 0,
        },
        {
          paymentType: PAYMENT_TYPES.CARD,
          name: this.$t('paymentTypes.card'),
          amount: 0,
        },
        {
          paymentType: PAYMENT_TYPES.DISCOUNT,
          name: this.$t('paymentTypes.discount'),
          amount: 0,
        },
        {
          paymentType: PAYMENT_TYPES.BANK_TRANSFER,
          name: this.$t('paymentTypes.bankTransfer'),
          amount: 0,
        },
        {
          paymentType: PAYMENT_TYPES.OTHER,
          name: this.$t('paymentTypes.other'),
          amount: 0,
        },
      ],
    };
  },

  validations() {
    return {
      variantList: {
        required,
      },
    };
  },

  watch: {
    variantList: {
      handler() {
        if (this.types[this.discountTypeIndex].amount > 0) {
          this.types[this.discountTypeIndex].amount = 0;
          this.$bvToast.toast(this.$t('checkout.discountNeedRecalculated'), {
            title: this.$t('toast.warning'),
            variant: 'warning',
            solid: true,
          });
        }
      },
    },
  },

  computed: {
    ...mapGetters(['saleStatus']),
    isLoading() {
      return this.saleStatus === 'loading';
    },
    totalCost() {
      const totalProductAmount = this.variantList.reduce(
        (total, item) =>
          total +
          (item.salePrice ? item.salePrice.amount.amount : item.masterPrice.amount.amount) *
            item.quantity,
        0
      );
      return totalProductAmount;
    },
    totalType() {
      return this.types.reduce((total, item) => total + item.amount * 100, 0);
    },
    diffTotal() {
      return this.totalCost - this.totalType;
    },
    changeAmount() {
      return this.totalType - this.totalCost;
    },
    isDisabled() {
      if (this.$v.variantList.$invalid || this.isLoading) {
        return true;
      }
      return false;
    },
  },

  methods: {
    addVariant(variant) {
      const index = this.variantList.findIndex(item => item.variantId === variant.variantId);
      if (index > -1) {
        this.variantList[index].quantity += 1;
        return;
      }
      this.variantList.push({ ...variant, quantity: 1 });
    },
    subtractVariant(variantId) {
      const index = this.variantList.findIndex(item => item.variantId === variantId);
      if (index > -1) {
        if (this.variantList[index].quantity < 2) {
          this.variantList.splice(index, 1);
          return;
        }
        this.variantList[index].quantity -= 1;
      }
    },
    async onSubmit() {
      if (this.diffTotal > 0) {
        this.$bvToast.toast(this.$t('checkout.pleaseMakeSureBalanceZero'), {
          title: this.$t('toast.warning'),
          variant: 'danger',
          solid: true,
        });
        return;
      }

      const payments = this.types
        .filter(item => item.amount > 0)
        .map(item => {
          const payment = {
            paymentType: item.paymentType,
            paidAmount: Math.round(item.amount * 100),
          };
          return payment;
        });

      const payload = {
        businessId: this.me.businessId,
        items: this.variantList.map(item => {
          return {
            variant: {
              id: item.variantId,
            },
            quantity: item.quantity,
          };
        }),
        payments,
      };

      await this.$store.dispatch(SALE_REQUEST, payload);

      if (this.saleStatus === 'success') {
        this.$router.push({ name: 'Orders' });
      }
    },
  },

  components: {
    BaseLayout,
    CheckoutItemList,
    CheckoutCompute,
    InlineButton,
  },
};
</script>

<style lang="scss" scoped>
@import '../../sass/variables';
@import '../../sass/flexBox';

.checkout-board {
  background-color: $white;
  text-align: left;
  color: $black;

  &::v-deep {
    p {
      margin-bottom: 0;
    }

    .row {
      margin: -0.25rem !important;

      & > div {
        padding: 0.25rem !important;
      }
    }
  }

  .checkout-board-compute {
    & > * {
      padding: 1.5rem;
      padding-bottom: 0;

      & + * {
        padding-top: 0;
        padding-bottom: 1.5rem;
      }
    }
  }

  .checkout-amount-status {
    padding: 1rem 1.5rem;
    background-color: $green;
    border-radius: 4px;
    margin-bottom: 0.5rem;
    color: $white;
    @include flexBox(flex, center, space-between);

    &.is-danger {
      background-color: $red;
    }
  }
}

@media (min-width: $breakpoint-lg) {
  .checkout-board {
    border-top: 3px solid $gray-200;
    height: 100%;
    @include flexBox(flex, stretch, flex-start);

    .checkout-board-main {
      width: calc(100% - 360px);
      border-right: 3px solid $gray-200;
    }

    .checkout-board-compute {
      width: 360px;
      @include flexBox(flex, stretch, space-between, column);
    }
  }
}

@media (min-width: $breakpoint-xl) {
  .checkout-board {
    .checkout-board-compute {
      & > * {
        padding: 1.5rem;
        padding-bottom: 0;

        & + * {
          padding-top: 0;
          padding-bottom: 1.5rem;
        }
      }
    }
  }
}

@media (min-width: $breakpoint-md) {
}

@media (min-width: $breakpoint-lg) {
}

@media (min-width: $breakpoint-xxl) {
}
</style>
