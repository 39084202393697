export const INVENTORY_LIST_REQUEST = 'INVENTORY_LIST_REQUEST';
export const INVENTORY_LIST_REQUEST_SUCCESS = 'INVENTORY_LIST_REQUEST_SUCCESS';
export const INVENTORY_LIST_REQUEST_ERROR = 'INVENTORY_LIST_REQUEST_ERROR';

export const INVENTORY_CREATE = 'INVENTORY_CREATE';
export const INVENTORY_CREATE_SUCCESS = 'INVENTORY_CREATE_SUCCESS';
export const INVENTORY_CREATE_ERROR = 'INVENTORY_CREATE_ERROR';

export const INVENTORY_UPDATE = 'INVENTORY_UPDATE';
export const INVENTORY_UPDATE_SUCCESS = 'INVENTORY_UPDATE_SUCCESS';
export const INVENTORY_UPDATE_ERROR = 'INVENTORY_UPDATE_ERROR';

export const INVENTORY_DELETE = 'INVENTORY_DELETE';
export const INVENTORY_DELETE_SUCCESS = 'INVENTORY_DELETE_SUCCESS';
export const INVENTORY_DELETE_ERROR = 'INVENTORY_DELETE_ERROR';
