<template>
  <BaseLayout :title="title">
    <template #actions>
      <DateRangePicker :config="datePicker" v-model="dateRange" @clickUpdate="clickUpdate" />
      <InlineButton
        @click="$emit('download')"
        :loading="isDownloading"
        pill
        shadow
        variant="secondary"
      >
        {{ $t('report.export') }}
      </InlineButton>
    </template>
    <slot></slot>
  </BaseLayout>
</template>

<script>
import BaseLayout from '@/components/ui/BaseLayout';
import DateRangePicker from '@/components/ui/input/DateRangePicker';
import InlineButton from '@/components/ui/button/InlineButton';

export default {
  props: {
    title: String,
    dateRange: [Boolean, Object],
    datePicker: [Boolean, Object],
    isDownloading: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    clickUpdate(value) {
      this.$emit('click', value);
    },
  },

  components: {
    BaseLayout,
    DateRangePicker,
    InlineButton,
  },
};
</script>
