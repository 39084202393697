<template>
  <TableBox>
    <b-table
      sticky-header
      id="my-table"
      :fields="fields"
      :items="list"
      :busy="isLoading"
      class="custom-table"
      thead-class="custom-table-header"
      tbody-tr-class="custom-table-body"
      :emptyText="$t('text.emptyText')"
      show-empty
    >
      <template #cell(index)="data">
        {{ data.index + 1 }}
      </template>

      <template #cell(createdAt)="data">
        <span class="text-nowrap">
          {{ data.item.createdAt | moment('MMM DD, YYYY') }}
        </span>
      </template>

      <template #cell(status)="data">
        <Status :value="data.item.status" />
      </template>

      <template #cell(type)="data">
        {{ getType(data.item.type) }}
      </template>

      <template #cell(action)="data">
        <div class="table-action-buttons">
          <InlineButton
            variant="outline-secondary"
            size="sm"
            pill
            @click="$emit('edit', data.item.id)"
            class="has-shadow"
          >
            {{ $t('text.editButton') }}
          </InlineButton>
        </div>
      </template>
    </b-table>
  </TableBox>
</template>

<script>
import TableBox from '@/components/ui/TableBox';
import Status from '@/components/ui/Status';
import InlineButton from '@/components/ui/button/InlineButton';

export default {
  props: {
    list: Array,
    isLoading: Boolean,
    typeOptions: Array,
  },

  data() {
    return {
      fields: [
        {
          key: 'index',
          label: '#',
        },
        {
          key: 'name',
          label: this.$t('table.th.name'),
        },
        {
          key: 'isFilterable',
          label: this.$t('table.th.isFilterable'),
        },
        {
          key: 'type',
          label: this.$t('table.th.type'),
        },
        {
          key: 'action',
          label: this.$t('table.th.settings'),
        },
      ],
    };
  },

  methods: {
    getType(type) {
      const item = this.typeOptions.find(typeOption => typeOption.value === type);
      if (item) {
        return item.name;
      }
      return '';
    },
  },

  components: {
    TableBox,
    Status,
    InlineButton,
  },
};
</script>
