import { find, findIndex } from 'lodash';
import {
  BLOG_LIST_REQUEST,
  BLOG_LIST_REQUEST_ERROR,
  BLOG_LIST_REQUEST_SUCCESS,
  BLOG_CREATE,
  BLOG_CREATE_SUCCESS,
  BLOG_CREATE_ERROR,
  BLOG_UPDATE,
  BLOG_UPDATE_SUCCESS,
  BLOG_UPDATE_ERROR,
  BLOG_DELETE,
  BLOG_DELETE_SUCCESS,
  BLOG_DELETE_ERROR,
} from '@/store/actions/blog';
import { handleRequestError } from '@/utils/error';
import Repository, { BLOGS } from '@/repositories/RepositoryFactory';

const BlogsRepository = Repository.get(BLOGS);

const state = {
  status: '',
  blog: {},
  blogList: [],
  blogListMeta: {},
  blogListStatus: '',
};

const getters = {
  blog: state => state.blog,
  blogStatus: state => state.status,
  blogList: state => state.blogList,
  blogListMeta: state => state.blogListMeta,
  blogListStatus: state => state.blogListStatus,
  getBlogById: (state, getters) => id => {
    if (getters.blogList.length > 0) {
      return find(getters.blogList, { id });
    }
    return state.blog;
  },
};

const actions = {
  [BLOG_LIST_REQUEST]: async ({ commit }, payload) => {
    commit(BLOG_LIST_REQUEST);
    try {
      const response = await BlogsRepository.get(payload);
      commit(BLOG_LIST_REQUEST_SUCCESS, response.data);
    } catch (error) {
      commit(BLOG_LIST_REQUEST_ERROR);
      handleRequestError(error);
    }
  },
  [BLOG_CREATE]: async ({ commit }, payload) => {
    commit(BLOG_CREATE);
    try {
      const response = await BlogsRepository.create(payload);
      commit(BLOG_CREATE_SUCCESS, response.data.data);
    } catch (error) {
      commit(BLOG_CREATE_ERROR);
      handleRequestError(error);
    }
  },
  [BLOG_UPDATE]: async ({ commit }, { id, data }) => {
    commit(BLOG_UPDATE);
    try {
      const response = await BlogsRepository.update(id, data);
      commit(BLOG_UPDATE_SUCCESS, response.data.data);
    } catch (error) {
      commit(BLOG_UPDATE_ERROR);
      handleRequestError(error);
    }
  },
  [BLOG_DELETE]: async ({ commit }, id) => {
    commit(BLOG_DELETE);
    try {
      await BlogsRepository.delete(id);
      commit(BLOG_DELETE_SUCCESS, id);
    } catch (error) {
      commit(BLOG_DELETE_ERROR);
      handleRequestError(error);
    }
  },
};

/* eslint-disable no-param-reassign */
const mutations = {
  [BLOG_LIST_REQUEST]: state => {
    state.blogListStatus = 'loading';
  },
  [BLOG_LIST_REQUEST_SUCCESS]: (state, payload) => {
    state.blogListStatus = 'success';
    state.blogList = payload.data;
    if (payload.meta) {
      state.blogListMeta = payload.meta;
    }
  },
  [BLOG_LIST_REQUEST_ERROR]: state => {
    state.blogListStatus = 'error';
  },
  [BLOG_CREATE]: state => {
    state.status = 'loading';
  },
  [BLOG_CREATE_SUCCESS]: (state, payload) => {
    state.status = 'success';
    state.blogList.push(payload);
  },
  [BLOG_CREATE_ERROR]: state => {
    state.status = 'error';
  },
  [BLOG_UPDATE]: state => {
    state.status = 'loading';
  },
  [BLOG_UPDATE_SUCCESS]: (state, payload) => {
    state.status = 'success';
    state.blogList = state.blogList.map(item => {
      if (item.id === payload.id) {
        return payload;
      }
      return item;
    });
  },
  [BLOG_UPDATE_ERROR]: state => {
    state.status = 'error';
  },
  [BLOG_DELETE]: state => {
    state.status = 'deleting';
  },
  [BLOG_DELETE_SUCCESS]: (state, payload) => {
    state.status = 'deleted';
    const index = findIndex(state.blogList, { id: payload });
    state.blogList.splice(index, 1);
  },
  [BLOG_DELETE_ERROR]: state => {
    state.status = 'error';
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
