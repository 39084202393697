<template>
  <BaseLayout :title="title">
    <template #actions>
      <InlineButton @click="onCreate" pill shadow>
        <span class="text-uppercase">New</span>
      </InlineButton>
    </template>
    <staffTable
      :staffList="staffList"
      :current-page="currentPage"
      :meta="staffListMeta"
      :is-loading="isLoading"
      :type-list="userTypeList"
      @edit="onEdit"
      @onChangePage="onChangePage"
    />
    <StaffAddEditModal :staff="staffToEdit" :type-list="userTypeList" @onHidden="emptyingStaff" />
  </BaseLayout>
</template>
<script>
import { mapGetters } from 'vuex';
import { STAFF_LIST_REQUEST } from '@/store/actions/staff';
import { KEY_FILTER_TYPE } from '@/utils/filter';
import { STAFF_TYPES } from '@/utils/constants';
import BaseLayout from '@/components/ui/BaseLayout';
import StaffTable from '@/components/manage/staff/StaffTable';
import InlineButton from '@/components/ui/button/InlineButton';
import StaffAddEditModal from '@/components/manage/staff/StaffAddEditModal';

export default {
  props: {
    title: String,
  },
  components: {
    BaseLayout,
    StaffTable,
    InlineButton,
    StaffAddEditModal,
  },
  data() {
    return {
      currentPage: 1,
      staffIdToEdit: null,
      userType: 'staff',
      userTypeList: STAFF_TYPES,
    };
  },
  computed: {
    ...mapGetters(['staffList', 'staffListMeta', 'staffListStatus', 'getStaffById']),
    staffToEdit() {
      return this.staffIdToEdit ? this.getStaffById(this.staffIdToEdit) : null;
    },
    isLoading() {
      return this.staffListStatus === 'loading';
    },
  },
  created() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      const payload = {
        limit: 10,
        page: this.currentPage,
        filter: {},
      };
      payload.filter[`${KEY_FILTER_TYPE}`] = this.userType;
      await this.$store.dispatch(STAFF_LIST_REQUEST, payload);
    },
    emptyingStaff() {
      this.staffIdToEdit = null;
    },
    onCreate() {
      this.emptyingStaff();
      this.openModal();
    },
    onEdit(staffId) {
      this.staffIdToEdit = staffId;
      this.openModal();
    },
    openModal() {
      this.$bvModal.show('manage-staff-modal');
    },
    onChangePage(page) {
      this.currentPage = page;
      this.fetchData();
    },
  },
};
</script>
