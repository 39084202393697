export const ADDON_CATEGORY_REQUEST = 'ADDON_CATEGORY_REQUEST';
export const ADDON_CATEGORY_REQUEST_SUCCESS = 'ADDON_CATEGORY_REQUEST_SUCCESS';
export const ADDON_CATEGORY_REQUEST_ERROR = 'ADDON_CATEGORY_REQUEST_ERROR';

export const ADDON_CATEGORY_LIST_REQUEST = 'ADDON_CATEGORY_LIST_REQUEST';
export const ADDON_CATEGORY_LIST_REQUEST_SUCCESS = 'ADDON_CATEGORY_LIST_REQUEST_SUCCESS';
export const ADDON_CATEGORY_LIST_REQUEST_ERROR = 'ADDON_CATEGORY_LIST_REQUEST_ERROR';

export const ADDON_CATEGORY_CREATE = 'ADDON_CATEGORY_CREATE';
export const ADDON_CATEGORY_CREATE_SUCCESS = 'ADDON_CATEGORY_CREATE_SUCCESS';
export const ADDON_CATEGORY_CREATE_ERROR = 'ADDON_CATEGORY_CREATE_ERROR';

export const ADDON_CATEGORY_UPDATE = 'ADDON_CATEGORY_UPDATE';
export const ADDON_CATEGORY_UPDATE_SUCCESS = 'ADDON_CATEGORY_UPDATE_SUCCESS';
export const ADDON_CATEGORY_UPDATE_ERROR = 'ADDON_CATEGORY_UPDATE_ERROR';

export const ADDON_CATEGORY_DELETE = 'ADDON_CATEGORY_DELETE';
export const ADDON_CATEGORY_DELETE_SUCCESS = 'ADDON_CATEGORY_DELETE_SUCCESS';
export const ADDON_CATEGORY_DELETE_ERROR = 'ADDON_CATEGORY_DELETE_ERROR';
