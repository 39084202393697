<template>
  <b-modal
    :id="id"
    size="sm"
    hide-header
    hide-footer
    centered
    v-on="$listeners"
    @ok="onSave"
    @hidden="onHidden"
  >
    <b-form @submit.prevent="onSave">
      <InputSelect
        class="business"
        :label="$t('label.changeStatus')"
        size="md"
        v-model="$v.form.status.$model"
        :vuelidate="$v.form.status"
        :options="activeOptions"
        value-field="value"
        :first-item-label="firstItemLabel"
      />
      <InputText class="note" :label="$t('label.note')" size="md" v-model="form.note" :rows="5" />
      <InlineButton @click="onSave" variant="primary btn-block">
        {{ $t('text.updateButton') }}
      </InlineButton>
    </b-form>
  </b-modal>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import InputSelect from '@/components/ui/input/InputSelect';
import InputText from '@/components/ui/input/InputText';
import InlineButton from '@/components/ui/button/InlineButton';

const defaultValuesForForm = () => ({
  status: null,
  note: '',
});

export default {
  props: {
    id: {
      type: String,
      default: 'confirm-dialog',
    },
    currentStatus: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      form: defaultValuesForForm(),
      options: [
        {
          name: this.$t('status.pending'),
          value: 'PENDING',
        },
        {
          name: this.$t('status.completed'),
          value: 'COMPLETED',
        },
        {
          name: this.$t('status.delivered'),
          value: 'DELIVERED',
        },
        {
          name: this.$t('status.cancel'),
          value: 'CANCEL',
        },
      ],
    };
  },

  validations: {
    form: {
      status: {
        required,
      },
    },
  },

  computed: {
    activeOptions() {
      return this.options.filter(item => item.value !== this.currentStatus);
    },
    firstItemLabel() {
      const item = this.options.find(option => option.value === this.currentStatus);
      return item ? item.name : this.$t('text.changeStatus');
    },
  },

  methods: {
    onSave() {
      if (this.$v) {
        this.$v.form.$touch();
        if (this.$v.form.$anyError) {
          return;
        }
      }

      this.$emit('update', this.form);
    },
    onHidden() {
      this.form = defaultValuesForForm();
      this.$v.$reset();
      this.$emit('onHidden');
    },
  },

  components: {
    InputSelect,
    InputText,
    InlineButton,
  },
};
</script>
