<template>
  <ModalMain
    :id="modalId"
    :title="title"
    :ok-title="saveButtonTitle"
    :loading="false"
    delete-disabled
    @hidden="onHidden"
    @ok="onSave"
    @cancel="onCancel"
  >
    <b-form @submit.prevent="onSave">
      <InputString
        class="name"
        :label="$t('label.name')"
        size="lg"
        v-model="$v.name.$model"
        :vuelidate="$v.name"
        :invalidFeedback="$t('validation.nameRequired')"
      />
    </b-form>
  </ModalMain>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import editModalMixin from '@/mixins/editModalMixin';
import ModalMain from '@/components/ui/ModalMain';
import InputString from '@/components/ui/input/InputString';

export default {
  mixins: [validationMixin, editModalMixin],

  data() {
    return {
      name: '',
    };
  },

  validations: {
    name: {
      required,
    },
  },

  computed: {
    title() {
      return this.$t('optionType.addValue');
    },
    saveButtonTitle() {
      return this.$t('text.addButton');
    },
  },

  methods: {
    resetForm() {
      this.name = '';
      this.$v.$reset();
    },
    onHidden() {
      this.$emit('onHidden');
      this.resetForm();
    },
    onCancel() {
      this.hideEditModal();
    },
    async onSave() {
      if (this.$v) {
        this.$v.name.$touch();
        if (this.$v.name.$anyError) {
          return;
        }
      }

      this.$emit('create', this.name);
      this.hideEditModal();
    },
  },

  components: {
    ModalMain,
    InputString,
  },
};
</script>
