<template>
  <ModalMain
    :id="modalId"
    :title="title"
    :ok-title="saveButtonTitle"
    :loading="isLoading"
    :delete-disabled="!formData"
    @hidden="onHidden"
    @ok="onSave"
    @cancel="onCancel"
    @remove="$emit('onRemove', formData.id)"
  >
    <InputSelect
      v-if="isAdmin"
      class="business"
      :label="$t('label.business')"
      size="lg"
      v-model="form.businessId"
      :options="businessList"
      :first-item-label="$t('label.notSelectBusiness')"
      :first-item-disabled="false"
    />

    <InputString
      class="name"
      :label="$t('label.name')"
      size="lg"
      v-model="$v.form.name.$model"
      :vuelidate="$v.form.name"
    />

    <InputEditor
      :label="$t('label.content')"
      v-model="$v.form.description.$model"
      is-large
      has-image
      :vuelidate="$v.form.description"
    />

    <b-row>
      <b-col cols="12" sm="6">
        <InputLabel>{{ $t('label.image') }}</InputLabel>
        <InputPhoto
          :is-loading="isUploadingPhoto"
          :photo="form.file"
          :vuelidate="$v.form.file"
          @change="onChangePhoto"
          @remove="onDeletePhoto"
        />
      </b-col>
    </b-row>

    <InputCheckBox
      v-if="isAdmin"
      v-model="form.isHome"
      :label="$t('label.showInMainWebsite')"
      class="tax-status mt-3 mb-0"
    />
  </ModalMain>
</template>

<script>
import { isNil } from 'lodash';
import { mapGetters } from 'vuex';
import { validationMixin } from 'vuelidate';
import editModalMixin from '@/mixins/editModalMixin';
import { required } from 'vuelidate/lib/validators';
import { BLOG_CREATE, BLOG_UPDATE } from '@/store/actions/blog';
import { FILE_UPLOAD } from '@/store/actions/file';
import ModalMain from '@/components/ui/ModalMain';
import InputSelect from '@/components/ui/input/InputSelect';
import InputString from '@/components/ui/input/InputString';
import InputPhoto from '@/components/ui/input/InputPhoto';
import InputLabel from '@/components/ui/input/InputLabel';
import InputEditor from '@/components/ui/input/InputEditor';
import InputCheckBox from '@/components/ui/input/InputCheckBox';

const defaultValuesForForm = () => ({
  name: '',
  description: '',
  businessId: null,
  isHome: false,
  file: null,
});

export default {
  mixins: [validationMixin, editModalMixin],

  props: {
    formData: Object,
    businessList: Array,
    isAdmin: {
      type: Boolean,
      default: false,
    },
    businessId: Number,
  },

  data() {
    return {
      form: defaultValuesForForm(),
    };
  },

  validations: {
    form: {
      name: {
        required,
      },
      description: {
        required,
      },
      file: {
        required,
      },
    },
  },

  watch: {
    formData() {
      this.updateForm();
    },
  },

  computed: {
    ...mapGetters(['blogStatus', 'getFileUpload']),
    isLoading() {
      return this.blogStatus === 'loading';
    },
    title() {
      return isNil(this.formData) ? this.$t('blog.newBlog') : this.$t('blog.editBlog');
    },
    saveButtonTitle() {
      return isNil(this.formData) ? this.$t('text.addButton') : this.$t('text.updateButton');
    },
    isUploadingPhoto() {
      return this.getFileUpload.status === 'loading';
    },
  },

  methods: {
    updateForm() {
      this.form = this.formData
        ? {
            name: this.formData.name,
            description: this.formData.description,
            file: this.formData.file,
            businessId: this.formData.businessId,
            isHome: this.formData.isHome,
          }
        : defaultValuesForForm();
      this.$v.$reset();
    },
    resetForm() {
      this.form = defaultValuesForForm();
      this.$v.$reset();
    },
    onHidden() {
      this.$emit('onHidden');
      this.resetForm();
    },
    onCancel() {
      this.hideEditModal();
    },
    async onSave() {
      if (this.$v) {
        this.$v.form.$touch();
        if (this.$v.form.$anyError) {
          return;
        }
      }

      if (!this.isAdmin) {
        this.form.businessId = this.businessId;
      }

      const data = this.form;

      if (isNil(this.formData)) {
        await this.$store.dispatch(BLOG_CREATE, data);
      } else {
        const payload = {
          id: this.formData.id,
          data,
        };
        await this.$store.dispatch(BLOG_UPDATE, payload);
      }
      if (this.blogStatus === 'success') {
        this.hideEditModal();
      }
    },
    async onChangePhoto(e) {
      const file = e.target.files[0];
      await this.$store.dispatch(FILE_UPLOAD, file);
      if (this.getFileUpload.status === 'success') {
        this.form.file = this.getFileUpload.item ? this.getFileUpload.item : null;
      }
    },
    onDeletePhoto() {
      this.form.file = null;
    },
  },

  components: {
    ModalMain,
    InputSelect,
    InputString,
    InputPhoto,
    InputLabel,
    InputEditor,
    InputCheckBox,
  },
};
</script>
