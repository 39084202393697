import { find, findIndex } from 'lodash';
import {
  OPTION_TYPE_LIST_REQUEST,
  OPTION_TYPE_LIST_REQUEST_ERROR,
  OPTION_TYPE_LIST_REQUEST_SUCCESS,
  OPTION_TYPE_CREATE,
  OPTION_TYPE_CREATE_SUCCESS,
  OPTION_TYPE_CREATE_ERROR,
  OPTION_TYPE_UPDATE,
  OPTION_TYPE_UPDATE_SUCCESS,
  OPTION_TYPE_UPDATE_ERROR,
  OPTION_TYPE_DELETE,
  OPTION_TYPE_DELETE_SUCCESS,
  OPTION_TYPE_DELETE_ERROR,
} from '@/store/actions/optionType';
import { handleRequestError } from '@/utils/error';
import Repository, { OPTION_TYPES } from '@/repositories/RepositoryFactory';

const OptionTypesRepository = Repository.get(OPTION_TYPES);

const state = {
  status: '',
  optionType: {},
  optionTypeList: [],
  optionTypeListStatus: '',
};

const getters = {
  optionTypeStatus: state => state.status,
  optionTypeList: state => state.optionTypeList,
  optionTypeListStatus: state => state.optionTypeListStatus,
  getOptionTypeById: (state, getters) => id => {
    if (getters.optionTypeList.length > 0) {
      return find(getters.optionTypeList, { id });
    }
    return state.optionType;
  },
};

const actions = {
  [OPTION_TYPE_LIST_REQUEST]: async ({ commit }) => {
    commit(OPTION_TYPE_LIST_REQUEST);
    try {
      const response = await OptionTypesRepository.get();
      commit(OPTION_TYPE_LIST_REQUEST_SUCCESS, response.data);
    } catch (error) {
      commit(OPTION_TYPE_LIST_REQUEST_ERROR);
      handleRequestError(error);
    }
  },
  [OPTION_TYPE_CREATE]: async ({ commit }, payload) => {
    commit(OPTION_TYPE_CREATE);
    try {
      const response = await OptionTypesRepository.create(payload);
      commit(OPTION_TYPE_CREATE_SUCCESS, response.data.data);
    } catch (error) {
      commit(OPTION_TYPE_CREATE_ERROR);
      handleRequestError(error);
    }
  },
  [OPTION_TYPE_UPDATE]: async ({ commit }, { id, data }) => {
    commit(OPTION_TYPE_UPDATE);
    try {
      const response = await OptionTypesRepository.update(id, data);
      commit(OPTION_TYPE_UPDATE_SUCCESS, response.data.data);
    } catch (error) {
      commit(OPTION_TYPE_UPDATE_ERROR);
      handleRequestError(error);
    }
  },
  [OPTION_TYPE_DELETE]: async ({ commit }, id) => {
    commit(OPTION_TYPE_DELETE);
    try {
      await OptionTypesRepository.delete(id);
      commit(OPTION_TYPE_DELETE_SUCCESS, id);
    } catch (error) {
      commit(OPTION_TYPE_DELETE_ERROR);
      handleRequestError(error);
    }
  },
};

/* eslint-disable no-param-reassign */
const mutations = {
  [OPTION_TYPE_LIST_REQUEST]: state => {
    state.optionTypeListStatus = 'loading';
  },
  [OPTION_TYPE_LIST_REQUEST_SUCCESS]: (state, payload) => {
    state.optionTypeListStatus = 'success';
    state.optionTypeList = payload.data;
  },
  [OPTION_TYPE_LIST_REQUEST_ERROR]: state => {
    state.optionTypeListStatus = 'error';
  },
  [OPTION_TYPE_CREATE]: state => {
    state.status = 'loading';
  },
  [OPTION_TYPE_CREATE_SUCCESS]: (state, payload) => {
    state.status = 'success';
    state.optionTypeList.push(payload);
  },
  [OPTION_TYPE_CREATE_ERROR]: state => {
    state.status = 'error';
  },
  [OPTION_TYPE_UPDATE]: state => {
    state.status = 'loading';
  },
  [OPTION_TYPE_UPDATE_SUCCESS]: (state, payload) => {
    state.status = 'success';
    state.optionTypeList = state.optionTypeList.map(item => {
      if (item.id === payload.id) {
        return payload;
      }
      return item;
    });
  },
  [OPTION_TYPE_UPDATE_ERROR]: state => {
    state.status = 'error';
  },
  [OPTION_TYPE_DELETE]: state => {
    state.status = 'deleting';
  },
  [OPTION_TYPE_DELETE_SUCCESS]: (state, payload) => {
    const index = findIndex(state.optionTypeList, { id: payload });
    state.optionTypeList.splice(index, 1);
    state.status = 'deleted';
  },
  [OPTION_TYPE_DELETE_ERROR]: state => {
    state.status = 'error';
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
