<template>
  <ModalMain
    :id="modalId"
    :title="title"
    :ok-title="$t('text.addButton')"
    :loading="false"
    delete-disabled
    paddingless
    ok-hide
    @cancel="onCancel"
  >
    <div class="p-3">
      <InlineButton @click="selectAll" pill dashed block variant="outline-primary">
        {{ $t('inventory.selectAll') }}
      </InlineButton>
    </div>

    <hr class="mt-0" />

    <b-list-group flush>
      <b-list-group-item v-for="product in filteredProductList" :key="`product-${product.id}`">
        <div class="mb-2">
          <strong>{{ product.name }}</strong>
        </div>
        <b-list-group>
          <b-list-group-item
            v-for="(variant, index) in product.variants"
            :key="`variant-${index}`"
            button
            @click="onClick(product, variant)"
            class="customer-details-list-item"
          >
            <div class="d-flex justify-content-between">
              <span>{{ variant.sku }}</span>
              <span>{{ variant.sizeString }}</span>
            </div>
          </b-list-group-item>
        </b-list-group>
      </b-list-group-item>
    </b-list-group>
  </ModalMain>
</template>

<script>
import editModalMixin from '@/mixins/editModalMixin';
import ModalMain from '@/components/ui/ModalMain';
import InlineButton from '@/components/ui/button/InlineButton';

export default {
  mixins: [editModalMixin],

  props: {
    productList: Array,
    selectedVariants: Array,
  },

  computed: {
    title() {
      return this.$t('product.addVariant');
    },
    variantIdList() {
      return this.selectedVariants.map(product => product.variant.id);
    },
    filteredProductList() {
      const list = this.productList.map(product => {
        const variants = product.variants.filter(
          variant => !this.variantIdList.includes(variant.id)
        );
        return {
          ...product,
          variants,
        };
      });
      return list;
    },
  },

  methods: {
    onCancel() {
      this.hideEditModal();
    },
    selectAll() {
      const variants = this.filteredProductList.reduce((list, product) => {
        product.variants.forEach(variant => {
          list.push({
            id: product.id,
            name: product.name,
            variant,
          });
        });
        return list;
      }, []);
      this.$emit('selectAll', variants);
      this.hideEditModal();
    },
    async onClick(product, variant) {
      this.$emit('click', { id: product.id, name: product.name, variant });
      this.hideEditModal();
    },
  },

  components: {
    ModalMain,
    InlineButton,
  },
};
</script>
