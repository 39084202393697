<template>
  <b-form-group
    :label="label"
    :label-class="labelClass"
    :label-for="`select-${uuid}`"
    :invalid-feedback="invalidFeedback"
    :valid-feedback="validFeedback"
    :state="$_state"
    :disabled="disabled"
  >
    <b-form-select
      :id="`select-${uuid}`"
      :value="value"
      :size="size"
      :state="$_state"
      @input="onInput"
      @change="onChange"
    >
      <b-form-select-option :value="null">{{ $t('category.noCategory') }}</b-form-select-option>
      <template v-for="item in options">
        <b-form-select-option :key="item.id" :value="item.id">{{ item.name }}</b-form-select-option>
        <template v-for="child in item.children">
          <b-form-select-option :key="child.id" :value="child.id">
            -- {{ child.name }}
          </b-form-select-option>
          <!-- <template v-for="childSecond in child.children">
            <b-form-select-option :key="childSecond.id" :value="childSecond.id">
              ---- {{ childSecond.name }}
            </b-form-select-option>
          </template> -->
        </template>
      </template>
    </b-form-select>
  </b-form-group>
</template>

<script>
import baseInputMixin from '@/mixins/baseInputMixin';

export default {
  mixins: [baseInputMixin],

  props: {
    options: Array,
  },

  data() {
    return {};
  },
};
</script>
