<template>
  <div class="about-page">
    <BaseLayout :title="title" padding>
      <b-row class="about-page-row">
        <b-col cols="12" md="6" xxl="4">
          <BusinessSettings :business="business" @click="openModal" />
        </b-col>
      </b-row>

      <BusinessAddEditModal :form-data="business" :modal-id="editModalId" delete-disabled />
    </BaseLayout>
  </div>
</template>

<script>
import meMixin from '@/mixins/meMixin';
import BaseLayout from '@/components/ui/BaseLayout';
import BusinessSettings from '@/components/manage/settings/BusinessSettings';
import BusinessAddEditModal from '@/components/manage/businesses/BusinessAddEditModal';

export default {
  mixins: [meMixin],

  props: {
    title: String,
  },

  data() {
    return {
      editModalId: 'manage-form-modal',
    };
  },

  computed: {
    business() {
      return this.me.business;
    },
  },

  created() {
    this.fetchData();
  },

  methods: {
    fetchData() {
      // this.$store.dispatch(PICK_UP_DURATION_LIST_REQUEST);
    },
    openModal() {
      this.$bvModal.show(this.editModalId);
    },
  },

  components: {
    BaseLayout,
    // BoxCard,
    BusinessSettings,
    BusinessAddEditModal,
  },
};
</script>

<style lang="scss" scoped>
.about-page-row {
  margin: -1rem;

  & > div {
    padding: 1rem;
  }
}
</style>
