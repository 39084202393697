<template>
  <b-container>
    <b-row>
      <b-col cols="12" offset-sm="1" sm="10" offset-md="2" md="8" offset-xl="3" xl="6">
        <div class="py-4">
          <p class="mb-3"><router-link :to="{ name: 'Home' }">&laquo; back to home</router-link></p>
          <h1 class="text-center mb-4">{{ title }}</h1>
          <p>
            {{ body }}
          </p>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { mapGetters } from 'vuex';
import { ABOUT_APP_REQUEST } from '@/store/actions/aboutApp';

export default {
  data() {
    return {
      title:
        this.$route.name === 'TermsCondition'
          ? this.$t('label.termsCondition')
          : this.$t('label.privacyPolicy'),
    };
  },

  computed: {
    ...mapGetters(['aboutApp', 'aboutAppStatus']),
    body() {
      if (this.aboutApp) {
        return this.$route.name === 'TermsCondition'
          ? this.aboutApp.termsCondition
          : this.aboutApp.privacyPolicy;
      }
      return '...';
    },
  },

  created() {
    this.fetchData();
  },

  methods: {
    fetchData() {
      this.$store.dispatch(ABOUT_APP_REQUEST);
    },
  },
};
</script>
