import {
  CUSTOMER_LIST_REQUEST,
  CUSTOMER_LIST_REQUEST_SUCCESS,
  CUSTOMER_LIST_REQUEST_ERROR,
  CUSTOMER_DETAILS_REQUEST,
  CUSTOMER_DETAILS_REQUEST_SUCCESS,
  CUSTOMER_DETAILS_REQUEST_ERROR,
  CUSTOMER_PROFILE_UPDATE,
  CUSTOMER_PROFILE_UPDATE_SUCCESS,
  CUSTOMER_PROFILE_UPDATE_ERROR,
} from '@/store/actions/customer';

import { handleRequestError } from '@/utils/error';
import Repository, { CUSTOMERS } from '@/repositories/RepositoryFactory';

const CustomersRepository = Repository.get(CUSTOMERS);

const state = {
  status: '',
  customerList: [],
  customerListMeta: {},
  customerListStatus: '',
  customerDetails: {},
};

const getters = {
  customerStatus: state => state.status,
  customerList: state => state.customerList,
  customerListMeta: state => state.customerListMeta,
  customerListStatus: state => state.customerListStatus,
  customerDetails: state => state.customerDetails,
};

const actions = {
  [CUSTOMER_LIST_REQUEST]: async ({ commit }, payload) => {
    commit(CUSTOMER_LIST_REQUEST);
    try {
      const response = await CustomersRepository.get(payload);
      commit(CUSTOMER_LIST_REQUEST_SUCCESS, response.data);
    } catch (error) {
      commit(CUSTOMER_LIST_REQUEST_ERROR);
      handleRequestError(error);
    }
  },
  [CUSTOMER_DETAILS_REQUEST]: async ({ commit }, { id }) => {
    commit(CUSTOMER_DETAILS_REQUEST);
    try {
      const response = await CustomersRepository.show(id);
      commit(CUSTOMER_DETAILS_REQUEST_SUCCESS, response.data);
    } catch (error) {
      commit(CUSTOMER_DETAILS_REQUEST_ERROR);
      handleRequestError(error);
    }
  },
  [CUSTOMER_PROFILE_UPDATE]: async ({ commit }, payload) => {
    commit(CUSTOMER_PROFILE_UPDATE);
    try {
      const response = await CustomersRepository.update(payload.id, payload.data);
      commit(CUSTOMER_PROFILE_UPDATE_SUCCESS, response.data);
    } catch (error) {
      commit(CUSTOMER_PROFILE_UPDATE_ERROR);
      handleRequestError(error);
    }
  },
};
const mutations = {
  [CUSTOMER_LIST_REQUEST]: state => {
    state.customerListStatus = 'loading';
  },
  [CUSTOMER_LIST_REQUEST_SUCCESS]: (state, payload) => {
    state.customerListStatus = 'success';
    state.customerList = payload.data;

    if (payload.meta) {
      state.customerListMeta = payload.meta;
    }
  },
  [CUSTOMER_LIST_REQUEST_ERROR]: state => {
    state.customerListStatus = 'error';
  },
  [CUSTOMER_DETAILS_REQUEST]: state => {
    state.status = 'loading';
  },
  [CUSTOMER_DETAILS_REQUEST_SUCCESS]: (state, payload) => {
    state.status = 'success';
    state.customerDetails = payload.data;
  },
  [CUSTOMER_DETAILS_REQUEST_ERROR]: state => {
    state.status = 'error';
  },
  [CUSTOMER_PROFILE_UPDATE]: state => {
    state.status = 'loading';
  },
  [CUSTOMER_PROFILE_UPDATE_SUCCESS]: (state, payload) => {
    state.status = 'success';
    state.customerDetails = payload.data;
  },
  [CUSTOMER_PROFILE_UPDATE_ERROR]: state => {
    state.status = 'error';
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
