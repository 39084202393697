export const BUSINESS_LIST_REQUEST = 'BUSINESS_LIST_REQUEST';
export const BUSINESS_LIST_REQUEST_SUCCESS = 'BUSINESS_LIST_REQUEST_SUCCESS';
export const BUSINESS_LIST_REQUEST_ERROR = 'BUSINESS_LIST_REQUEST_ERROR';

export const BUSINESS_CREATE = 'BUSINESS_CREATE';
export const BUSINESS_CREATE_SUCCESS = 'BUSINESS_CREATE_SUCCESS';
export const BUSINESS_CREATE_ERROR = 'BUSINESS_CREATE_ERROR';

export const BUSINESS_UPDATE = 'BUSINESS_UPDATE';
export const BUSINESS_UPDATE_SUCCESS = 'BUSINESS_UPDATE_SUCCESS';
export const BUSINESS_UPDATE_ERROR = 'BUSINESS_UPDATE_ERROR';

export const BUSINESS_DELETE = 'BUSINESS_DELETE';
export const BUSINESS_DELETE_SUCCESS = 'BUSINESS_DELETE_SUCCESS';
export const BUSINESS_DELETE_ERROR = 'BUSINESS_DELETE_ERROR';
