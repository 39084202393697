import { find, findIndex } from 'lodash';
import {
  ADDON_CATEGORY_REQUEST,
  ADDON_CATEGORY_REQUEST_ERROR,
  ADDON_CATEGORY_REQUEST_SUCCESS,
  ADDON_CATEGORY_LIST_REQUEST,
  ADDON_CATEGORY_LIST_REQUEST_ERROR,
  ADDON_CATEGORY_LIST_REQUEST_SUCCESS,
  ADDON_CATEGORY_CREATE,
  ADDON_CATEGORY_CREATE_SUCCESS,
  ADDON_CATEGORY_CREATE_ERROR,
  ADDON_CATEGORY_UPDATE,
  ADDON_CATEGORY_UPDATE_SUCCESS,
  ADDON_CATEGORY_UPDATE_ERROR,
  ADDON_CATEGORY_DELETE,
  ADDON_CATEGORY_DELETE_SUCCESS,
  ADDON_CATEGORY_DELETE_ERROR,
} from '@/store/actions/addonCategory';
import { handleRequestError } from '@/utils/error';
import Repository, { ADDON_CATEGORIES } from '@/repositories/RepositoryFactory';

const AddonCategoriesRepository = Repository.get(ADDON_CATEGORIES);

const state = {
  status: '',
  addonCategory: {},
  addonCategoryList: [],
  addonCategoryListMeta: {},
  addonCategoryListStatus: '',
};

const getters = {
  addonCategoryStatus: state => state.status,
  addonCategoryList: state => state.addonCategoryList,
  addonCategoryListMeta: state => state.addonCategoryListMeta,
  addonCategoryListStatus: state => state.addonCategoryListStatus,
  getAddonCategoryById: (state, getters) => id => {
    if (getters.addonCategoryList.length > 0) {
      return find(getters.addonCategoryList, { id });
    }
    return state.addonCategory;
  },
};

const actions = {
  [ADDON_CATEGORY_REQUEST]: async ({ commit }, id) => {
    commit(ADDON_CATEGORY_REQUEST);
    try {
      const response = await AddonCategoriesRepository.show(id);
      commit(ADDON_CATEGORY_REQUEST_SUCCESS, response.data);
    } catch (error) {
      commit(ADDON_CATEGORY_REQUEST_ERROR);
      handleRequestError(error);
    }
  },
  [ADDON_CATEGORY_LIST_REQUEST]: async ({ commit }, payload) => {
    commit(ADDON_CATEGORY_LIST_REQUEST);
    try {
      const response = await AddonCategoriesRepository.get(payload);
      commit(ADDON_CATEGORY_LIST_REQUEST_SUCCESS, response.data);
    } catch (error) {
      commit(ADDON_CATEGORY_LIST_REQUEST_ERROR);
      handleRequestError(error);
    }
  },
  [ADDON_CATEGORY_CREATE]: async ({ commit }, payload) => {
    commit(ADDON_CATEGORY_CREATE);
    try {
      const response = await AddonCategoriesRepository.create(payload);
      commit(ADDON_CATEGORY_CREATE_SUCCESS, response.data.data);
    } catch (error) {
      commit(ADDON_CATEGORY_CREATE_ERROR);
      handleRequestError(error);
    }
  },
  [ADDON_CATEGORY_UPDATE]: async ({ commit }, { id, data }) => {
    commit(ADDON_CATEGORY_UPDATE);
    try {
      const response = await AddonCategoriesRepository.update(id, data);
      commit(ADDON_CATEGORY_UPDATE_SUCCESS, response.data.data);
    } catch (error) {
      commit(ADDON_CATEGORY_UPDATE_ERROR);
      handleRequestError(error);
    }
  },
  [ADDON_CATEGORY_DELETE]: async ({ commit }, id) => {
    commit(ADDON_CATEGORY_DELETE);
    try {
      await AddonCategoriesRepository.delete(id);
      commit(ADDON_CATEGORY_DELETE_SUCCESS, id);
    } catch (error) {
      commit(ADDON_CATEGORY_DELETE_ERROR);
      handleRequestError(error);
    }
  },
};

/* eslint-disable no-param-reassign */
const mutations = {
  [ADDON_CATEGORY_REQUEST]: state => {
    state.status = 'loading';
  },
  [ADDON_CATEGORY_REQUEST_SUCCESS]: (state, payload) => {
    state.status = 'success';
    state.addonCategory = payload.data;
  },
  [ADDON_CATEGORY_REQUEST_ERROR]: state => {
    state.status = 'error';
  },
  [ADDON_CATEGORY_LIST_REQUEST]: state => {
    state.addonCategoryListStatus = 'loading';
  },
  [ADDON_CATEGORY_LIST_REQUEST_SUCCESS]: (state, payload) => {
    state.addonCategoryListStatus = 'success';
    state.addonCategoryList = payload.data;
    if (payload.meta) {
      state.addonCategoryListMeta = payload.meta;
    }
  },
  [ADDON_CATEGORY_LIST_REQUEST_ERROR]: state => {
    state.addonCategoryListStatus = 'error';
  },
  [ADDON_CATEGORY_CREATE]: state => {
    state.status = 'loading';
  },
  [ADDON_CATEGORY_CREATE_SUCCESS]: (state, payload) => {
    state.status = 'success';
    state.addonCategoryList.push(payload);
  },
  [ADDON_CATEGORY_CREATE_ERROR]: state => {
    state.status = 'error';
  },
  [ADDON_CATEGORY_UPDATE]: state => {
    state.status = 'loading';
  },
  [ADDON_CATEGORY_UPDATE_SUCCESS]: (state, payload) => {
    state.status = 'success';
    state.addonCategoryList = state.addonCategoryList.map(item => {
      if (item.id === payload.id) {
        return payload;
      }
      return item;
    });
  },
  [ADDON_CATEGORY_UPDATE_ERROR]: state => {
    state.status = 'error';
  },
  [ADDON_CATEGORY_DELETE]: state => {
    state.status = 'deleting';
  },
  [ADDON_CATEGORY_DELETE_SUCCESS]: (state, payload) => {
    state.status = 'deleted';
    const index = findIndex(state.addonCategoryList, { id: payload });
    state.addonCategoryList.splice(index, 1);
  },
  [ADDON_CATEGORY_DELETE_ERROR]: state => {
    state.status = 'error';
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
