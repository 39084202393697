export const DELIVERY_TYPE_LIST_REQUEST = 'DELIVERY_TYPE_LIST_REQUEST';
export const DELIVERY_TYPE_LIST_REQUEST_SUCCESS = 'DELIVERY_TYPE_LIST_REQUEST_SUCCESS';
export const DELIVERY_TYPE_LIST_REQUEST_ERROR = 'DELIVERY_TYPE_LIST_REQUEST_ERROR';

export const DELIVERY_TYPE_CREATE = 'DELIVERY_TYPE_CREATE';
export const DELIVERY_TYPE_CREATE_SUCCESS = 'DELIVERY_TYPE_CREATE_SUCCESS';
export const DELIVERY_TYPE_CREATE_ERROR = 'DELIVERY_TYPE_CREATE_ERROR';

export const DELIVERY_TYPE_UPDATE = 'DELIVERY_TYPE_UPDATE';
export const DELIVERY_TYPE_UPDATE_SUCCESS = 'DELIVERY_TYPE_UPDATE_SUCCESS';
export const DELIVERY_TYPE_UPDATE_ERROR = 'DELIVERY_TYPE_UPDATE_ERROR';

export const DELIVERY_TYPE_DELETE = 'DELIVERY_TYPE_DELETE';
export const DELIVERY_TYPE_DELETE_SUCCESS = 'DELIVERY_TYPE_DELETE_SUCCESS';
export const DELIVERY_TYPE_DELETE_ERROR = 'DELIVERY_TYPE_DELETE_ERROR';
