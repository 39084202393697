<template>
  <BaseLayout :title="title">
    <template #control>
      <Tabs :items="items" :current-tab="currentTab" @click="onChangeTab" />
    </template>

    <OrderTable
      :orders="productSaleList"
      :meta="productSaleListMeta"
      :current-page="currentPage"
      :is-loading="isLoading"
      @show="onShow"
      @onChangePage="onChangePage"
    />

    <OrderShowModal :order="dataToEdit" :modal-id="editModalId" @onHidden="emptyingProductSale" />
  </BaseLayout>
</template>

<script>
import { mapGetters } from 'vuex';
import meMixin from '@/mixins/meMixin';
import { PRODUCT_SALE_LIST_REQUEST } from '@/store/actions/productSale';
import { KEY_FILTER_BUSINESS_ID, KEY_FILTER_STATUS } from '@/utils/filter';
import BaseLayout from '@/components/ui/BaseLayout';
import OrderTable from '@/components/manage/orders/OrderTable';
import OrderShowModal from '@/components/manage/orders/OrderShowModal';
import Tabs from '@/components/ui/Tabs';

export default {
  mixins: [meMixin],

  props: {
    title: String,
  },

  data() {
    return {
      currentPage: 1,
      dataIdToEdit: null,
      editModalId: 'manage-form-modal',
      currentTab: 'ALL',
      items: [
        {
          name: this.$t('status.all'),
          value: 'ALL',
        },
        {
          name: this.$t('status.pending'),
          value: 'PENDING',
        },
        {
          name: this.$t('status.completed'),
          value: 'COMPLETED',
        },
        {
          name: this.$t('status.delivered'),
          value: 'DELIVERED',
        },
        {
          name: this.$t('status.cancel'),
          value: 'CANCEL',
        },
      ],
    };
  },

  computed: {
    ...mapGetters([
      'productSaleList',
      'productSaleListMeta',
      'productSaleListStatus',
      'getProductSaleById',
      'categoryList',
      'categoryListStatus',
    ]),
    dataToEdit() {
      return this.dataIdToEdit ? this.getProductSaleById(this.dataIdToEdit) : null;
    },
    isLoading() {
      return this.productSaleListStatus === 'loading';
    },
  },

  created() {
    this.fetchData();
  },

  methods: {
    fetchData() {
      const payload = {
        limit: 100,
        page: this.currentPage,
        filter: {},
      };
      if (!this.me.isAdmin) {
        payload.filter[`${KEY_FILTER_BUSINESS_ID}`] = this.me.businessId;
      }
      if (this.currentTab !== 'ALL') {
        payload.filter[`${KEY_FILTER_STATUS}`] = this.currentTab;
      }

      this.$store.dispatch(PRODUCT_SALE_LIST_REQUEST, payload);
    },
    emptyingProductSale() {
      this.dataIdToEdit = null;
    },
    onShow(productSaleId) {
      this.dataIdToEdit = productSaleId;
      this.openModal();
    },
    openModal() {
      this.$bvModal.show(this.editModalId);
    },
    onChangePage(page) {
      this.currentPage = page;
      this.fetchData();
    },
    onChangeTab(payload) {
      this.currentPage = 1;
      this.dateString = '';
      this.dates = [];
      this.currentTab = payload;
      this.fetchData();
    },
  },

  components: {
    BaseLayout,
    OrderTable,
    OrderShowModal,
    Tabs,
  },
};
</script>
