<template>
  <ReportLayout
    :title="title"
    :date-range="dateRange"
    :date-picker="datePicker"
    :is-downloading="isDownloading"
    @click="updateValues"
    @download="onDownload"
  >
    <TableBox :current-page="currentPage" :meta="reportMeta" @onChangePage="onChangePage">
      <b-table
        sticky-header
        id="report-table"
        :fields="fields"
        :items="reportList"
        :busy="isLoading"
        :emptyText="$t('text.emptyText')"
        show-empty
      >
        <template #cell(grossSales)="data">
          {{
            data.item.grossSales && data.item.grossSales.amount
              ? $options.filters.currency(data.item.grossSales.amount)
              : ''
          }}
        </template>
        <template #cell(refunds)="data">
          {{
            data.item.refunds && data.item.refunds.amount
              ? $options.filters.currency(data.item.refunds.amount)
              : ''
          }}
        </template>
        <template #cell(netSales)="data">
          {{
            data.item.netSales && data.item.netSales.amount
              ? $options.filters.currency(data.item.netSales.amount)
              : ''
          }}
        </template>
        <template #cell(tax)="data">
          {{
            data.item.tax && data.item.tax.amount
              ? $options.filters.currency(data.item.tax.amount)
              : ''
          }}
        </template>
        <template #cell(totalSales)="data">
          {{
            data.item.totalSales && data.item.totalSales.amount
              ? $options.filters.currency(data.item.totalSales.amount)
              : ''
          }}
        </template>
      </b-table>
    </TableBox>
  </ReportLayout>
</template>

<script>
import reportMixin from '@/mixins/reportMixin';

export default {
  mixins: [reportMixin],

  data() {
    return {
      fields: [
        {
          key: 'date',
          label: this.$t('report.table.date'),
        },
        {
          key: 'quantity',
          label: this.$t('report.table.salesQuantity'),
        },
        {
          key: 'grossSales',
          label: this.$t('report.table.grossSales'),
        },
        {
          key: 'refunds',
          label: this.$t('report.table.refunds'),
        },
        {
          key: 'netSales',
          label: this.$t('report.table.netSales'),
        },
        {
          key: 'tax',
          label: this.$t('report.table.tax'),
        },
        {
          key: 'totalSales',
          label: this.$t('report.table.totalSales'),
        },
      ],
      reportType: 'MONTH',
      perPage: 20,
    };
  },

  created() {
    this.fetchData();
  },
};
</script>
