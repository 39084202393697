<template>
  <ModalMain
    :id="modalId"
    :title="title"
    :ok-title="saveButtonTitle"
    :loading="isLoading"
    :delete-disabled="!formData"
    :ok-disabled="!hasProduct"
    @shown="updateForm"
    @hidden="onHidden"
    @ok="onSave"
    @cancel="onCancel"
    @remove="$emit('onRemove', formData.id)"
  >
    <b-form @submit.prevent="onSave">
      <template v-if="form.items.length > 0">
        <div v-for="(item, index) in form.items" :key="index">
          <div>
            <span>{{ $t('label.name') }}:</span>
            {{ item.name }}
          </div>
          <div>
            <span>{{ $t('label.sku') }}:</span>
            {{ item.variant ? item.variant.sku : 'no SKU' }}
          </div>
          <b-row class="mt-3">
            <b-col cols="8">
              <InputNumber
                class="quantity"
                v-model.number="item.quantity"
                :append="$t('label.quantity')"
              />
            </b-col>
            <b-col cols="4">
              <InlineButton @click="onItemRemove(index)" variant="outline-danger" block>
                {{ $t('text.deleteButton') }}
              </InlineButton>
            </b-col>
          </b-row>
          <hr v-if="index + 1 < form.items.length" />
        </div>
      </template>
      <template v-else>
        <p class="mb-0">{{ $t('text.emptyText') }}</p>
      </template>

      <hr />

      <InlineButton @click="onItemCreate" pill dashed variant="outline-primary">
        {{ $t('inventory.addProduct') }}
      </InlineButton>

      <InventoryItemAddEditModal
        :modal-id="addModalId"
        :product-list="productList"
        :selected-variants="form.items"
        @click="onItemPush"
        @selectAll="onPushAll"
      />

      <ConfirmDialog :id="deleteModalId" @ok="removeItem" />
    </b-form>
  </ModalMain>
</template>

<script>
import { isNil } from 'lodash';
import { mapGetters } from 'vuex';
import editModalMixin from '@/mixins/editModalMixin';
import { INVENTORY_CREATE, INVENTORY_UPDATE } from '@/store/actions/inventory';
import ModalMain from '@/components/ui/ModalMain';
import InputNumber from '@/components/ui/input/InputNumber';
import InlineButton from '@/components/ui/button/InlineButton';
import ConfirmDialog from '@/components/ui/ConfirmDialog';
import InventoryItemAddEditModal from '@/components/manage/inventories/InventoryItemAddEditModal';

const defaultValuesForForm = () => ({
  items: [],
  businessId: null,
});

export default {
  mixins: [editModalMixin],

  props: {
    formData: Object,
    productList: Array,
    businessId: Number,
  },

  data() {
    return {
      form: defaultValuesForForm(),
      addModalId: 'add-variant-modal',
      deleteModalId: 'remove-variant-modal',
      variantIndexToRemove: null,
      variantToEdit: null,
    };
  },

  computed: {
    ...mapGetters(['inventoryStatus']),
    isLoading() {
      return this.inventoryStatus === 'loading';
    },
    title() {
      return isNil(this.formData)
        ? this.$t('inventory.newInventory')
        : this.$t('inventory.editInventory');
    },
    saveButtonTitle() {
      return isNil(this.formData) ? this.$t('text.addButton') : this.$t('text.updateButton');
    },
    hasProduct() {
      const products = this.form.items.filter(item => item.quantity > 0);
      return !!(products && products.length > 0);
    },
  },

  methods: {
    updateForm() {
      this.form = this.formData
        ? {
            items: this.formData.items.map(item => {
              const product = item.variant ? item.variant.product : null;
              return {
                id: product ? product.id : null,
                name: product ? product.name : '',
                ...item,
              };
            }),
          }
        : defaultValuesForForm();
    },
    resetForm() {
      this.form = defaultValuesForForm();
    },
    onHidden() {
      this.$emit('onHidden');
      this.resetForm();
    },
    onCancel() {
      this.hideEditModal();
    },
    onItemCreate() {
      this.$bvModal.show(this.addModalId);
    },
    onItemRemove(index) {
      this.variantIndexToRemove = index;
      this.$bvModal.show(this.deleteModalId);
    },
    removeItem() {
      this.form.items.splice(this.variantIndexToRemove, 1);
    },
    onItemPush(payload) {
      this.form.items.push({ ...payload, quantity: 1 });
    },
    onPushAll(variants) {
      variants.forEach(variant => {
        this.form.items.push({ ...variant, quantity: 1 });
      });
    },
    async onSave() {
      const data = {
        ...this.form,
        businessId: this.businessId,
      };

      if (isNil(this.formData)) {
        await this.$store.dispatch(INVENTORY_CREATE, data);
      } else {
        const payload = {
          id: this.formData.id,
          data,
        };
        await this.$store.dispatch(INVENTORY_UPDATE, payload);
      }
      if (this.inventoryStatus === 'success') {
        this.hideEditModal();
      }
    },
  },

  components: {
    ModalMain,
    InputNumber,
    InlineButton,
    ConfirmDialog,
    InventoryItemAddEditModal,
  },
};
</script>
