<template>
  <BoxCard
    :title="$t('settings.businessInfo')"
    :button-label="$t('text.editButton')"
    button-variant="outline-secondary"
    body-paddingless
    @click="$emit('click')"
    class="overflow-hidden"
  >
    <b-list-group flush>
      <b-list-group-item class="customer-details-list-item">
        <span>{{ $t('label.name') }}:</span>
        <p>{{ business.name }}</p>
      </b-list-group-item>
      <b-list-group-item class="customer-details-list-item">
        <span>{{ $t('label.slug') }}:</span>
        <p>{{ business.slug }}</p>
      </b-list-group-item>
      <b-list-group-item class="customer-details-list-item">
        <span>{{ $t('label.phone') }}:</span>
        <p>{{ business.phone }}</p>
      </b-list-group-item>
      <b-list-group-item class="customer-details-list-item">
        <span>{{ $t('label.email') }}:</span>
        <p>{{ business.email }}</p>
      </b-list-group-item>
      <b-list-group-item class="customer-details-list-item">
        <span>{{ $t('label.registered') }}:</span>
        <p v-if="business.createdAt">
          {{ business.createdAt | moment('MMM DD, YYYY') }}
        </p>
      </b-list-group-item>
    </b-list-group>
  </BoxCard>
</template>

<script>
import { validationMixin } from 'vuelidate';
import BoxCard from '@/components/ui/BoxCard';

export default {
  mixins: [validationMixin],

  props: {
    business: Object,
  },

  components: {
    BoxCard,
  },
};
</script>

<style lang="scss" scoped>
@import '../../../sass/variables';
@import '../../../sass/flexBox';

.customer-details-list-item {
  @include flexBox(flex, center, space-between);
  flex-wrap: wrap;
  padding: 0.75rem 1.5rem;

  span {
    color: $gray-500;
  }
}

@media (min-width: $breakpoint-md) {
  .customer-details-list-item {
    padding: 1.25rem 2rem;
  }
}
</style>
