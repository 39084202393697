import { findIndex } from 'lodash';
import {
  PRODUCT_REQUEST,
  PRODUCT_REQUEST_ERROR,
  PRODUCT_REQUEST_SUCCESS,
  PRODUCT_LIST_REQUEST,
  PRODUCT_LIST_REQUEST_ERROR,
  PRODUCT_LIST_REQUEST_SUCCESS,
  PRODUCT_CREATE,
  PRODUCT_CREATE_SUCCESS,
  PRODUCT_CREATE_ERROR,
  PRODUCT_UPDATE,
  PRODUCT_UPDATE_SUCCESS,
  PRODUCT_UPDATE_ERROR,
  PRODUCT_DELETE,
  PRODUCT_DELETE_SUCCESS,
  PRODUCT_DELETE_ERROR,
} from '@/store/actions/product';
import { handleRequestError } from '@/utils/error';
import Repository, { PRODUCTS } from '@/repositories/RepositoryFactory';

const ProductsRepository = Repository.get(PRODUCTS);

const state = {
  status: '',
  product: {},
  productList: [],
  productListMeta: {},
  productListStatus: '',
};

const getters = {
  product: state => state.product,
  productStatus: state => state.status,
  productList: state => state.productList,
  productListMeta: state => state.productListMeta,
  productListStatus: state => state.productListStatus,
};

const actions = {
  [PRODUCT_REQUEST]: async ({ commit }, id) => {
    commit(PRODUCT_REQUEST);
    try {
      const response = await ProductsRepository.show(id);
      commit(PRODUCT_REQUEST_SUCCESS, response.data);
    } catch (error) {
      commit(PRODUCT_REQUEST_ERROR);
      handleRequestError(error);
    }
  },
  [PRODUCT_LIST_REQUEST]: async ({ commit }, payload) => {
    commit(PRODUCT_LIST_REQUEST);
    try {
      const response = await ProductsRepository.get(payload);
      commit(PRODUCT_LIST_REQUEST_SUCCESS, response.data);
    } catch (error) {
      commit(PRODUCT_LIST_REQUEST_ERROR);
      handleRequestError(error);
    }
  },
  [PRODUCT_CREATE]: async ({ commit }, payload) => {
    commit(PRODUCT_CREATE);
    try {
      const response = await ProductsRepository.create(payload);
      commit(PRODUCT_CREATE_SUCCESS, response.data.data);
    } catch (error) {
      commit(PRODUCT_CREATE_ERROR);
      handleRequestError(error);
    }
  },
  [PRODUCT_UPDATE]: async ({ commit }, { id, data }) => {
    commit(PRODUCT_UPDATE);
    try {
      const response = await ProductsRepository.update(id, data);
      commit(PRODUCT_UPDATE_SUCCESS, response.data.data);
    } catch (error) {
      commit(PRODUCT_UPDATE_ERROR);
      handleRequestError(error);
    }
  },
  [PRODUCT_DELETE]: async ({ commit }, id) => {
    commit(PRODUCT_DELETE);
    try {
      await ProductsRepository.delete(id);
      commit(PRODUCT_DELETE_SUCCESS, id);
    } catch (error) {
      commit(PRODUCT_DELETE_ERROR);
      handleRequestError(error);
    }
  },
};

/* eslint-disable no-param-reassign */
const mutations = {
  [PRODUCT_REQUEST]: state => {
    state.status = 'loading';
  },
  [PRODUCT_REQUEST_SUCCESS]: (state, payload) => {
    state.status = 'success';
    state.product = payload.data;
  },
  [PRODUCT_REQUEST_ERROR]: state => {
    state.status = 'error';
  },
  [PRODUCT_LIST_REQUEST]: state => {
    state.productListStatus = 'loading';
  },
  [PRODUCT_LIST_REQUEST_SUCCESS]: (state, payload) => {
    state.productListStatus = 'success';
    state.productList = payload.data;
    if (payload.meta) {
      state.productListMeta = payload.meta;
    }
  },
  [PRODUCT_LIST_REQUEST_ERROR]: state => {
    state.productListStatus = 'error';
  },
  [PRODUCT_CREATE]: state => {
    state.status = 'loading';
  },
  [PRODUCT_CREATE_SUCCESS]: (state, payload) => {
    state.status = 'success';
    state.productList.push(payload);
  },
  [PRODUCT_CREATE_ERROR]: state => {
    state.status = 'error';
  },
  [PRODUCT_UPDATE]: state => {
    state.status = 'loading';
  },
  [PRODUCT_UPDATE_SUCCESS]: (state, payload) => {
    state.status = 'success';
    state.productList = state.productList.map(item => {
      if (item.id === payload.id) {
        return payload;
      }
      return item;
    });
  },
  [PRODUCT_UPDATE_ERROR]: state => {
    state.status = 'error';
  },
  [PRODUCT_DELETE]: state => {
    state.status = 'deleting';
  },
  [PRODUCT_DELETE_SUCCESS]: (state, payload) => {
    const index = findIndex(state.productList, { id: payload });
    state.productList.splice(index, 1);
  },
  [PRODUCT_DELETE_ERROR]: state => {
    state.status = 'error';
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
