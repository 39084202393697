<template>
  <b-tr>
    <b-td>
      <p :style="`padding-left: ${30 * level}px`">{{ row.name }}</p>
    </b-td>
    <b-td>
      {{ row.id }}
    </b-td>
    <b-td>
      <Status :value="row.status" />
    </b-td>
    <b-td>
      <div class="table-action-buttons">
        <InlineButton
          variant="outline-secondary"
          size="sm"
          pill
          @click="$emit('edit', row.id)"
          class="has-shadow"
        >
          {{ $t('text.editButton') }}
        </InlineButton>
      </div>
    </b-td>
  </b-tr>
</template>

<script>
import Status from '@/components/ui/Status';
import InlineButton from '@/components/ui/button/InlineButton';

export default {
  name: 'CategoryChild',

  props: {
    row: Object,
    level: {
      type: Number,
      default: 0,
    },
  },

  components: {
    Status,
    InlineButton,
  },
};
</script>
