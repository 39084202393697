<template>
  <TableBox :current-page="currentPage" :meta="meta" @onChangePage="$emit('onChangePage', $event)">
    <b-table
      sticky-header
      id="my-table"
      :fields="fields"
      :items="products"
      :busy="isLoading"
      class="custom-table"
      thead-class="custom-table-header"
      tbody-tr-class="custom-table-body"
      :emptyText="$t('text.emptyText')"
      show-empty
    >
      <template #cell(index)="data">
        {{ (meta.currentPage - 1) * parseInt(meta.perPage, 10) + data.index + 1 }}
      </template>

      <template #cell(productCategory.name)="data">
        <span class="text-nowrap">
          <!-- {{ data.item.productCategory.name }} -->
        </span>
      </template>

      <template #cell(createdAt)="data">
        <span class="text-nowrap">
          {{
            data.item.createdAt ? $options.filters.moment(data.item.createdAt, 'MMM DD, YYYY') : ''
          }}
        </span>
      </template>

      <template #cell(variants)="data">
        <!-- {{ data.item.variants ? data.item.variants.length : 0 }} -->
        <p
          v-for="(variant, index) in data.item.variants"
          :key="index"
          :class="{ 'mt-3': index > 0 }"
        >
          <small class="mr-2">{{ $t('product.variantSku') }}:</small>
          <small>{{ variant.sku }}</small>
          <br />
          <small class="mr-2">{{ $t('product.variantPrice') }}:</small>
          <small>{{ getMasterPrice(variant) | currency }}</small>
        </p>
      </template>

      <template #cell(action)="data">
        <div class="table-action-buttons">
          <InlineButton
            variant="outline-secondary"
            size="sm"
            pill
            :to="{ name: 'ProductEdit', params: { productId: data.item.id } }"
            class="has-shadow"
          >
            {{ $t('text.editButton') }}
          </InlineButton>
          <InlineButton
            variant="outline-danger"
            size="sm"
            pill
            @click="$emit('remove', data.item.id)"
          >
            {{ $t('text.deleteButton') }}
          </InlineButton>
        </div>
      </template>
    </b-table>
  </TableBox>
</template>

<script>
import TableBox from '@/components/ui/TableBox';
import InlineButton from '@/components/ui/button/InlineButton';

export default {
  props: {
    products: Array,
    meta: Object,
    currentPage: Number,
    isLoading: Boolean,
  },

  data() {
    return {
      fields: [
        {
          key: 'index',
          label: '#',
        },
        {
          key: 'name',
          label: this.$t('table.th.name'),
        },
        // {
        //   key: 'productCategory.name',
        //   label: this.$t('table.th.category'),
        // },
        {
          key: 'createdAt',
          label: this.$t('table.th.created'),
        },
        {
          key: 'variants',
          label: this.$t('table.th.variant'),
        },
        {
          key: 'action',
          label: this.$t('table.th.settings'),
        },
      ],
    };
  },

  methods: {
    getMasterPrice(variant) {
      if (variant?.masterPrice?.amount) {
        return variant.masterPrice.amount.amount;
      }
      return 0;
    },
  },

  components: {
    TableBox,
    InlineButton,
  },
};
</script>
