import { find, findIndex } from 'lodash';
import {
  PICK_UP_DURATION_LIST_REQUEST,
  PICK_UP_DURATION_LIST_REQUEST_ERROR,
  PICK_UP_DURATION_LIST_REQUEST_SUCCESS,
  PICK_UP_DURATION_CREATE,
  PICK_UP_DURATION_CREATE_SUCCESS,
  PICK_UP_DURATION_CREATE_ERROR,
  PICK_UP_DURATION_UPDATE,
  PICK_UP_DURATION_UPDATE_SUCCESS,
  PICK_UP_DURATION_UPDATE_ERROR,
  PICK_UP_DURATION_DELETE,
  PICK_UP_DURATION_DELETE_SUCCESS,
  PICK_UP_DURATION_DELETE_ERROR,
} from '@/store/actions/pickUpDuration';
import { handleRequestError } from '@/utils/error';
import Repository, { PICK_UP_DURATIONS } from '@/repositories/RepositoryFactory';

const PickUpDurationsRepository = Repository.get(PICK_UP_DURATIONS);

const state = {
  status: '',
  durationList: [],
  durationListStatus: '',
};

const getters = {
  durationStatus: state => state.status,
  durationList: state => state.durationList,
  durationListStatus: state => state.durationListStatus,
  getDurationById: (state, getters) => id => {
    if (getters.durationList.length > 0) {
      return find(getters.durationList, { id });
    }
    return null;
  },
};

const actions = {
  [PICK_UP_DURATION_LIST_REQUEST]: async ({ commit }, payload) => {
    commit(PICK_UP_DURATION_LIST_REQUEST);
    try {
      const response = await PickUpDurationsRepository.get(payload);
      commit(PICK_UP_DURATION_LIST_REQUEST_SUCCESS, response.data);
    } catch (error) {
      commit(PICK_UP_DURATION_LIST_REQUEST_ERROR);
      handleRequestError(error);
    }
  },
  [PICK_UP_DURATION_CREATE]: async ({ commit }, payload) => {
    commit(PICK_UP_DURATION_CREATE);
    try {
      const response = await PickUpDurationsRepository.create(payload);
      commit(PICK_UP_DURATION_CREATE_SUCCESS, response.data.data);
    } catch (error) {
      commit(PICK_UP_DURATION_CREATE_ERROR);
      handleRequestError(error);
    }
  },
  [PICK_UP_DURATION_UPDATE]: async ({ commit }, { id, data }) => {
    commit(PICK_UP_DURATION_UPDATE);
    try {
      const response = await PickUpDurationsRepository.update(id, data);
      commit(PICK_UP_DURATION_UPDATE_SUCCESS, response.data.data);
    } catch (error) {
      commit(PICK_UP_DURATION_UPDATE_ERROR);
      handleRequestError(error);
    }
  },
  [PICK_UP_DURATION_DELETE]: async ({ commit }, id) => {
    commit(PICK_UP_DURATION_DELETE);
    try {
      await PickUpDurationsRepository.delete(id);
      commit(PICK_UP_DURATION_DELETE_SUCCESS, id);
    } catch (error) {
      commit(PICK_UP_DURATION_DELETE_ERROR);
      handleRequestError(error);
    }
  },
};

/* eslint-disable no-param-reassign */
const mutations = {
  [PICK_UP_DURATION_LIST_REQUEST]: state => {
    state.durationListStatus = 'loading';
  },
  [PICK_UP_DURATION_LIST_REQUEST_SUCCESS]: (state, payload) => {
    state.durationListStatus = 'success';
    state.durationList = payload.data;
  },
  [PICK_UP_DURATION_LIST_REQUEST_ERROR]: state => {
    state.durationListStatus = 'error';
  },
  [PICK_UP_DURATION_CREATE]: state => {
    state.status = 'loading';
  },
  [PICK_UP_DURATION_CREATE_SUCCESS]: (state, payload) => {
    state.status = 'success';
    state.durationList.unshift(payload);
  },
  [PICK_UP_DURATION_CREATE_ERROR]: state => {
    state.status = 'error';
  },
  [PICK_UP_DURATION_UPDATE]: state => {
    state.status = 'loading';
  },
  [PICK_UP_DURATION_UPDATE_SUCCESS]: (state, payload) => {
    state.status = 'success';
    state.durationList = state.durationList.map(item => {
      if (item.id === payload.id) {
        return payload;
      }
      return item;
    });
  },
  [PICK_UP_DURATION_UPDATE_ERROR]: state => {
    state.status = 'error';
  },
  [PICK_UP_DURATION_DELETE]: state => {
    state.status = 'deleting';
  },
  [PICK_UP_DURATION_DELETE_SUCCESS]: (state, payload) => {
    state.status = 'deleted';
    const index = findIndex(state.durationList, { id: payload });
    state.durationList.splice(index, 1);
  },
  [PICK_UP_DURATION_DELETE_ERROR]: state => {
    state.status = 'error';
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
