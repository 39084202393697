<template>
  <ModalMain
    :id="modalId"
    :title="title"
    :ok-title="saveButtonTitle"
    :loading="isLoading"
    :delete-disabled="!formData"
    @hidden="onHidden"
    @ok="onSave"
    @cancel="onCancel"
    @remove="$emit('onRemove', formData.id)"
  >
    <b-form @submit.prevent="onSave">
      <InputString
        class="name"
        :label="$t('label.name')"
        size="lg"
        v-model="$v.form.name.$model"
        :vuelidate="$v.form.name"
        :invalidFeedback="$t('validation.nameRequired')"
      />
      <InputRadioButton
        class="status"
        :label="$t('label.isFilterable')"
        size="lg"
        v-model="form.isFilterable"
        :options="statusOptions"
      />
      <InputRadioButton
        class="type"
        :label="$t('label.type')"
        size="lg"
        v-model="form.type"
        :options="typeOptions"
      />

      <div class="d-flex justify-content-between align-items-center mb-2 mt-5">
        <InputLabel>{{ $t('label.optionTypeValues') }}</InputLabel>
        <InlineButton @click="showAddValueModal" size="sm" variant="primary" pill>
          {{ $t('text.newButton') }}
        </InlineButton>
      </div>
      <div class="option-type-values-container">
        <b-list-group flush class="option-type-values">
          <b-list-group-item
            v-for="(item, index) in form.values"
            :key="index"
            class="option-type-values-item"
          >
            <span>{{ item.name }}</span>
            <div>
              <InlineButton @click="onRemove(index)" size="sm" variant="outline">
                <span class="text-danger">{{ $t('text.deleteButton') }}</span>
              </InlineButton>
            </div>
          </b-list-group-item>
        </b-list-group>
      </div>
    </b-form>

    <ConfirmDialog :id="deleteModalId" @ok="remove" />
    <OptionTypeAddValueModal :modal-id="addValueModalId" @create="createValue" />
  </ModalMain>
</template>

<script>
import { isNil } from 'lodash';
import { mapGetters } from 'vuex';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import { OPTION_TYPE_CREATE, OPTION_TYPE_UPDATE } from '@/store/actions/optionType';
import { YES_OR_NO_OPTIONS } from '@/utils/constants';
import editModalMixin from '@/mixins/editModalMixin';
import ModalMain from '@/components/ui/ModalMain';
import InputString from '@/components/ui/input/InputString';
import InputRadioButton from '@/components/ui/input/InputRadioButton';
import InputLabel from '@/components/ui/input/InputLabel';
import InlineButton from '@/components/ui/button/InlineButton';
import ConfirmDialog from '@/components/ui/ConfirmDialog';
import OptionTypeAddValueModal from '@/components/manage/optionTypes/OptionTypeAddValueModal';

const defaultValuesForForm = () => ({
  name: '',
  isFilterable: true,
  type: 'SINGLE',
  values: [],
});

export default {
  mixins: [validationMixin, editModalMixin],

  props: {
    formData: Object,
    typeOptions: Array,
  },

  data() {
    return {
      form: defaultValuesForForm(),
      statusOptions: YES_OR_NO_OPTIONS,
      deleteModalId: 'remove-option-type-value',
      addValueModalId: 'add-value',
      valueIndexToRemove: null,
    };
  },

  validations: {
    form: {
      name: {
        required,
      },
    },
  },

  watch: {
    formData() {
      this.updateForm();
    },
  },

  computed: {
    ...mapGetters(['optionTypeStatus']),
    isLoading() {
      return this.optionTypeStatus === 'loading';
    },
    title() {
      return isNil(this.formData)
        ? this.$t('optionType.newOptionType')
        : this.$t('optionType.editOptionType');
    },
    saveButtonTitle() {
      return isNil(this.formData) ? this.$t('text.addButton') : this.$t('text.updateButton');
    },
  },

  methods: {
    updateForm() {
      this.form = this.formData
        ? {
            name: this.formData.name,
            isFilterable: this.formData.isFilterable,
            type: this.formData.type ? this.formData.type : 'SINGLE',
            values: this.formData.values ? this.formData.values.map(item => item) : [],
          }
        : defaultValuesForForm();
      this.$v.$reset();
    },
    resetForm() {
      this.form = defaultValuesForForm();
      this.$v.$reset();
    },
    onHidden() {
      this.$emit('onHidden');
      this.resetForm();
    },
    onCancel() {
      this.hideEditModal();
    },
    async onSave() {
      if (this.$v) {
        this.$v.form.$touch();
        if (this.$v.form.$anyError) {
          return;
        }
      }

      const data = this.form;

      if (isNil(this.formData)) {
        await this.$store.dispatch(OPTION_TYPE_CREATE, data);
      } else {
        const payload = {
          id: this.formData.id,
          data,
        };
        await this.$store.dispatch(OPTION_TYPE_UPDATE, payload);
      }
      if (this.optionTypeStatus === 'success') {
        this.hideEditModal();
      }
    },
    onRemove(index) {
      this.valueIndexToRemove = index;
      this.$bvModal.show(this.deleteModalId);
    },
    remove() {
      this.form.values.splice(this.valueIndexToRemove, 1);
    },
    showAddValueModal() {
      this.$bvModal.show(this.addValueModalId);
    },
    createValue(name) {
      this.form.values.unshift({ name });
    },
  },

  components: {
    ModalMain,
    InputString,
    InputRadioButton,
    InputLabel,
    InlineButton,
    ConfirmDialog,
    OptionTypeAddValueModal,
  },
};
</script>

<style lang="scss" scoped>
@import '../../../sass/variables';
@import '../../../sass/flexBox';

.option-type-values-container {
  padding: 2rem 0;
}

.option-type-values {
  border-top: 2px solid $gray-200;
  margin: -2rem;
}

.option-type-values-item {
  padding: 0.75rem 1.5rem;
  @include flexBox(flex, center, space-between);
}

@media (min-width: $breakpoint-md) {
  .option-type-values-item {
    padding: 0.75rem 2rem;
  }
}
</style>
