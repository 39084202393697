import Client from '@/clients/apiClient';
import { stringify } from 'query-string';

const resource = 'users';

export default {
  get(payload) {
    const params = Object.assign(
      {
        page: payload.page,
        limit: payload.limit || 10,
      },
      payload.filter
    );
    return Client.get(`${resource}?${stringify(params)}`);
  },
  show(id) {
    return Client.get(`${resource}/${id}`);
  },
  update(id, data) {
    return Client.put(`${resource}/${id}`, data);
  },
};
