export const SHOP_LIST_REQUEST = 'SHOP_LIST_REQUEST';
export const SHOP_LIST_REQUEST_SUCCESS = 'SHOP_LIST_REQUEST_SUCCESS';
export const SHOP_LIST_REQUEST_ERROR = 'SHOP_LIST_REQUEST_ERROR';

export const SHOP_CREATE = 'SHOP_CREATE';
export const SHOP_CREATE_SUCCESS = 'SHOP_CREATE_SUCCESS';
export const SHOP_CREATE_ERROR = 'SHOP_CREATE_ERROR';

export const SHOP_UPDATE = 'SHOP_UPDATE';
export const SHOP_UPDATE_SUCCESS = 'SHOP_UPDATE_SUCCESS';
export const SHOP_UPDATE_ERROR = 'SHOP_UPDATE_ERROR';

export const SHOP_DELETE = 'SHOP_DELETE';
export const SHOP_DELETE_SUCCESS = 'SHOP_DELETE_SUCCESS';
export const SHOP_DELETE_ERROR = 'SHOP_DELETE_ERROR';

export const SHOP_SHOW = 'SHOP_SHOW';
export const SHOP_SHOW_SUCCESS = 'SHOP_SHOW_SUCCESS';
export const SHOP_SHOW_ERROR = 'SHOP_SHOW_ERROR';

export const ADD_CASHIER = 'ADD_CASHIER';
export const ADD_CASHIER_SUCCESS = 'ADD_CASHIER_SUCCESS';
export const ADD_CASHIER_ERROR = 'ADD_CASHIER_ERROR';

export const DELETE_CASHIER = 'DELETE_CASHIER';
export const DELETE_CASHIER_SUCCESS = 'DELETE_CASHIER_SUCCESS';
export const DELETE_CASHIER_ERROR = 'DELETE_CASHIER_ERROR';
