<template>
  <TableBox :current-page="currentPage" :meta="meta" @onChangePage="$emit('onChangePage', $event)">
    <b-table
      sticky-header
      id="my-table"
      :fields="fields"
      :items="list"
      :busy="isLoading"
      class="custom-table"
      thead-class="custom-table-header"
      tbody-tr-class="custom-table-body"
      :emptyText="$t('text.emptyText')"
      show-empty
    >
      <template #cell(index)="data">
        {{ data.index + 1 }}
      </template>

      <template #cell(createdAt)="data">
        <span class="text-nowrap">
          {{ data.item.createdAt | moment('YYYY-MM-DD') }}
        </span>
      </template>

      <template #cell(name)="data">
        <p class="mb-2">
          <span>{{ data.item.name }}</span>
          <b-icon
            v-if="isAdmin && data.item.isHome"
            icon="star-fill"
            class="text-warning ml-2"
          ></b-icon>
        </p>
        <p class="text-black-50">{{ getBusiness(data.item.businessId) }}</p>
      </template>

      <template #cell(action)="data">
        <div class="table-action-buttons">
          <InlineButton
            variant="outline-secondary"
            size="sm"
            pill
            @click="$emit('edit', data.item.id)"
            class="has-shadow"
          >
            {{ $t('text.editButton') }}
          </InlineButton>
        </div>
      </template>
    </b-table>
  </TableBox>
</template>

<script>
import TableBox from '@/components/ui/TableBox';
import InlineButton from '@/components/ui/button/InlineButton';

export default {
  props: {
    list: Array,
    meta: Object,
    currentPage: Number,
    isLoading: Boolean,
    isAdmin: Boolean,
    businessList: Array,
  },

  data() {
    return {
      fields: [
        {
          key: 'index',
          label: '#',
        },
        {
          key: 'name',
          label: this.$t('table.th.name'),
        },
        {
          key: 'createdAt',
          label: this.$t('table.th.created'),
        },
        {
          key: 'action',
          label: this.$t('table.th.settings'),
        },
      ],
    };
  },

  methods: {
    getBusiness(id) {
      const business = this.businessList.find(item => item.id === id);
      return business ? business.name : '';
    },
  },

  components: {
    TableBox,
    InlineButton,
  },
};
</script>
