export const BLOG_LIST_REQUEST = 'BLOG_LIST_REQUEST';
export const BLOG_LIST_REQUEST_SUCCESS = 'BLOG_LIST_REQUEST_SUCCESS';
export const BLOG_LIST_REQUEST_ERROR = 'BLOG_LIST_REQUEST_ERROR';

export const BLOG_CREATE = 'BLOG_CREATE';
export const BLOG_CREATE_SUCCESS = 'BLOG_CREATE_SUCCESS';
export const BLOG_CREATE_ERROR = 'BLOG_CREATE_ERROR';

export const BLOG_UPDATE = 'BLOG_UPDATE';
export const BLOG_UPDATE_SUCCESS = 'BLOG_UPDATE_SUCCESS';
export const BLOG_UPDATE_ERROR = 'BLOG_UPDATE_ERROR';

export const BLOG_DELETE = 'BLOG_DELETE';
export const BLOG_DELETE_SUCCESS = 'BLOG_DELETE_SUCCESS';
export const BLOG_DELETE_ERROR = 'BLOG_DELETE_ERROR';
