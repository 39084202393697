<template>
  <ModalMain
    :id="modalId"
    :title="title"
    :ok-title="saveButtonTitle"
    :loading="isLoading"
    :delete-disabled="!formData"
    @hidden="onHidden"
    @ok="onSave"
    @cancel="onCancel"
    @remove="$emit('onRemove', formData.id)"
  >
    <InputSelect
      class="business"
      :label="$t('label.business')"
      size="lg"
      v-model="$v.form.businessId.$model"
      :vuelidate="$v.form.businessId"
      :options="businessList"
      :first-item-label="$t('label.notSelectBusiness')"
    />

    <InputSelect
      class="bank"
      :label="$t('label.bank')"
      size="lg"
      v-model="$v.form.bankId.$model"
      :vuelidate="$v.form.bankId"
      :options="bankList"
      :first-item-label="$t('label.notSelectBank')"
    />

    <InputString
      class="account-holder"
      :label="$t('label.accountHolder')"
      size="lg"
      v-model="$v.form.accountHolder.$model"
      :vuelidate="$v.form.accountHolder"
    />

    <InputString
      class="account-number"
      :label="$t('label.accountNumber')"
      type="number"
      size="lg"
      v-model="$v.form.accountNumber.$model"
      :vuelidate="$v.form.accountNumber"
    />
  </ModalMain>
</template>

<script>
import { isNil } from 'lodash';
import { mapGetters } from 'vuex';
import { validationMixin } from 'vuelidate';
import editModalMixin from '@/mixins/editModalMixin';
import { required } from 'vuelidate/lib/validators';
import { BUSINESS_BANK_CREATE, BUSINESS_BANK_UPDATE } from '@/store/actions/businessBank';
import ModalMain from '@/components/ui/ModalMain';
import InputSelect from '@/components/ui/input/InputSelect';
import InputString from '@/components/ui/input/InputString';

const defaultValuesForForm = () => ({
  businessId: null,
  bankId: null,
  accountHolder: '',
  accountNumber: '',
});

export default {
  mixins: [validationMixin, editModalMixin],

  props: {
    formData: Object,
    businessList: Array,
    bankList: Array,
  },

  data() {
    return {
      form: defaultValuesForForm(),
    };
  },

  validations: {
    form: {
      businessId: {
        required,
      },
      bankId: {
        required,
      },
      accountHolder: {
        required,
      },
      accountNumber: {
        required,
      },
    },
  },

  watch: {
    formData() {
      this.updateForm();
    },
  },

  computed: {
    ...mapGetters(['businessBankStatus']),
    isLoading() {
      return this.businessBankStatus === 'loading';
    },
    title() {
      return isNil(this.formData)
        ? this.$t('businessBank.newBusinessBank')
        : this.$t('businessBank.editBusinessBank');
    },
    saveButtonTitle() {
      return isNil(this.formData) ? this.$t('text.addButton') : this.$t('text.updateButton');
    },
  },

  methods: {
    updateForm() {
      this.form = this.formData
        ? {
            businessId: this.formData.businessId,
            bankId: this.formData.bankId,
            accountHolder: this.formData.accountHolder,
            accountNumber: this.formData.accountNumber,
          }
        : defaultValuesForForm();
      this.$v.$reset();
    },
    resetForm() {
      this.form = defaultValuesForForm();
      this.$v.$reset();
    },
    onHidden() {
      this.$emit('onHidden');
      this.resetForm();
    },
    onCancel() {
      this.hideEditModal();
    },
    async onSave() {
      if (this.$v) {
        this.$v.form.$touch();
        if (this.$v.form.$anyError) {
          return;
        }
      }

      const data = this.form;

      if (isNil(this.formData)) {
        await this.$store.dispatch(BUSINESS_BANK_CREATE, data);
      } else {
        const payload = {
          id: this.formData.id,
          data,
        };
        await this.$store.dispatch(BUSINESS_BANK_UPDATE, payload);
      }
      if (this.businessBankStatus === 'success') {
        this.hideEditModal();
      }
    },
  },

  components: {
    ModalMain,
    InputSelect,
    InputString,
  },
};
</script>
