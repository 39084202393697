<template>
  <ModalMain
    :id="modalId"
    :title="$t('product.selectProduct')"
    :loading="isLoading"
    size="lg"
    cancel-disabled
    delete-disabled
    @ok="onCancel"
    @shown="onShown"
    @hidden="onHidden"
  >
    <template #header-content>
      <div class="product-filter">
        <b-row>
          <b-col cols="6">
            <InputSelect
              class="category mb-0"
              size="md"
              v-model="categoryId"
              :options="categories"
              :first-item-label="$t('label.allCategory')"
              :first-item-disabled="false"
              :disabled="isLoading"
              @input="onChangeCategory"
            />
          </b-col>
          <b-col cols="6">
            <InputString
              class="search mb-0"
              size="md"
              v-model="search"
              :placeholder="$t('placeholder.search')"
              @input="inputSearch"
            />
          </b-col>
        </b-row>
        <RoundedButton
          v-if="categoryId || search"
          @click="clearFilters"
          variant="danger"
          is-close-button
          class="clear-button"
        />
      </div>
    </template>
    <SpinLoader v-if="isLoading" variant="light" class="py-5" />
    <div v-else class="product-container">
      <b-row>
        <template v-for="product in products">
          <b-col
            v-for="variant in product.variants"
            :key="variant.id"
            cols="6"
            sm="6"
            md="4"
            lg="3"
          >
            <div class="product">
              <div class="product-image-container">
                <div class="product-image" :style="getImageWithStyle(product)"></div>
                <div class="product-quantity">{{ variant.quantity }}</div>
                <div :class="['product-control', { 'is-active': isSelected(variant.id) }]">
                  <button type="button" @click="subtract(variant.id)">
                    <img src="@/assets/icons/ic_minus_white.svg" />
                  </button>
                  <strong>{{ getQuantity(variant.id) }}</strong>
                  <button type="button" @click="add(variant, product)">
                    <img src="@/assets/icons/ic_plus_white.svg" />
                  </button>
                </div>
              </div>
              <div class="product-info">
                <div class="product-name">{{ variant.sku }} - {{ product.name }}</div>
                <div class="product-price">
                  <strong>{{ getPrice(variant) }}</strong>
                </div>
              </div>
            </div>
          </b-col>
        </template>
      </b-row>
    </div>
  </ModalMain>
</template>

<script>
import { mapGetters } from 'vuex';
import editModalMixin from '@/mixins/editModalMixin';
import { KEY_FILTER_NAME, KEY_FILTER_CATEGORY_ID } from '@/utils/filter';
import { PRODUCT_LIST_REQUEST } from '@/store/actions/product';
import { CATEGORY_LIST_REQUEST } from '@/store/actions/productCategory';
import ModalMain from '@/components/ui/ModalMain';
import SpinLoader from '@/components/ui/SpinLoader';
import InputSelect from '@/components/ui/input/InputSelect';
import InputString from '@/components/ui/input/InputString';
import RoundedButton from '@/components/ui/button/RoundedButton';
import { DEBOUNCE_MILLISECONDS } from '@/config';

export default {
  mixins: [editModalMixin],

  props: {
    selectedVariants: Array,
  },

  data() {
    return {
      search: '',
      timeout: null,
      categoryId: null,
      listData: [],
    };
  },

  computed: {
    ...mapGetters([
      'productList',
      'productListMeta',
      'productListStatus',
      'categoryList',
      'categoryListMeta',
      'categoryListStatus',
    ]),
    isLoading() {
      return this.productListStatus === 'loading';
    },
    categories() {
      return this.categoryList;
    },
    products() {
      return this.listData;
    },
  },

  methods: {
    onShown() {
      this.fetchAllData();
    },
    onHidden() {
      this.listData = [];
      this.categoryId = null;
      this.$emit('onHidden');
    },
    onCancel() {
      this.hideEditModal();
    },
    fetchAllData() {
      this.fetchData();
      this.fetchCategories();
    },
    async fetchData() {
      const payload = {
        limit: 1000,
        page: 1,
        filter: {},
      };

      if (this.search) {
        payload.filter[`${KEY_FILTER_NAME}`] = this.search;
      }

      if (this.categoryId) {
        payload.filter[`${KEY_FILTER_CATEGORY_ID}`] = this.categoryId;
      }

      await this.$store.dispatch(PRODUCT_LIST_REQUEST, payload);
      if (this.productListStatus === 'success') {
        this.listData = this.productList;
      }
    },
    async fetchCategories() {
      const payload = {
        limit: 50,
        page: 1,
        filter: {},
      };
      await this.$store.dispatch(CATEGORY_LIST_REQUEST, payload);
    },
    onChangeCategory() {
      this.search = '';
      this.fetchData();
    },
    inputSearch() {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.fetchData();
      }, DEBOUNCE_MILLISECONDS);
    },
    clearFilters() {
      this.search = '';
      this.categoryId = null;
      this.fetchAllData();
    },
    getImageWithStyle(product) {
      if (product?.images && product.images.length > 0) {
        const { file } = product.images[0];
        if (file) {
          return `background-image: url(${file.path});`;
        }

        return null;
      }

      return null;
    },
    isSelected(id) {
      const variant = this.selectedVariants.find(item => item.variantId === id);
      return !!variant;
    },
    getQuantity(id) {
      const variant = this.selectedVariants.find(item => item.variantId === id);
      return variant ? variant.quantity : 0;
    },
    getPrice(variant) {
      if (variant.salePrice) {
        return this.$options.filters.currency(variant.salePrice.amount.amount);
      }
      return this.$options.filters.currency(variant.masterPrice.amount.amount);
    },
    add(variant, product) {
      this.$emit('add', {
        variantId: variant.id,
        masterPrice: variant.masterPrice,
        salePrice: variant.salePrice,
        sku: variant.sku,
        product: {
          name: product.name,
          images: product.images,
        },
      });
    },
    subtract(variantId) {
      this.$emit('subtract', variantId);
    },
  },

  components: {
    ModalMain,
    SpinLoader,
    InputSelect,
    InputString,
    RoundedButton,
  },
};
</script>

<style lang="scss" scoped>
@import '../../../sass/variables';
@import '../../../sass/flexBox';

.product-filter {
  position: relative;
  width: 100%;
  padding: 0.5rem;
  padding-right: 2.5rem;
  padding-top: 0;

  .row {
    margin: -0.25rem;

    & > div {
      padding: 0.25rem;
    }
  }

  .clear-button {
    position: absolute;
    top: calc(50% - 0.25rem);
    right: 0.5rem;
    transform: translateY(-50%);
  }
}

.product-container {
  .row {
    margin: -0.5rem;

    & > div {
      padding: 0.5rem;
    }
  }
}

.product {
  text-align: center;
  color: $black;
  height: 100%;
  @include flexBox(flex, stretch, flex-start, column);
}

.product-name,
.product-price {
  line-height: 1.2;

  &:not(:last-child) {
    margin-bottom: 0.5rem;
  }
}

.product-name {
  font-size: 13px;
  line-height: 16px;
  max-height: 32px;
  overflow: hidden;
}

.product-info {
  flex-shrink: 1;
  flex-grow: 1;
  text-align: center;
  @include flexBox(flex, center, space-between, column);
}

.product-image-container {
  flex-shrink: 0;
  position: relative;
  padding-top: calc(100% - 2px);
  border-radius: 1.5rem;
  border: 1px solid $light;
  background-color: $light;
  margin-bottom: 0.75rem;
  overflow: hidden;

  & > * {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .product-image {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }

  .product-quantity {
    left: inherit;
    bottom: inherit;
    right: 0.5rem;
    top: 0.5rem;
    min-width: 2.5rem;
    height: 2.5rem;
    padding: 0 0.25rem;
    border-radius: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba($white, 0.75);
  }

  .product-control {
    color: $white;
    font-size: 1.5rem;
    padding: 0 0.75rem;
    background-color: rgba($black, 0.75);
    @include flexBox(flex, center, space-between);

    button {
      outline-width: 0;
      border-width: 0;
      padding: 0;
      margin: 0;
      background-color: transparent;
    }
  }

  @media (min-width: $breakpoint-xl) {
    .product-control {
      opacity: 0;
      transition: opacity 0.3s ease-out;

      &.is-active {
        opacity: 1;
      }
    }

    &:hover {
      .product-control {
        opacity: 1;
      }
    }
  }
}
</style>
