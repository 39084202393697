export const BUSINESS_BANK_LIST_REQUEST = 'BUSINESS_BANK_LIST_REQUEST';
export const BUSINESS_BANK_LIST_REQUEST_SUCCESS = 'BUSINESS_BANK_LIST_REQUEST_SUCCESS';
export const BUSINESS_BANK_LIST_REQUEST_ERROR = 'BUSINESS_BANK_LIST_REQUEST_ERROR';

export const BUSINESS_BANK_CREATE = 'BUSINESS_BANK_CREATE';
export const BUSINESS_BANK_CREATE_SUCCESS = 'BUSINESS_BANK_CREATE_SUCCESS';
export const BUSINESS_BANK_CREATE_ERROR = 'BUSINESS_BANK_CREATE_ERROR';

export const BUSINESS_BANK_UPDATE = 'BUSINESS_BANK_UPDATE';
export const BUSINESS_BANK_UPDATE_SUCCESS = 'BUSINESS_BANK_UPDATE_SUCCESS';
export const BUSINESS_BANK_UPDATE_ERROR = 'BUSINESS_BANK_UPDATE_ERROR';

export const BUSINESS_BANK_DELETE = 'BUSINESS_BANK_DELETE';
export const BUSINESS_BANK_DELETE_SUCCESS = 'BUSINESS_BANK_DELETE_SUCCESS';
export const BUSINESS_BANK_DELETE_ERROR = 'BUSINESS_BANK_DELETE_ERROR';
