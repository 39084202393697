<template>
  <TableBox :current-page="currentPage" :meta="meta" @onChangePage="$emit('onChangePage', $event)">
    <b-table
      sticky-header
      id="my-table"
      :fields="fields"
      :items="list"
      :busy="isLoading"
      class="custom-table"
      thead-class="custom-table-header"
      tbody-tr-class="custom-table-body"
      :empty-text="$t('customers.emptyText')"
      show-empty
    >
      <template #cell(createdAt)="data">
        <span class="text-nowrap">
          {{
            data.item.createdAt ? $options.filters.moment(data.item.createdAt, 'MMM DD, YYYY') : '-'
          }}
        </span>
      </template>

      <template #cell(status)="data">
        <Status :value="data.item.status" />
      </template>

      <template #cell(details)="data">
        <div class="table-action-buttons">
          <InlineButton
            variant="outline-secondary"
            size="sm"
            pill
            @click="$emit('onShowDetails', data.item.id)"
            class="has-shadow"
          >
            {{ $t('text.detailButton') }}
          </InlineButton>
          <InlineButton
            variant="outline-secondary"
            size="sm"
            pill
            @click="$emit('edit', data.item.id)"
            class="has-shadow"
          >
            {{ $t('text.editButton') }}
          </InlineButton>
        </div>
      </template>
    </b-table>
  </TableBox>
</template>
<script>
import TableBox from '@/components/ui/TableBox';
import Status from '@/components/ui/Status';
import InlineButton from '@/components/ui/button/InlineButton';

export default {
  props: {
    isLoading: Boolean,
    meta: Object,
    currentPage: Number,
    list: Array,
  },
  data() {
    return {
      fields: [
        {
          key: 'name',
          label: this.$t('table.th.name'),
        },
        {
          key: 'phone',
          label: this.$t('table.th.phoneNumber'),
        },
        {
          key: 'createdAt',
          label: this.$t('table.th.registered'),
        },
        {
          key: 'status',
          label: this.$t('table.th.status'),
        },
        {
          key: 'details',
          label: '',
        },
      ],
    };
  },
  components: {
    TableBox,
    Status,
    InlineButton,
  },
};
</script>
