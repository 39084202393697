export const PICK_UP_DURATION_LIST_REQUEST = 'PICK_UP_DURATION_LIST_REQUEST';
export const PICK_UP_DURATION_LIST_REQUEST_SUCCESS = 'PICK_UP_DURATION_LIST_REQUEST_SUCCESS';
export const PICK_UP_DURATION_LIST_REQUEST_ERROR = 'PICK_UP_DURATION_LIST_REQUEST_ERROR';

export const PICK_UP_DURATION_CREATE = 'PICK_UP_DURATION_CREATE';
export const PICK_UP_DURATION_CREATE_SUCCESS = 'PICK_UP_DURATION_CREATE_SUCCESS';
export const PICK_UP_DURATION_CREATE_ERROR = 'PICK_UP_DURATION_CREATE_ERROR';

export const PICK_UP_DURATION_UPDATE = 'PICK_UP_DURATION_UPDATE';
export const PICK_UP_DURATION_UPDATE_SUCCESS = 'PICK_UP_DURATION_UPDATE_SUCCESS';
export const PICK_UP_DURATION_UPDATE_ERROR = 'PICK_UP_DURATION_UPDATE_ERROR';

export const PICK_UP_DURATION_DELETE = 'PICK_UP_DURATION_DELETE';
export const PICK_UP_DURATION_DELETE_SUCCESS = 'PICK_UP_DURATION_DELETE_SUCCESS';
export const PICK_UP_DURATION_DELETE_ERROR = 'PICK_UP_DURATION_DELETE_ERROR';
