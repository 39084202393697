<template>
  <BaseLayout :title="fullTitle" padding>
    <b-row>
      <b-col cols="12" md="6" lg="6" xl="6" xxl="4">
        <BoxCard
          :title="$t('text.profile')"
          @click="openProfileEditModal"
          body-paddingless
          class="mb-4 overflow-hidden"
        >
          <b-list-group flush>
            <b-list-group-item class="customer-details-list-item">
              <span>{{ $t('label.name') }}:</span>
              <p>{{ customerDetails | fullName }}</p>
            </b-list-group-item>
            <b-list-group-item class="customer-details-list-item">
              <span>{{ $t('label.email') }}:</span>
              <p>{{ customerDetails.email }}</p>
            </b-list-group-item>
            <b-list-group-item class="customer-details-list-item">
              <span>{{ $t('label.phone') }}:</span>
              <p>{{ customerDetails.phone }}</p>
            </b-list-group-item>
            <b-list-group-item class="customer-details-list-item">
              <span>{{ $t('label.registered') }}:</span>
              <p v-if="customerDetails.createdAt">
                {{ customerDetails.createdAt | moment('YYYY-MM-DD') }}
              </p>
            </b-list-group-item>
            <!-- <b-list-group-item class="customer-details-list-item">
              <span>{{ $t('label.totalOrders') }}:</span>
              <p>{{ customerDetails.productSalesCount }}</p>
            </b-list-group-item>
            <b-list-group-item class="customer-details-list-item">
              <span>{{ $t('label.totalSpent') }}:</span>
              <p>
                {{
                  customerDetails.productSalesSumTotalAmount
                    ? $options.filters.currency(customerDetails.productSalesSumTotalAmount.amount)
                    : ''
                }}
              </p>
            </b-list-group-item> -->
          </b-list-group>
        </BoxCard>
      </b-col>
      <!-- <b-col cols="12" md="6" lg="6" xl="6" xxl="8">
        <BoxCard :title="$t('text.history')" body-paddingless no-button>
          <CustomersDetailsHistoryTable
            :list="customerDetails.productSales"
            :is-loading="isLoading"
          />
        </BoxCard>
      </b-col> -->
    </b-row>
    <CustomerProfileEditModal :modal-id="editModalId" :customerDetails="customerDetails" />
  </BaseLayout>
</template>

<script>
import BaseLayout from '@/components/ui/BaseLayout';
import BoxCard from '@/components/ui/BoxCard';
import { mapGetters } from 'vuex';
import { CUSTOMER_DETAILS_REQUEST } from '@/store/actions/customer';
// import CustomersDetailsHistoryTable from '@/components/manage/customers/CustomersDetailsHistoryTable';
import CustomerProfileEditModal from '@/components/manage/customers/CustomerProfileEditModal';

export default {
  props: {
    title: String,
  },

  data() {
    return {
      editModalId: 'manage-form-modal',
    };
  },

  computed: {
    ...mapGetters(['customerDetails', 'customerStatus']),
    isLoading() {
      return this.customerStatus === 'loading';
    },
    fullTitle() {
      if (this.customerDetails && this.customerDetails.firstName) {
        return `${this.title} / ${this.customerDetails.firstName}`;
      }
      return this.title;
    },
  },

  created() {
    this.fetchData();
  },

  methods: {
    async fetchData() {
      const payload = {
        id: this.$route.params.id,
      };
      await this.$store.dispatch(CUSTOMER_DETAILS_REQUEST, payload);
    },

    openProfileEditModal() {
      this.$bvModal.show(this.editModalId);
    },
  },

  components: {
    BaseLayout,
    BoxCard,
    // CustomersDetailsHistoryTable,
    CustomerProfileEditModal,
  },
};
</script>
<style lang="scss" scoped>
@import '../../../sass/variables';
@import '../../../sass/flexBox';

.customer-details-list-item {
  @include flexBox(flex, center, space-between);
  flex-wrap: wrap;
  padding: 0.75rem 1.5rem;

  span {
    color: $gray-600;
  }
}

@media (min-width: $breakpoint-md) {
  .customer-details-list-item {
    padding: 0.75rem 2rem;
  }
}
</style>
