<template>
  <BaseLayout :title="title">
    <template #actions>
      <InlineButton @click="onCreate" pill shadow>
        <span class="text-uppercase">{{ $t('text.newButton') }}</span>
      </InlineButton>
    </template>

    <BusinessBankTable
      :list="businessBankList"
      :current-page="currentPage"
      :meta="businessBankListMeta"
      :is-loading="isLoading"
      :business-list="businessList"
      :bank-list="bankList"
      @onShowDetails="onShowDetails"
      @edit="onEdit"
      @onChangePage="onChangePage"
    />

    <BusinessBankAddEditModal
      :form-data="dataToEdit"
      :modal-id="editModalId"
      :business-list="businessList"
      :bank-list="bankList"
      @onHidden="onHidden"
      @onRemove="onConfirmDelete"
    />

    <ConfirmDialog :id="deleteModalId" @ok="remove" />
  </BaseLayout>
</template>
<script>
import { mapGetters } from 'vuex';
import { BUSINESS_BANK_LIST_REQUEST, BUSINESS_BANK_DELETE } from '@/store/actions/businessBank';
import { BANK_LIST_REQUEST } from '@/store/actions/bank';
import { BUSINESS_LIST_REQUEST } from '@/store/actions/business';
import BaseLayout from '@/components/ui/BaseLayout';
import InlineButton from '@/components/ui/button/InlineButton';
import BusinessBankTable from '@/components/manage/businessBanks/BusinessBankTable';
import BusinessBankAddEditModal from '@/components/manage/businessBanks/BusinessBankAddEditModal';
import ConfirmDialog from '@/components/ui/ConfirmDialog';

export default {
  props: {
    title: String,
  },

  data() {
    return {
      currentPage: 1,
      dataIdToEdit: null,
      dataIdToRemove: null,
      editModalId: 'manage-form-modal',
      deleteModalId: 'manage-delete-modal',
    };
  },

  computed: {
    ...mapGetters([
      'businessBankList',
      'businessBankListMeta',
      'businessBankListStatus',
      'businessBankStatus',
      'getBusinessBankById',
      'bankList',
      'businessList',
    ]),
    dataToEdit() {
      return this.dataIdToEdit ? this.getBusinessBankById(this.dataIdToEdit) : null;
    },
    isLoading() {
      return this.businessBankListStatus === 'loading';
    },
  },

  created() {
    this.fetchData();
  },

  methods: {
    async fetchData() {
      this.$store.dispatch(BANK_LIST_REQUEST);
      this.$store.dispatch(BUSINESS_LIST_REQUEST, {
        limit: 1000,
        page: 1,
      });

      const payload = {
        limit: 100,
        page: this.currentPage,
      };

      await this.$store.dispatch(BUSINESS_BANK_LIST_REQUEST, payload);
    },
    emptyingDataToEdit() {
      this.dataIdToEdit = null;
    },
    onCreate() {
      this.emptyingDataToEdit();
      this.openModal();
    },
    onEdit(id) {
      this.dataIdToEdit = id;
      this.openModal();
    },
    openModal() {
      this.$bvModal.show(this.editModalId);
    },
    onShowDetails(id) {
      this.$emit('onShowDetails', id);
    },
    onChangePage(page) {
      this.currentPage = page;
      this.fetchData();
    },
    onConfirmDelete(id) {
      this.dataIdToRemove = id;
      this.$bvModal.show(this.deleteModalId);
    },
    async remove() {
      await this.$store.dispatch(BUSINESS_BANK_DELETE, this.dataIdToRemove);
      if (this.businessBankStatus === 'deleted') {
        this.emptyingDataToEdit();
        this.$bvModal.hide(this.editModalId);
        this.dataIdToRemove = null;
        this.fetchData();
      }
    },
    onHidden() {
      this.emptyingDataToEdit();
    },
  },

  components: {
    BaseLayout,
    InlineButton,
    BusinessBankTable,
    BusinessBankAddEditModal,
    ConfirmDialog,
  },
};
</script>
