<template>
  <div>
    <div class="checkout-item-list">
      <template v-if="variantList.length > 0">
        <section
          v-for="(item, itemIndex) in variantList"
          :key="`variant-${itemIndex}`"
          class="item"
        >
          <div>
            <p class="item-sku">{{ item.sku }}</p>
            <p class="item-name">{{ item.product.name }}</p>
            <p class="item-price" :class="{ 'is-danger': item.salePrice }">
              <span v-if="item.salePrice">{{ item.salePrice.amount.amount | currency }}</span>
              <component :is="item.salePrice ? 'del' : 'span'">
                {{ item.masterPrice.amount.amount | currency }}
              </component>
            </p>
          </div>
          <div class="item-right">
            <div class="item-control">
              <button type="button" @click="$emit('subtract', item.variantId)">-</button>
              <strong>{{ item.quantity }}</strong>
              <button type="button" @click="$emit('add', item)">+</button>
            </div>
          </div>
        </section>
      </template>
      <section v-else>
        <p class="mb-0">{{ $t('text.emptyText') }}</p>
      </section>

      <section>
        <div class="text-right">
          <InlineButton @click="showProductModal" variant="light" pill>
            {{ `+ ${$t('text.addButton')}` }}
          </InlineButton>
        </div>
      </section>
    </div>

    <CheckoutSelectProductModal
      :selected-variants="variantList"
      :modal-id="checkoutProductModal"
      @add="$emit('add', $event)"
      @subtract="$emit('subtract', $event)"
    />
  </div>
</template>

<script>
import CheckoutSelectProductModal from '@/components/manage/checkout/CheckoutSelectProductModal';
import InlineButton from '@/components/ui/button/InlineButton';

export default {
  props: {
    variantList: Array,
  },

  data() {
    return {
      checkoutProductModal: 'checkout-product-modal',
    };
  },

  methods: {
    showProductModal() {
      this.$bvModal.show(this.checkoutProductModal);
    },
  },

  components: {
    CheckoutSelectProductModal,
    InlineButton,
  },
};
</script>

<style lang="scss" scoped>
@import '../../../sass/variables';
@import '../../../sass/flexBox';

.checkout-item-list {
  section {
    padding: 1rem 1.5rem;

    & + section {
      border-top: 1px solid $gray-200;
    }
  }

  p {
    margin-bottom: 0;
  }

  .item {
    font-size: 0.875rem;
    line-height: 1.3;
    @include flexBox(flex, center, space-between);

    .item-right {
      margin-left: 0.5rem;

      @include flexBox(flex, center, flex-start);

      .item-control {
        width: 140px;
        height: 42px;
        border-radius: 21px;
        background-color: $gray-200;
        padding: 0 7px;
        @include flexBox(flex, center, space-between);

        button {
          outline-width: 0;
          border-width: 0;
          padding: 0;
          margin: 0;
          background-color: transparent;
          width: 28px;
          height: 28px;
          border-radius: 50%;
          background-color: $gray-600;
          color: $white;
        }
      }
    }

    .item-sku {
      font-weight: 800;
    }

    .item-name {
      color: $gray-600;
    }

    .item-price {
      font-size: 0.875em;
      font-weight: 500;
      margin-top: 0.25rem;

      & > * {
        & + * {
          margin-left: 1rem;
        }
      }

      del {
        color: $gray-500;
      }
    }

    @media (min-width: $breakpoint-xl) {
      font-size: 1rem;

      .item-price {
        min-width: 130px;
      }
    }
  }
}
</style>
