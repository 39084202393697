<template>
  <ModalMain
    :id="modalId"
    :title="title"
    :ok-title="saveButtonTitle"
    :loading="isLoading"
    delete-disabled
    @hidden="onHidden"
    @ok="onSave"
    @cancel="onCancel"
  >
    <InputString
      class="search"
      :label="$t('label.email')"
      size="lg"
      v-model="$v.search.$model"
      :vuelidate="$v.search"
    />
  </ModalMain>
</template>

<script>
import { mapGetters } from 'vuex';
import { validationMixin } from 'vuelidate';
import editModalMixin from '@/mixins/editModalMixin';
import { required, email } from 'vuelidate/lib/validators';
import { ADD_CASHIER } from '@/store/actions/shop';
import { CUSTOMER_LIST_REQUEST } from '@/store/actions/customer';
import { KEY_FILTER_EMAIL } from '@/utils/filter';
import ModalMain from '@/components/ui/ModalMain';
import InputString from '@/components/ui/input/InputString';

export default {
  mixins: [validationMixin, editModalMixin],

  props: {
    shopId: Number,
  },

  data() {
    return {
      search: '',
      user: null,
    };
  },

  validations: {
    search: {
      required,
      email,
    },
  },

  computed: {
    ...mapGetters(['cashierStatus', 'customerList', 'customerListStatus']),
    isLoading() {
      return this.cashierStatus === 'loading';
    },
    title() {
      return this.$t('cashier.newCashier');
    },
    saveButtonTitle() {
      return this.$t('text.addButton');
    },
  },

  methods: {
    async fetchData() {
      const payload = {
        limit: 10,
        page: 1,
        filter: {},
      };
      payload.filter[`${KEY_FILTER_EMAIL}`] = this.search;
      await this.$store.dispatch(CUSTOMER_LIST_REQUEST, payload);
    },
    resetForm() {
      this.user = null;
      this.search = '';
      this.$v.$reset();
    },
    onHidden() {
      this.$emit('onHidden');
      this.resetForm();
    },
    onCancel() {
      this.hideEditModal();
    },
    async onSave() {
      if (this.$v) {
        this.$v.search.$touch();
        if (this.$v.search.$anyError) {
          return;
        }
      }

      await this.fetchData();
      if (this.customerListStatus === 'success' && this.customerList.length > 0) {
        const payload = {
          id: this.shopId,
          data: {
            userId: this.customerList[0].id,
          },
        };
        await this.$store.dispatch(ADD_CASHIER, payload);
        if (this.cashierStatus === 'success') {
          this.hideEditModal();
          this.$emit('onUpdated');
        }
        return;
      }

      this.$bvToast.toast(this.$t('toast.noResultForSearch'), {
        title: this.$t('toast.warning'),
        variant: 'warning',
        solid: true,
      });
    },
  },

  components: {
    ModalMain,
    InputString,
  },
};
</script>
