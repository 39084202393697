<template>
  <BaseLayout :title="title">
    <template #actions>
      <InlineButton @click="onCreate" pill shadow>
        <span class="text-uppercase">{{ $t('text.newButton') }}</span>
      </InlineButton>
    </template>

    <CategoryTable :list="categoryList" :is-loading="isLoading" @edit="onEdit" />

    <CategoryAddEditModal
      :form-data="dataToEdit"
      :modal-id="editModalId"
      @onHidden="onHidden"
      @onRemove="onConfirmDelete"
    />

    <ConfirmDialog :id="deleteModalId" @ok="remove" />
  </BaseLayout>
</template>

<script>
import { mapGetters } from 'vuex';
import { CATEGORY_LIST_REQUEST, CATEGORY_DELETE } from '@/store/actions/productCategory';
import BaseLayout from '@/components/ui/BaseLayout';
import InlineButton from '@/components/ui/button/InlineButton';
import CategoryTable from '@/components/manage/categories/CategoryTable';
import CategoryAddEditModal from '@/components/manage/categories/CategoryAddEditModal';
import ConfirmDialog from '@/components/ui/ConfirmDialog';

export default {
  props: {
    title: String,
  },

  data() {
    return {
      dataIdToEdit: null,
      dataIdToRemove: null,
      editModalId: 'manage-form-modal',
      deleteModalId: 'manage-delete-modal',
    };
  },

  computed: {
    ...mapGetters(['categoryStatus', 'categoryList', 'categoryListStatus', 'getCategoryById']),
    dataToEdit() {
      return this.dataIdToEdit ? this.getCategoryById(this.dataIdToEdit) : null;
    },
    isLoading() {
      return this.categoryListStatus === 'loading';
    },
  },

  created() {
    this.fetchData();
  },

  methods: {
    async fetchData() {
      const payload = {
        filter: {},
      };
      await this.$store.dispatch(CATEGORY_LIST_REQUEST, payload);
    },
    emptyingDataToEdit() {
      this.dataIdToEdit = null;
    },
    onCreate() {
      this.emptyingDataToEdit();
      this.openModal();
    },
    onEdit(id) {
      this.dataIdToEdit = id;
      this.openModal();
    },
    openModal() {
      this.$bvModal.show(this.editModalId);
    },
    onConfirmDelete(id) {
      this.dataIdToRemove = id;
      this.$bvModal.show(this.deleteModalId);
    },
    async remove() {
      await this.$store.dispatch(CATEGORY_DELETE, this.dataIdToRemove);
      if (this.categoryStatus === 'deleted') {
        this.emptyingDataToEdit();
        this.$bvModal.hide(this.editModalId);
        this.dataIdToRemove = null;
        this.fetchData();
      }
    },
    onHidden() {
      this.emptyingDataToEdit();
      this.fetchData();
    },
  },

  components: {
    BaseLayout,
    InlineButton,
    CategoryTable,
    CategoryAddEditModal,
    ConfirmDialog,
  },
};
</script>
