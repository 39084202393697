<template>
  <BaseLayout :title="title">
    <template #actions>
      <InlineButton @click="$router.push({ name: 'Products' })" pill variant="outline-secondary">
        <span class="text-uppercase">
          {{ $t('text.backButton') }}
        </span>
      </InlineButton>
      <InlineButton @click="onSubmit" pill shadow :loading="isLoading">
        <span class="text-uppercase">
          {{ product && product.id ? $t('text.updateButton') : $t('text.newButton') }}
        </span>
      </InlineButton>
    </template>

    <div class="product-form-container">
      <b-row>
        <b-col cols="12" md="4" class="product-form-section">
          <div class="product-form-section-title">{{ $t('text.general') }}</div>
          <div class="product-form-section-body">
            <InputSelect
              v-if="isAdmin"
              class="product-business"
              :label="$t('label.business')"
              size="lg"
              v-model="$v.form.businessId.$model"
              :vuelidate="$v.form.businessId"
              :options="businessList"
              :disabled="isLoadingBusiness || $route.name === 'ProductEdit'"
            />
            <CategorySelectInputWithGroup
              class="product-category"
              :label="$t('label.category')"
              size="lg"
              v-model="$v.form.categoryId.$model"
              :vuelidate="$v.form.categoryId"
              :options="categories"
              show-all-levels
              value-field="id"
              :disabled="isLoadingCategories || $route.name === 'ProductEdit'"
              @change="onChangeCategory"
            />
            <InputString
              class="product-name"
              :label="$t('label.name')"
              size="lg"
              v-model="$v.form.name.$model"
              :vuelidate="$v.form.name"
            />
            <InputEditor :label="$t('label.description')" size="lg" v-model="form.description" />
            <InputString
              class="product-video"
              :label="$t('label.video')"
              size="lg"
              v-model="youtubeLink"
              @input="onChangeYoutubeLink"
            />
          </div>
        </b-col>
        <b-col cols="12" md="4" class="product-form-section">
          <div class="product-form-section-title">{{ $t('text.variantsAndPrices') }}</div>
          <div class="product-form-section-body">
            <SpinLoader v-if="isLoadingOptionTypes" :size="1.5" />
            <ProductVariants
              v-else
              :variants="form.variants"
              :category-id="form.categoryId"
              :categories="categoryList"
              :option-types="optionTypeList"
              :delivery-type-list="deliveryTypeList"
              :delivery-type-list-status="deliveryTypeListStatus"
              @add="addVariant"
              @update="updateVariant"
              @remove="removeVariant"
            />
            <b-form-invalid-feedback :state="!$v.form.variants.$error" class="mt-3">
              {{ $t('validation.variantRequired') }}
            </b-form-invalid-feedback>
          </div>
        </b-col>
        <b-col cols="12" md="4" class="product-form-section">
          <div class="product-form-section-title">{{ $t('text.images') }}</div>
          <div class="product-form-section-body">
            <ProductPhotos
              :photos="form.images"
              :is-loading="isUploadingPhoto"
              :vuelidate="$v.form.images"
              @change="onChangePhoto"
              @remove="onDeletePhoto"
              @feature="onFeaturePhoto"
            />
          </div>
        </b-col>
      </b-row>
    </div>
  </BaseLayout>
</template>

<script>
import { mapGetters } from 'vuex';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import { PRODUCT_CREATE, PRODUCT_UPDATE, PRODUCT_REQUEST } from '@/store/actions/product';
import { CATEGORY_LIST_REQUEST } from '@/store/actions/productCategory';
import { OPTION_TYPE_LIST_REQUEST } from '@/store/actions/optionType';
import { BUSINESS_LIST_REQUEST } from '@/store/actions/business';
import { DELIVERY_TYPE_LIST_REQUEST } from '@/store/actions/deliveryType';
import meMixin from '@/mixins/meMixin';
import { FILE_DELETE, FILE_UPLOAD } from '@/store/actions/file';
import SpinLoader from '@/components/ui/SpinLoader';
import BaseLayout from '@/components/ui/BaseLayout';
import ProductPhotos from '@/components/manage/products/ProductPhotos';
import CategorySelectInputWithGroup from '@/components/manage/categories/CategorySelectInputWithGroup';
import InputSelect from '@/components/ui/input/InputSelect';
import InputString from '@/components/ui/input/InputString';
import InputEditor from '@/components/ui/input/InputEditor';
import InlineButton from '@/components/ui/button/InlineButton';
import ProductVariants from '@/components/manage/products/ProductVariants';

const defaultValuesForForm = () => ({
  name: '',
  businessId: null,
  description: '',
  categoryId: null,
  variants: [],
  images: [],
  status: true,
  video: '',
});

export default {
  mixins: [validationMixin, meMixin],

  props: {
    title: String,
  },

  data() {
    return {
      form: defaultValuesForForm(),
      youtubeLink: '',
    };
  },

  validations() {
    if (this.isAdmin) {
      return {
        form: {
          businessId: {
            required,
          },
          name: {
            required,
          },
          categoryId: {
            required,
          },
          variants: {
            required,
          },
          images: {
            required,
          },
        },
      };
    }
    return {
      form: {
        name: {
          required,
        },
        categoryId: {
          required,
        },
        variants: {
          required,
        },
        images: {
          required,
        },
      },
    };
  },

  computed: {
    ...mapGetters([
      'getFileUpload',
      'categoryList',
      'categoryListStatus',
      'productStatus',
      'product',
      'optionTypeList',
      'optionTypeListStatus',
      'businessList',
      'businessListStatus',
      'deliveryTypeList',
      'deliveryTypeListStatus',
    ]),
    isAdmin() {
      return !!this.me.isAdmin;
    },
    isLoading() {
      return this.productStatus === 'loading';
    },
    isLoadingCategories() {
      return this.categoryListStatus === 'loading';
    },
    isUploadingPhoto() {
      return this.getFileUpload.status === 'loading';
    },
    isLoadingOptionTypes() {
      return this.optionTypeListStatus === 'loading';
    },
    isLoadingBusiness() {
      return this.businessListStatus === 'loading';
    },
    categories() {
      return this.categoryList.map(category => {
        return {
          ...category,
          disabled: !(category.optionTypes && category.optionTypes.length > 0),
          children: category.children.map(firstChild => {
            return {
              ...firstChild,
              disabled: !(firstChild.optionTypes && firstChild.optionTypes.length > 0),
              children: firstChild.children.map(secondChild => {
                return {
                  ...secondChild,
                  disabled: !(secondChild.optionTypes && secondChild.optionTypes.length > 0),
                };
              }),
            };
          }),
        };
      });
    },
  },

  async created() {
    const payload = {
      limit: 1000,
      page: 1,
      filter: {},
    };
    this.$store.dispatch(CATEGORY_LIST_REQUEST, payload);
    this.$store.dispatch(OPTION_TYPE_LIST_REQUEST);
    this.$store.dispatch(DELIVERY_TYPE_LIST_REQUEST, payload);
    if (this.$route.params.productId) {
      await this.$store.dispatch(PRODUCT_REQUEST, this.$route.params.productId);
      if (this.productStatus === 'success') {
        await this.updateForm();
      }
    }
    if (this.isAdmin) {
      this.$store.dispatch(BUSINESS_LIST_REQUEST, payload);
    }
  },

  methods: {
    updateForm() {
      this.form = {
        name: this.product.name,
        businessId: this.product.businessId,
        description: this.product.description,
        categoryId:
          this.product.productCategories && this.product.productCategories.length > 0
            ? this.product.productCategories[0].id
            : null,
        images: [...this.product.images],
        variants: this.product.variants.map(item => {
          return {
            id: item.id,
            deliveryTypeId: item.deliveryTypeId,
            // masterPrice: item.masterPrice ? parseInt(item.masterPrice.amount.amount, 10) : 0,
            prices: item.prices.map(price => {
              return {
                amount: price.amount ? parseInt(price.amount.amount, 10) : 0,
                type: price.type,
                startDate: price.startDate,
                endDate: price.endDate,
                status: true,
              };
            }),
            optionValues: item.optionValues,
            sku: item.sku,
            sizeString: item.sizeString,
            // width: item.width,
            // depth: item.depth,
            // height: item.height,
            // weight: item.weight,
          };
        }),
        status: this.product.status,
        video: this.product.video,
      };
      if (this.product?.video) {
        this.youtubeLink = `https://www.youtube.com/watch?v=${this.product.video}`;
      }
    },
    onChangeYoutubeLink(payload) {
      if (payload) {
        const videoId = payload.split('v=')[1];
        if (videoId) {
          const ampersandPosition = videoId.indexOf('&');
          if (ampersandPosition !== -1) {
            this.form.video = videoId.substring(0, ampersandPosition);
          } else {
            this.form.video = videoId;
          }
        }
        return;
      }

      this.form.video = '';
    },
    async onChangePhoto(e) {
      const file = e.target.files[0];
      await this.$store.dispatch(FILE_UPLOAD, file);
      if (this.getFileUpload.status === 'success') {
        const isFeature = !(this.form.images.length > 0);
        this.form.images.push({ file: this.getFileUpload.item, isFeature });
      }
    },
    async onDeletePhoto(index) {
      const image = this.form.images[index];
      if (image && image.file) {
        await this.$store.dispatch(FILE_DELETE, image.file.id);
        this.form.images.splice(index, 1);
      }
    },
    onFeaturePhoto(imageIndex) {
      this.form.images = this.form.images.map((image, index) => {
        return {
          ...image,
          isFeature: imageIndex === index,
        };
      });
    },
    onChangeCategory() {},
    addVariant(payload) {
      this.form.variants.push(payload);
    },
    updateVariant(payload) {
      this.form.variants = this.form.variants.map((variant, index) => {
        if (index === payload.index) {
          return payload.data;
        }
        return variant;
      });
    },
    removeVariant(index) {
      this.form.variants.splice(index, 1);
    },
    async onSubmit() {
      if (this.$v) {
        this.$v.form.$touch();
        if (this.$v.form.$anyError) {
          return;
        }
      }

      const variants = this.form.variants.map(variant => {
        return {
          ...variant,
          prices: [
            ...variant.prices,
            // {
            //   amount: variant.masterPrice,
            //   endDate: '',
            //   startDate: '',
            //   status: true,
            //   type: 'MASTER',
            // },
          ],
        };
      });

      let data = { ...this.form, variants };

      if (!this.isAdmin) {
        data = { ...this.form, businessId: this.me.businessId };
      }

      if (this.$route.params.productId) {
        const payload = {
          id: this.$route.params.productId,
          data,
        };
        await this.$store.dispatch(PRODUCT_UPDATE, payload);
      } else {
        await this.$store.dispatch(PRODUCT_CREATE, data);
      }
      if (this.productStatus === 'success') {
        this.$router.push({ name: 'Products' });
      }
    },
  },

  components: {
    SpinLoader,
    BaseLayout,
    CategorySelectInputWithGroup,
    InputSelect,
    InputString,
    InputEditor,
    InlineButton,
    ProductPhotos,
    ProductVariants,
  },
};
</script>

<style lang="scss" scoped>
@import '../../../sass/variables';
@import '../../../sass/flexBox';

.product-form-container {
  min-height: 100%;

  .row {
    margin-right: 0;
    margin-left: 0;

    & > div {
      padding-left: 0;
      padding-right: 0;
    }
  }
}

.product-form-section-title {
  font-size: 0.75rem;
  font-weight: bold;
  text-transform: uppercase;
  line-height: 1.2;
  padding: 0.75rem 1rem;
  background-color: $gray-200;
}

.product-form-section-body {
  padding: 1rem;
}

@media (min-width: $breakpoint-md) {
  .product-form-container {
    @include flexBox(flex, stretch, flex-start);

    .row {
      width: 100%;
    }
  }

  .product-form-section {
    position: relative;

    & + .product-form-section {
      border-left: 1px solid $gray-100;
    }
  }
}

@media (min-width: $breakpoint-lg) {
  .product-form-section-title {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }

  .product-form-section-body {
    padding: 2.5rem;
  }
}

@media (min-width: $breakpoint-xxl) {
  .product-form-section-title {
    padding-left: 3.75rem;
    padding-right: 3.75rem;
  }

  .product-form-section-body {
    padding: 3.75rem;
  }
}
</style>
