<template>
  <ModalMain
    :id="modalId"
    :title="title"
    :ok-title="saveButtonTitle"
    :loading="isLoading"
    delete-disabled
    @hidden="onHidden"
    @ok="onSave"
    @cancel="onCancel"
  >
    <b-form @submit.prevent="onSave">
      <InputString
        class="staff-name"
        :label="$t('text.name')"
        size="lg"
        v-model="$v.form.name.$model"
        :vuelidate="$v.form.name"
      />
      <InputString
        class="staff-email"
        :label="$t('text.email')"
        size="lg"
        v-model="$v.form.email.$model"
        :vuelidate="$v.form.email"
      />
      <InputString
        class="staff-phone"
        :label="$t('staff.phone')"
        size="lg"
        v-model="$v.form.phone.$model"
        :vuelidate="$v.form.phone"
      />
      <InputPassword
        class="staff-password"
        :label="$t('staff.password')"
        size="lg"
        v-model="$v.form.password.$model"
        :vuelidate="$v.form.password"
        :invalidFeedback="$t('validation.passwordInvalidFeedback')"
      />
      <InputSelect
        class="staff-type"
        :label="$t('staff.type')"
        size="lg"
        v-model="$v.form.type.$model"
        :vuelidate="$v.form.type"
        :options="typeList"
        value-field="value"
      />
      <InputRadioButton
        class="staff-status"
        :label="$t('text.status')"
        size="lg"
        v-model="form.status"
        :options="statusOptions"
      ></InputRadioButton>
    </b-form>
  </ModalMain>
</template>
<script>
import { isNil } from 'lodash';
import { mapGetters } from 'vuex';
import { validationMixin } from 'vuelidate';
import { email, required, helpers } from 'vuelidate/lib/validators';
import { STAFF_CREATE, STAFF_UPDATE } from '@/store/actions/staff';
import { STATUS_OPTIONS } from '@/utils/constants';
import passwordRegExp from '@/utils/password';
import editModalMixin from '@/mixins/editModalMixin';
import ModalMain from '@/components/ui/ModalMain';
import InputString from '@/components/ui/input/InputString';
import InputRadioButton from '@/components/ui/input/InputRadioButton';
import InputPassword from '@/components/ui/input/InputPassword';
import InputSelect from '@/components/ui/input/InputSelect';

const defaultValuesForForm = () => ({
  name: '',
  email: '',
  phone: '',
  password: '',
  type: '',
  status: true,
});

export default {
  mixins: [validationMixin, editModalMixin],

  props: {
    staff: Object,
    typeList: Array,
  },

  data() {
    return {
      modalId: 'manage-staff-modal',
      form: defaultValuesForForm(),
      statusOptions: STATUS_OPTIONS,
    };
  },

  validations() {
    if (isNil(this.staff)) {
      return {
        form: {
          name: {
            required,
          },
          email: {
            required,
            email,
          },
          phone: {
            required,
          },
          type: {
            required,
          },
          password: {
            required,
            password: helpers.regex('password', passwordRegExp),
          },
        },
      };
    }
    return {
      form: {
        name: {
          required,
        },
        email: {
          required,
          email,
        },
        phone: {
          required,
        },
        type: {
          required,
        },
        password: {
          password: helpers.regex('password', passwordRegExp),
        },
      },
    };
  },

  watch: {
    staff() {
      this.updateForm();
    },
  },

  computed: {
    ...mapGetters(['staffStatus']),
    isLoading() {
      return this.staffStatus === 'loading';
    },
    title() {
      return isNil(this.staff) ? this.$t('staff.newStaff') : this.$t('staff.editStaff');
    },
    saveButtonTitle() {
      return isNil(this.staff) ? this.$t('staff.addStaff') : this.$t('staff.updateStaff');
    },
  },

  methods: {
    updateForm() {
      this.form = this.staff
        ? {
            name: this.staff.name,
            email: this.staff.email,
            phone: this.staff.phone,
            password: '',
            type: this.staff.type,
            status: this.staff.status,
          }
        : defaultValuesForForm();
      this.$v.$reset();
    },
    resetForm() {
      this.form = defaultValuesForForm();
      this.$v.$reset();
    },
    onHidden() {
      this.$emit('onHidden');
      this.resetForm();
    },
    onCancel() {
      this.hideEditModal();
    },
    async onSave() {
      if (this.$v) {
        this.$v.form.$touch();
        if (this.$v.form.$anyError) {
          return;
        }
      }

      const data = this.form;

      if (isNil(this.staff)) {
        await this.$store.dispatch(STAFF_CREATE, data);
      } else {
        const payload = {
          id: this.staff.id,
          data: {
            ...data,
            password: this.form.password || null,
          },
        };
        await this.$store.dispatch(STAFF_UPDATE, payload);
      }
      if (this.staffStatus === 'success') {
        this.hideEditModal();
      }
    },
  },

  components: {
    ModalMain,
    InputString,
    InputPassword,
    InputRadioButton,
    InputSelect,
  },
};
</script>
