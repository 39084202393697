export const ADDON_REQUEST = 'ADDON_REQUEST';
export const ADDON_REQUEST_SUCCESS = 'ADDON_REQUEST_SUCCESS';
export const ADDON_REQUEST_ERROR = 'ADDON_REQUEST_ERROR';

export const ADDON_LIST_REQUEST = 'ADDON_LIST_REQUEST';
export const ADDON_LIST_REQUEST_SUCCESS = 'ADDON_LIST_REQUEST_SUCCESS';
export const ADDON_LIST_REQUEST_ERROR = 'ADDON_LIST_REQUEST_ERROR';

export const ADDON_CREATE = 'ADDON_CREATE';
export const ADDON_CREATE_SUCCESS = 'ADDON_CREATE_SUCCESS';
export const ADDON_CREATE_ERROR = 'ADDON_CREATE_ERROR';

export const ADDON_UPDATE = 'ADDON_UPDATE';
export const ADDON_UPDATE_SUCCESS = 'ADDON_UPDATE_SUCCESS';
export const ADDON_UPDATE_ERROR = 'ADDON_UPDATE_ERROR';

export const ADDON_DELETE = 'ADDON_DELETE';
export const ADDON_DELETE_SUCCESS = 'ADDON_DELETE_SUCCESS';
export const ADDON_DELETE_ERROR = 'ADDON_DELETE_ERROR';
