import { find, findIndex } from 'lodash';
import {
  BUSINESS_BANK_LIST_REQUEST,
  BUSINESS_BANK_LIST_REQUEST_ERROR,
  BUSINESS_BANK_LIST_REQUEST_SUCCESS,
  BUSINESS_BANK_CREATE,
  BUSINESS_BANK_CREATE_SUCCESS,
  BUSINESS_BANK_CREATE_ERROR,
  BUSINESS_BANK_UPDATE,
  BUSINESS_BANK_UPDATE_SUCCESS,
  BUSINESS_BANK_UPDATE_ERROR,
  BUSINESS_BANK_DELETE,
  BUSINESS_BANK_DELETE_SUCCESS,
  BUSINESS_BANK_DELETE_ERROR,
} from '@/store/actions/businessBank';
import { handleRequestError } from '@/utils/error';
import Repository, { BUSINESS_BANKS } from '@/repositories/RepositoryFactory';

const BusinessBanksRepository = Repository.get(BUSINESS_BANKS);

const state = {
  status: '',
  businessBank: {},
  businessBankList: [],
  businessBankListMeta: {},
  businessBankListStatus: '',
};

const getters = {
  businessBank: state => state.businessBank,
  businessBankStatus: state => state.status,
  businessBankList: state => state.businessBankList,
  businessBankListMeta: state => state.businessBankListMeta,
  businessBankListStatus: state => state.businessBankListStatus,
  getBusinessBankById: (state, getters) => id => {
    if (getters.businessBankList.length > 0) {
      return find(getters.businessBankList, { id });
    }
    return state.businessBank;
  },
};

const actions = {
  [BUSINESS_BANK_LIST_REQUEST]: async ({ commit }, payload) => {
    commit(BUSINESS_BANK_LIST_REQUEST);
    try {
      const response = await BusinessBanksRepository.get(payload);
      commit(BUSINESS_BANK_LIST_REQUEST_SUCCESS, response.data);
    } catch (error) {
      commit(BUSINESS_BANK_LIST_REQUEST_ERROR);
      handleRequestError(error);
    }
  },
  [BUSINESS_BANK_CREATE]: async ({ commit }, payload) => {
    commit(BUSINESS_BANK_CREATE);
    try {
      const response = await BusinessBanksRepository.create(payload);
      commit(BUSINESS_BANK_CREATE_SUCCESS, response.data.data);
    } catch (error) {
      commit(BUSINESS_BANK_CREATE_ERROR);
      handleRequestError(error);
    }
  },
  [BUSINESS_BANK_UPDATE]: async ({ commit }, { id, data }) => {
    commit(BUSINESS_BANK_UPDATE);
    try {
      const response = await BusinessBanksRepository.update(id, data);
      commit(BUSINESS_BANK_UPDATE_SUCCESS, response.data.data);
    } catch (error) {
      commit(BUSINESS_BANK_UPDATE_ERROR);
      handleRequestError(error);
    }
  },
  [BUSINESS_BANK_DELETE]: async ({ commit }, id) => {
    commit(BUSINESS_BANK_DELETE);
    try {
      await BusinessBanksRepository.delete(id);
      commit(BUSINESS_BANK_DELETE_SUCCESS, id);
    } catch (error) {
      commit(BUSINESS_BANK_DELETE_ERROR);
      handleRequestError(error);
    }
  },
};

/* eslint-disable no-param-reassign */
const mutations = {
  [BUSINESS_BANK_LIST_REQUEST]: state => {
    state.businessBankListStatus = 'loading';
  },
  [BUSINESS_BANK_LIST_REQUEST_SUCCESS]: (state, payload) => {
    state.businessBankListStatus = 'success';
    state.businessBankList = payload.data;
    if (payload.meta) {
      state.businessBankListMeta = payload.meta;
    }
  },
  [BUSINESS_BANK_LIST_REQUEST_ERROR]: state => {
    state.businessBankListStatus = 'error';
  },
  [BUSINESS_BANK_CREATE]: state => {
    state.status = 'loading';
  },
  [BUSINESS_BANK_CREATE_SUCCESS]: (state, payload) => {
    state.status = 'success';
    state.businessBankList.push(payload);
  },
  [BUSINESS_BANK_CREATE_ERROR]: state => {
    state.status = 'error';
  },
  [BUSINESS_BANK_UPDATE]: state => {
    state.status = 'loading';
  },
  [BUSINESS_BANK_UPDATE_SUCCESS]: (state, payload) => {
    state.status = 'success';
    state.businessBankList = state.businessBankList.map(item => {
      if (item.id === payload.id) {
        return payload;
      }
      return item;
    });
  },
  [BUSINESS_BANK_UPDATE_ERROR]: state => {
    state.status = 'error';
  },
  [BUSINESS_BANK_DELETE]: state => {
    state.status = 'deleting';
  },
  [BUSINESS_BANK_DELETE_SUCCESS]: (state, payload) => {
    state.status = 'deleted';
    const index = findIndex(state.businessBankList, { id: payload });
    state.businessBankList.splice(index, 1);
  },
  [BUSINESS_BANK_DELETE_ERROR]: state => {
    state.status = 'error';
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
