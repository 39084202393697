<template>
  <TableBox>
    <b-table-simple hover sticky-header>
      <b-thead>
        <b-tr>
          <b-th v-for="(field, index) in fields" :key="index">{{ field.label }}</b-th>
        </b-tr>
      </b-thead>
      <template v-for="(row, rowIndex) in list">
        <b-tbody :key="rowIndex" class="category-tbody">
          <CategoryChild @edit="$emit('edit', $event)" :row="row" />
          <template v-for="(rowChild, rowChildIndex) in row.children">
            <CategoryChild
              @edit="$emit('edit', $event)"
              :key="`child-${rowChildIndex}`"
              :row="rowChild"
              :level="1"
            />
            <template v-for="(rowSecondChild, rowSecondChildIndex) in rowChild.children">
              <CategoryChild
                @edit="$emit('edit', $event)"
                :key="`child-second-${rowChildIndex}-${rowSecondChildIndex}`"
                :row="rowSecondChild"
                :level="2"
              />
            </template>
          </template>
        </b-tbody>
      </template>
    </b-table-simple>
  </TableBox>
</template>

<script>
import TableBox from '@/components/ui/TableBox';
import CategoryChild from '@/components/manage/categories/CategoryChild';

export default {
  props: {
    list: Array,
  },

  data() {
    return {
      fields: [
        {
          key: 'name',
          label: this.$t('table.th.categoryName'),
        },
        {
          key: 'id',
          label: 'ID',
        },
        {
          key: 'status',
          label: this.$t('table.th.status'),
        },
        {
          key: 'action',
          label: this.$t('table.th.settings'),
        },
      ],
    };
  },

  components: {
    TableBox,
    CategoryChild,
  },
};
</script>

<style lang="scss" scoped>
@import '../../../sass/variables';

::v-deep {
  .category-tbody {
    tr:first-child {
      p {
        font-size: 1.25rem;
        font-weight: bold;
      }
    }

    & + .category-tbody {
      border-color: $gray-400;
    }
  }
}
</style>
