<template>
  <div>
    <template v-if="variants.length > 0">
      <div v-for="(variant, index) in variants" :key="index">
        <div>
          <small class="mr-2">{{ $t('product.variantSku') }}:</small>
          <small>{{ variant.sku }}</small>
        </div>
        <div class="d-flex align-items-center flex-wrap">
          <small class="mr-2">{{ $t('product.variantSize') }}:</small>
          <small>{{ variant.sizeString }}</small>
        </div>
        <div>
          <small class="mr-2">{{ $t('product.variantPrice') }}:</small>
          <small>{{ getMasterPrice(variant.prices) | currency }}</small>
        </div>
        <div v-if="variant.deliveryTypeId">
          <small class="mr-2">{{ $t('product.deliveryType') }}:</small>
          <small>{{ getDeliveryType(variant.deliveryTypeId) }}</small>
        </div>
        <div>
          <b-badge
            variant="light"
            v-for="optionValue in variant.optionValues"
            :key="optionValue.id"
            class="mr-1"
          >
            {{ getOptionValue(optionValue.id) }}
          </b-badge>
        </div>
        <div class="text-right">
          <InlineButton @click="onEdit(index)" variant="warning" size="sm" class="mt-2 ml-2">
            {{ $t('text.editButton') }}
          </InlineButton>
          <InlineButton
            @click="onRemove(index)"
            variant="outline-danger"
            size="sm"
            class="mt-2 ml-2"
          >
            {{ $t('text.deleteButton') }}
          </InlineButton>
        </div>
        <hr v-if="index + 1 < variants.length" />
      </div>
    </template>
    <template v-else>
      <p class="mb-0">{{ $t('text.emptyText') }}</p>
    </template>

    <hr />

    <InlineButton @click="onCreate" pill dashed variant="outline-primary">
      {{ $t('product.addVariant') }}
    </InlineButton>

    <ProductVariantAddEditModal
      :modal-id="addModalId"
      :option-types="selectableOptionTypes"
      :form-data="variantToEdit"
      :delivery-type-list="deliveryTypeList"
      :delivery-type-list-status="deliveryTypeListStatus"
      @click="onSave"
      @onHidden="onHiddenModal"
    />

    <ConfirmDialog :id="deleteModalId" @ok="remove" />
  </div>
</template>

<script>
import { isNil } from 'lodash';
import InlineButton from '@/components/ui/button/InlineButton';
import ConfirmDialog from '@/components/ui/ConfirmDialog';
import ProductVariantAddEditModal from '@/components/manage/products/ProductVariantAddEditModal';

export default {
  props: {
    variants: Array,
    categoryId: Number,
    categories: Array,
    optionTypes: Array,
    deliveryTypeList: Array,
    deliveryTypeListStatus: String,
  },

  data() {
    return {
      addModalId: 'add-variant-modal',
      deleteModalId: 'remove-variant-modal',
      variantIndexToRemove: null,
      variantIndexToEdit: null,
      variantToEdit: null,
    };
  },

  computed: {
    optionValues() {
      return this.optionTypes.reduce((array, item) => {
        item.values.forEach(value => array.push(value));
        return array;
      }, []);
    },
    selectableOptionTypes() {
      const allCategories = this.findItemNested(this.categories, this.categoryId, 'children');
      const category = allCategories.find(item => item.id === this.categoryId);
      if (category) {
        const optionTypes = category.optionTypes.map(item => item.id);
        return this.optionTypes.filter(optionType => optionTypes.includes(optionType.id));
      }
      return [];
    },
  },

  methods: {
    getDeliveryType(id) {
      const type = this.deliveryTypeList.find(item => item.id === id);
      return type ? type.name : '';
    },
    getMasterPrice(prices) {
      const result = prices.find(item => item.type === 'MASTER');
      return result ? result.amount : 0;
    },
    onCreate() {
      if (this.categoryId) {
        this.$bvModal.show(this.addModalId);
        return;
      }

      this.$bvToast.toast(this.$t('toast.pleaseChooseProductCategory'), {
        title: this.$t('toast.warning'),
        variant: 'warning',
        solid: true,
      });
    },
    onEdit(index) {
      this.variantIndexToEdit = index;
      this.variantToEdit = this.variants[index];
      this.$bvModal.show(this.addModalId);
    },
    onHiddenModal() {
      this.variantIndexToEdit = null;
      this.variantToEdit = null;
    },
    onRemove(index) {
      this.variantIndexToRemove = index;
      this.$bvModal.show(this.deleteModalId);
    },
    remove() {
      this.$emit('remove', this.variantIndexToRemove);
    },
    getOptionValue(id) {
      const result = this.optionValues.find(item => item.id === id);
      if (result) {
        return result.name;
      }

      return id;
    },
    findItemNested(array, searchString, nestingKey) {
      return array.reduce((acc, { [nestingKey]: nested, ...o }) => {
        if (o.id === searchString) acc.push(o);

        if (nested) acc.push(...this.findItemNested(nested, searchString, nestingKey));

        return acc;
      }, []);
    },
    onSave(payload) {
      if (isNil(this.variantIndexToEdit)) {
        this.$emit('add', payload);
      } else {
        this.$emit('update', { data: payload, index: this.variantIndexToEdit });
      }

      this.variantIndexToEdit = null;
    },
  },

  components: {
    InlineButton,
    ConfirmDialog,
    ProductVariantAddEditModal,
  },
};
</script>
