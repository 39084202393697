import Client from '@/clients/apiClient';

const resource = 'shops';

export default {
  get() {
    return Client.get(`${resource}`);
  },
  create(payload) {
    return Client.post(`${resource}`, payload);
  },
  update(id, payload) {
    return Client.put(`${resource}/${id}`, payload);
  },
  delete(id) {
    return Client.delete(`${resource}/${id}`);
  },
  show(id) {
    return Client.get(`${resource}/${id}`);
  },
  addCashier(id, payload) {
    return Client.post(`${resource}/${id}/add-cashier/`, payload);
  },
  deleteCashier(id, payload) {
    return Client.post(`${resource}/${id}/delete-cashier/`, payload);
  },
};
