<template>
  <ModalMain
    :id="modalId"
    :title="title"
    :ok-title="saveButtonTitle"
    :loading="isLoading"
    :delete-disabled="!isAdmin || !formData"
    @hidden="onHidden"
    @ok="onSave"
    @cancel="onCancel"
    @remove="$emit('onRemove', formData.id)"
  >
    <InputSelect
      v-if="isAdmin"
      class="business"
      :label="$t('label.business')"
      size="lg"
      v-model="$v.form.businessId.$model"
      :vuelidate="$v.form.businessId"
      :options="businessList"
      :first-item-label="$t('label.notSelectBusiness')"
      :first-item-disabled="true"
    />
    <InputString
      class="name"
      :label="$t('label.name')"
      size="lg"
      v-model="$v.form.name.$model"
      :vuelidate="$v.form.name"
    />
    <InputString
      class="phone"
      :label="$t('label.phone')"
      size="lg"
      v-model="$v.form.phone.$model"
      :vuelidate="$v.form.phone"
    />
    <InputEditor
      class="address"
      :label="$t('label.address')"
      size="lg"
      v-model="$v.form.address.$model"
      :vuelidate="$v.form.address"
    />
    <InputEditor
      class="time-table mb-0"
      :label="$t('label.timeTable')"
      size="lg"
      v-model="$v.form.timeTable.$model"
      :vuelidate="$v.form.timeTable"
    />
  </ModalMain>
</template>

<script>
import { isNil } from 'lodash';
import { mapGetters } from 'vuex';
import { validationMixin } from 'vuelidate';
import editModalMixin from '@/mixins/editModalMixin';
import { required, helpers } from 'vuelidate/lib/validators';
import phoneRegex from '@/utils/phone';
import { SHOP_CREATE, SHOP_UPDATE } from '@/store/actions/shop';
import ModalMain from '@/components/ui/ModalMain';
import InputSelect from '@/components/ui/input/InputSelect';
import InputString from '@/components/ui/input/InputString';
import InputEditor from '@/components/ui/input/InputEditor';

const defaultValuesForForm = () => ({
  businessId: null,
  name: '',
  phone: '',
  address: '',
  timeTable: '',
});

export default {
  mixins: [validationMixin, editModalMixin],

  props: {
    formData: Object,
    businessList: Array,
    isAdmin: Boolean,
  },

  data() {
    return {
      form: defaultValuesForForm(),
    };
  },

  validations: {
    form: {
      businessId: {
        required,
      },
      name: {
        required,
      },
      phone: {
        required,
        phone: helpers.regex('phone', phoneRegex),
      },
      address: {
        required,
      },
      timeTable: {
        required,
      },
    },
  },

  watch: {
    formData() {
      this.updateForm();
    },
  },

  computed: {
    ...mapGetters(['shopStatus']),
    isLoading() {
      return this.shopStatus === 'loading';
    },
    title() {
      return isNil(this.formData) ? this.$t('shop.newShop') : this.$t('shop.editShop');
    },
    saveButtonTitle() {
      return isNil(this.formData) ? this.$t('text.addButton') : this.$t('text.updateButton');
    },
  },

  methods: {
    updateForm() {
      this.form = this.formData
        ? {
            businessId: this.formData.businessId,
            name: this.formData.name,
            phone: this.formData.phone,
            address: this.formData.address,
            timeTable: this.formData.timeTable,
          }
        : defaultValuesForForm();
      this.$v.$reset();
    },
    resetForm() {
      this.form = defaultValuesForForm();
      this.$v.$reset();
    },
    onHidden() {
      this.$emit('onHidden');
      this.resetForm();
    },
    onCancel() {
      this.hideEditModal();
    },
    async onSave() {
      if (this.$v) {
        this.$v.form.$touch();
        if (this.$v.form.$anyError) {
          return;
        }
      }

      const data = this.form;

      if (isNil(this.formData)) {
        await this.$store.dispatch(SHOP_CREATE, data);
      } else {
        const payload = {
          id: this.formData.id,
          data,
        };
        await this.$store.dispatch(SHOP_UPDATE, payload);
      }
      if (this.shopStatus === 'success') {
        this.hideEditModal();
        this.$emit('onUpdated');
      }
    },
  },

  components: {
    ModalMain,
    InputSelect,
    InputString,
    InputEditor,
  },
};
</script>
