import Client from '@/clients/apiClient';
import { stringify } from 'query-string';

const resource = 'businesses';
export default {
  get(payload) {
    const params = Object.assign({
      page: payload.page,
      limit: payload.limit || 10,
    });
    return Client.get(`${resource}?${stringify(params)}`);
  },
  create(payload) {
    return Client.post(`${resource}`, payload);
  },
  show(id) {
    return Client.get(`${resource}/${id}`);
  },
  update(id, payload) {
    return Client.put(`${resource}/${id}`, payload);
  },
  delete(id) {
    return Client.delete(`${resource}/${id}`);
  },
};
