import { find } from 'lodash';
import {
  PRODUCT_SALE_REQUEST,
  PRODUCT_SALE_REQUEST_ERROR,
  PRODUCT_SALE_REQUEST_SUCCESS,
  PRODUCT_SALE_LIST_REQUEST,
  PRODUCT_SALE_LIST_REQUEST_ERROR,
  PRODUCT_SALE_LIST_REQUEST_SUCCESS,
  PRODUCT_SALE_CHANGE_STATUS,
  PRODUCT_SALE_CHANGE_STATUS_ERROR,
  PRODUCT_SALE_CHANGE_STATUS_SUCCESS,
} from '@/store/actions/productSale';
import { handleRequestError } from '@/utils/error';
import Repository, { PRODUCT_SALES } from '@/repositories/RepositoryFactory';

const ProductSalesRepository = Repository.get(PRODUCT_SALES);

const state = {
  status: '',
  productSale: {},
  productSaleList: [],
  productSaleListMeta: {},
  productSaleListStatus: '',
  productSaleChangeStatus: '',
};

const getters = {
  productSale: state => state.productSale,
  productSaleStatus: state => state.status,
  productSaleList: state => state.productSaleList,
  productSaleListMeta: state => state.productSaleListMeta,
  productSaleListStatus: state => state.productSaleListStatus,
  productSaleChangeStatus: state => state.productSaleChangeStatus,
  getProductSaleById: (state, getters) => id => {
    if (getters.productSaleList.length > 0) {
      return find(getters.productSaleList, { id });
    }
    return state.productSale;
  },
};

const actions = {
  [PRODUCT_SALE_REQUEST]: async ({ commit }, id) => {
    commit(PRODUCT_SALE_REQUEST);
    try {
      const response = await ProductSalesRepository.show(id);
      commit(PRODUCT_SALE_REQUEST_SUCCESS, response.data);
    } catch (error) {
      commit(PRODUCT_SALE_REQUEST_ERROR);
      handleRequestError(error);
    }
  },
  [PRODUCT_SALE_LIST_REQUEST]: async ({ commit }, payload) => {
    commit(PRODUCT_SALE_LIST_REQUEST);
    try {
      const response = await ProductSalesRepository.get(payload);
      commit(PRODUCT_SALE_LIST_REQUEST_SUCCESS, response.data);
    } catch (error) {
      commit(PRODUCT_SALE_LIST_REQUEST_ERROR);
      handleRequestError(error);
    }
  },
  [PRODUCT_SALE_CHANGE_STATUS]: async ({ commit }, payload) => {
    commit(PRODUCT_SALE_CHANGE_STATUS);
    try {
      const response = await ProductSalesRepository.changeStatus(payload.id, payload.data);
      commit(PRODUCT_SALE_CHANGE_STATUS_SUCCESS, response.data.data);
    } catch (error) {
      commit(PRODUCT_SALE_CHANGE_STATUS_ERROR);
      handleRequestError(error);
    }
  },
};

/* eslint-disable no-param-reassign */
const mutations = {
  [PRODUCT_SALE_REQUEST]: state => {
    state.status = 'loading';
  },
  [PRODUCT_SALE_REQUEST_SUCCESS]: (state, payload) => {
    state.status = 'success';
    state.productSale = payload.data;
  },
  [PRODUCT_SALE_REQUEST_ERROR]: state => {
    state.status = 'error';
  },
  [PRODUCT_SALE_LIST_REQUEST]: state => {
    state.productSaleListStatus = 'loading';
  },
  [PRODUCT_SALE_LIST_REQUEST_SUCCESS]: (state, payload) => {
    state.productSaleListStatus = 'success';
    state.productSaleList = payload.data;
    if (payload.meta) {
      state.productSaleListMeta = payload.meta;
    }
  },
  [PRODUCT_SALE_LIST_REQUEST_ERROR]: state => {
    state.productSaleListStatus = 'error';
  },
  [PRODUCT_SALE_CHANGE_STATUS]: state => {
    state.productSaleChangeStatus = 'loading';
  },
  [PRODUCT_SALE_CHANGE_STATUS_SUCCESS]: (state, payload) => {
    state.productSaleChangeStatus = 'success';
    state.productSaleList = state.productSaleList.map(item => {
      if (item.id === payload.id) {
        return { ...item, histories: payload.histories, status: payload.status };
      }
      return item;
    });
  },
  [PRODUCT_SALE_CHANGE_STATUS_ERROR]: state => {
    state.productSaleChangeStatus = 'error';
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
