import {
  BANK_LIST_REQUEST,
  BANK_LIST_REQUEST_ERROR,
  BANK_LIST_REQUEST_SUCCESS,
} from '@/store/actions/bank';
import { handleRequestError } from '@/utils/error';
import Repository, { BANKS } from '@/repositories/RepositoryFactory';

const BanksRepository = Repository.get(BANKS);

const state = {
  bankList: [],
  bankListMeta: {},
  bankListStatus: '',
};

const getters = {
  bankList: state => state.bankList,
  bankListMeta: state => state.bankListMeta,
  bankListStatus: state => state.bankListStatus,
};

const actions = {
  [BANK_LIST_REQUEST]: async ({ commit }, payload) => {
    commit(BANK_LIST_REQUEST);
    try {
      const response = await BanksRepository.get(payload);
      commit(BANK_LIST_REQUEST_SUCCESS, response.data);
    } catch (error) {
      commit(BANK_LIST_REQUEST_ERROR);
      handleRequestError(error);
    }
  },
};

/* eslint-disable no-param-reassign */
const mutations = {
  [BANK_LIST_REQUEST]: state => {
    state.bankListStatus = 'loading';
  },
  [BANK_LIST_REQUEST_SUCCESS]: (state, payload) => {
    state.bankListStatus = 'success';
    state.bankList = payload.data;
    if (payload.meta) {
      state.bankListMeta = payload.meta;
    }
  },
  [BANK_LIST_REQUEST_ERROR]: state => {
    state.bankListStatus = 'error';
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
