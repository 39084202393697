import { ifAuthenticated, ifNotAuthenticated, ifAdmin, ifManager } from '@/router/helpers';
import Login from '@/views/Login';
import Index from '@/views/Manage/index';
import i18n from '@/i18n';
import Orders from '@/views/Manage/Orders';
import Dashboard from '@/views/Manage/Dashboard';
import Staff from '@/views/Manage/Staff';
import OptionTypes from '@/views/Manage/OptionTypes';
import Categories from '@/views/Manage/Categories';
import DeliveryTypes from '@/views/Manage/DeliveryTypes';
import Products from '@/views/Manage/Products/Products';
import ProductAddEdit from '@/views/Manage/Products/ProductAddEdit';
import SalesByMonth from '@/views/Manage/Reports/SalesByMonth';
import Customers from '@/views/Manage/Customers/Customers';
import CustomerDetails from '@/views/Manage/Customers/CustomerDetails';
import Settings from '@/views/Manage/Settings';
import Businesses from '@/views/Manage/Businesses/Businesses';
import Banners from '@/views/Manage/Banners';
import Inventories from '@/views/Manage/Inventories';
import BusinessBanks from '@/views/Manage/BusinessBanks';
import Blogs from '@/views/Manage/Blogs';
import Checkout from '@/views/Manage/Checkout';
import Shops from '@/views/Manage/Shops';
import Cashiers from '@/views/Manage/Cashiers';

export default [
  {
    path: '/',
    name: 'Home',
    component: Login,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: '/manage',
    component: Index,
    meta: {
      title: 'Manage',
    },
    children: [
      {
        path: '',
        name: 'Dashboard',
        component: Dashboard,
        meta: {
          title: i18n.t('pageTitle.dashboard'),
        },
      },
      {
        path: 'orders',
        name: 'Orders',
        component: Orders,
        meta: {
          title: i18n.t('pageTitle.orders'),
        },
      },
      {
        path: 'products',
        name: 'Products',
        component: Products,
        meta: {
          title: i18n.t('pageTitle.products'),
        },
        beforeEnter: ifManager,
      },
      {
        path: 'products/add',
        name: 'ProductAdd',
        component: ProductAddEdit,
        meta: {
          title: i18n.t('pageTitle.newProduct'),
        },
        beforeEnter: ifManager,
      },
      {
        path: 'products/:productId/edit',
        name: 'ProductEdit',
        component: ProductAddEdit,
        meta: {
          title: i18n.t('pageTitle.editProduct'),
        },
        beforeEnter: ifManager,
      },
      {
        path: 'customers',
        name: 'Customers',
        component: Customers,
        meta: {
          title: i18n.t('pageTitle.customers'),
        },
        beforeEnter: ifManager,
      },
      {
        path: 'customers/:id',
        name: 'CustomerDetails',
        component: CustomerDetails,
        meta: {
          title: i18n.t('pageTitle.customers'),
        },
        beforeEnter: ifManager,
      },
      {
        path: 'staff',
        name: 'Staff',
        component: Staff,
        beforeEnter: ifManager,
      },
      {
        path: 'reports',
        name: 'SalesByMonth',
        component: SalesByMonth,
        meta: {
          title: 'Sales by month',
        },
        beforeEnter: ifManager,
      },
      {
        path: 'settings',
        name: 'Settings',
        component: Settings,
        meta: {
          title: i18n.t('pageTitle.settings'),
        },
        beforeEnter: ifManager,
      },
      {
        path: 'banners',
        name: 'Banners',
        component: Banners,
        meta: {
          title: i18n.t('pageTitle.banners'),
        },
        beforeEnter: ifManager,
      },
      {
        path: 'shops',
        name: 'Shops',
        component: Shops,
        meta: {
          title: i18n.t('pageTitle.shops'),
        },
        beforeEnter: ifManager,
      },
      {
        path: 'shops/:shopId/cashiers',
        name: 'Cashiers',
        component: Cashiers,
        meta: {
          title: i18n.t('pageTitle.cashiers'),
        },
        beforeEnter: ifManager,
      },
      {
        path: 'inventories',
        name: 'Inventories',
        component: Inventories,
        meta: {
          title: i18n.t('pageTitle.inventories'),
        },
        beforeEnter: ifManager,
      },
      {
        path: 'checkout',
        name: 'Checkout',
        component: Checkout,
        meta: {
          title: i18n.t('pageTitle.checkout'),
        },
      },
      {
        path: 'blogs',
        name: 'Blogs',
        component: Blogs,
        meta: {
          title: i18n.t('pageTitle.blogs'),
        },
        beforeEnter: ifManager,
      },
      {
        path: 'option-types',
        name: 'OptionTypes',
        component: OptionTypes,
        meta: {
          title: i18n.t('pageTitle.optionTypes'),
        },
        beforeEnter: ifAdmin,
      },
      {
        path: 'categories',
        name: 'Categories',
        component: Categories,
        meta: {
          title: i18n.t('pageTitle.categories'),
        },
        beforeEnter: ifAdmin,
      },
      {
        path: 'delivery-types',
        name: 'DeliveryTypes',
        component: DeliveryTypes,
        meta: {
          title: i18n.t('pageTitle.deliveryTypes'),
        },
        beforeEnter: ifAdmin,
      },
      {
        path: 'businesses',
        name: 'Businesses',
        component: Businesses,
        meta: {
          title: i18n.t('pageTitle.businesses'),
        },
        beforeEnter: ifAdmin,
      },
      {
        path: 'business-banks',
        name: 'BusinessBanks',
        component: BusinessBanks,
        meta: {
          title: i18n.t('pageTitle.businessBanks'),
        },
        beforeEnter: ifAdmin,
      },
    ],
    beforeEnter: ifAuthenticated,
  },
];
