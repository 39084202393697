import { find, findIndex } from 'lodash';
import {
  INVENTORY_LIST_REQUEST,
  INVENTORY_LIST_REQUEST_ERROR,
  INVENTORY_LIST_REQUEST_SUCCESS,
  INVENTORY_CREATE,
  INVENTORY_CREATE_SUCCESS,
  INVENTORY_CREATE_ERROR,
  INVENTORY_UPDATE,
  INVENTORY_UPDATE_SUCCESS,
  INVENTORY_UPDATE_ERROR,
  INVENTORY_DELETE,
  INVENTORY_DELETE_SUCCESS,
  INVENTORY_DELETE_ERROR,
} from '@/store/actions/inventory';
import { handleRequestError } from '@/utils/error';
import Repository, { INVENTORIES } from '@/repositories/RepositoryFactory';

const InventoriesRepository = Repository.get(INVENTORIES);

const state = {
  status: '',
  inventory: {},
  inventoryList: [],
  inventoryListMeta: {},
  inventoryListStatus: '',
};

const getters = {
  inventory: state => state.inventory,
  inventoryStatus: state => state.status,
  inventoryList: state => state.inventoryList,
  inventoryListMeta: state => state.inventoryListMeta,
  inventoryListStatus: state => state.inventoryListStatus,
  getInventoryById: (state, getters) => id => {
    if (getters.inventoryList.length > 0) {
      return find(getters.inventoryList, { id });
    }
    return state.inventory;
  },
};

const actions = {
  [INVENTORY_LIST_REQUEST]: async ({ commit }, payload) => {
    commit(INVENTORY_LIST_REQUEST);
    try {
      const response = await InventoriesRepository.get(payload);
      commit(INVENTORY_LIST_REQUEST_SUCCESS, response.data);
    } catch (error) {
      commit(INVENTORY_LIST_REQUEST_ERROR);
      handleRequestError(error);
    }
  },
  [INVENTORY_CREATE]: async ({ commit }, payload) => {
    commit(INVENTORY_CREATE);
    try {
      const response = await InventoriesRepository.create(payload);
      commit(INVENTORY_CREATE_SUCCESS, response.data.data);
    } catch (error) {
      commit(INVENTORY_CREATE_ERROR);
      handleRequestError(error);
    }
  },
  [INVENTORY_UPDATE]: async ({ commit }, { id, data }) => {
    commit(INVENTORY_UPDATE);
    try {
      const response = await InventoriesRepository.update(id, data);
      commit(INVENTORY_UPDATE_SUCCESS, response.data.data);
    } catch (error) {
      commit(INVENTORY_UPDATE_ERROR);
      handleRequestError(error);
    }
  },
  [INVENTORY_DELETE]: async ({ commit }, id) => {
    commit(INVENTORY_DELETE);
    try {
      await InventoriesRepository.delete(id);
      commit(INVENTORY_DELETE_SUCCESS, id);
    } catch (error) {
      commit(INVENTORY_DELETE_ERROR);
      handleRequestError(error);
    }
  },
};

/* eslint-disable no-param-reassign */
const mutations = {
  [INVENTORY_LIST_REQUEST]: state => {
    state.inventoryListStatus = 'loading';
  },
  [INVENTORY_LIST_REQUEST_SUCCESS]: (state, payload) => {
    state.inventoryListStatus = 'success';
    state.inventoryList = payload.data;
    if (payload.meta) {
      state.inventoryListMeta = payload.meta;
    }
  },
  [INVENTORY_LIST_REQUEST_ERROR]: state => {
    state.inventoryListStatus = 'error';
  },
  [INVENTORY_CREATE]: state => {
    state.status = 'loading';
  },
  [INVENTORY_CREATE_SUCCESS]: (state, payload) => {
    state.status = 'success';
    state.inventoryList.push(payload);
  },
  [INVENTORY_CREATE_ERROR]: state => {
    state.status = 'error';
  },
  [INVENTORY_UPDATE]: state => {
    state.status = 'loading';
  },
  [INVENTORY_UPDATE_SUCCESS]: (state, payload) => {
    state.status = 'success';
    state.inventoryList = state.inventoryList.map(item => {
      if (item.id === payload.id) {
        return payload;
      }
      return item;
    });
  },
  [INVENTORY_UPDATE_ERROR]: state => {
    state.status = 'error';
  },
  [INVENTORY_DELETE]: state => {
    state.status = 'deleting';
  },
  [INVENTORY_DELETE_SUCCESS]: (state, payload) => {
    state.status = 'deleted';
    const index = findIndex(state.inventoryList, { id: payload });
    state.inventoryList.splice(index, 1);
  },
  [INVENTORY_DELETE_ERROR]: state => {
    state.status = 'error';
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
