<template>
  <ModalMain
    :id="modalId"
    :title="title"
    :ok-title="saveButtonTitle"
    :loading="isLoading"
    :delete-disabled="!formData || deleteDisabled"
    @hidden="onHidden"
    @shown="updateForm"
    @ok="onSave"
    @cancel="onCancel"
    @remove="$emit('onRemove', formData.id)"
  >
    <b-form @submit.prevent="onSave">
      <template v-if="me.isAdmin">
        <InputString
          class="name"
          :label="$t('label.name')"
          size="lg"
          v-model="$v.form.name.$model"
          :vuelidate="$v.form.name"
        />
        <InputString
          class="slug"
          :label="$t('label.slug')"
          size="lg"
          v-model="$v.form.slug.$model"
          :vuelidate="$v.form.slug"
        />
      </template>
      <template v-else>
        <InputString class="name" :label="$t('label.name')" size="lg" disabled :value="form.name" />
        <InputString class="slug" :label="$t('label.slug')" size="lg" disabled :value="form.slug" />
      </template>

      <b-row class="mb-3">
        <b-col cols="6" sm="6">
          <InputLabel>{{ $t('label.logo') }}</InputLabel>
          <InputPhoto
            :is-loading="isUploadingPhoto"
            :photo="form.logo"
            :vuelidate="$v.form.logo"
            @change="onChangeLogo"
            @remove="onDeleteLogo"
          />
        </b-col>
        <b-col cols="6" sm="6">
          <InputLabel>{{ $t('label.photo') }}</InputLabel>
          <InputPhoto
            :is-loading="isUploadingPhoto"
            :photo="form.image"
            :vuelidate="$v.form.image"
            @change="onChangePhoto"
            @remove="onDeletePhoto"
          />
        </b-col>
      </b-row>

      <InputEditor
        class="description"
        :label="$t('label.description')"
        size="lg"
        v-model="form.description"
      />
      <InputString
        class="phone"
        :label="$t('label.phone')"
        size="lg"
        v-model="$v.form.phone.$model"
        :vuelidate="$v.form.phone"
      />
      <InputString
        class="email"
        :label="$t('label.email')"
        size="lg"
        v-model="$v.form.email.$model"
        :vuelidate="$v.form.email"
      />
      <InputEditor
        :label="$t('label.address')"
        size="lg"
        v-model="$v.form.address.$model"
        :vuelidate="$v.form.address"
      />
      <InputString class="facebook" label="Facebook" size="lg" v-model="form.facebook" />
      <InputString class="messenger" label="Messenger" size="lg" v-model="form.messenger" />
      <InputString class="youtube" label="Youtube" size="lg" v-model="form.youtube" />
      <InputString class="instagram" label="Instagram" size="lg" v-model="form.instagram" />
      <!-- <InputEditor
        class="privacy-policy"
        :label="$t('label.privacyPolicy')"
        size="lg"
        is-large
        v-model="form.privacyPolicy"
      />
      <InputEditor
        class="terms-conditions"
        :label="$t('label.termsConditions')"
        size="lg"
        is-large
        v-model="form.termsConditions"
      /> -->

      <InputRadioButton
        v-if="me.isAdmin"
        class="status"
        :label="$t('label.status')"
        size="lg"
        v-model="form.status"
        :options="statusOptions"
      />
    </b-form>
  </ModalMain>
</template>

<script>
import { isNil } from 'lodash';
import { mapGetters } from 'vuex';
import meMixin from '@/mixins/meMixin';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import { BUSINESS_CREATE, BUSINESS_UPDATE } from '@/store/actions/business';
import { FILE_UPLOAD } from '@/store/actions/file';
import { STATUS_OPTIONS } from '@/utils/constants';
import editModalMixin from '@/mixins/editModalMixin';
import ModalMain from '@/components/ui/ModalMain';
import InputString from '@/components/ui/input/InputString';
import InputEditor from '@/components/ui/input/InputEditor';
import InputRadioButton from '@/components/ui/input/InputRadioButton';
import InputPhoto from '@/components/ui/input/InputPhoto';
import InputLabel from '@/components/ui/input/InputLabel';

const defaultValuesForForm = () => ({
  name: '',
  slug: '',
  logo: null,
  image: null,
  description: '',
  phone: '',
  email: '',
  facebook: '',
  messenger: '',
  youtube: '',
  instagram: '',
  address: '',
  privacyPolicy: '',
  termsConditions: '',
  status: true,
});

export default {
  mixins: [meMixin, validationMixin, editModalMixin],

  props: {
    formData: Object,
    deleteDisabled: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      form: defaultValuesForForm(),
      statusOptions: STATUS_OPTIONS,
    };
  },

  validations: {
    form: {
      name: {
        required,
      },
      slug: {
        required,
      },
      logo: {
        required,
      },
      image: {
        required,
      },
      phone: {
        required,
      },
      email: {
        required,
      },
      address: {
        required,
      },
    },
  },

  watch: {
    formData() {
      this.updateForm();
    },
  },

  computed: {
    ...mapGetters(['businessStatus', 'getFileUpload']),
    isLoading() {
      return this.businessStatus === 'loading';
    },
    title() {
      return isNil(this.formData)
        ? this.$t('category.newCategory')
        : this.$t('category.editCategory');
    },
    saveButtonTitle() {
      return isNil(this.formData) ? this.$t('text.addButton') : this.$t('text.updateButton');
    },
    isUploadingPhoto() {
      return this.getFileUpload.status === 'loading';
    },
  },

  methods: {
    updateForm() {
      this.form = this.formData
        ? {
            name: this.formData.name,
            slug: this.formData.slug,
            logo: this.formData.logo,
            image: this.formData.image,
            description: this.formData.description,
            phone: this.formData.phone,
            email: this.formData.email,
            facebook: this.formData.facebook,
            messenger: this.formData.messenger,
            youtube: this.formData.youtube,
            instagram: this.formData.instagram,
            address: this.formData.address,
            privacyPolicy: this.formData.privacyPolicy,
            termsConditions: this.formData.termsConditions,
            status: this.formData.status,
          }
        : defaultValuesForForm();
      this.$v.$reset();
    },
    resetForm() {
      this.form = defaultValuesForForm();
      this.$v.$reset();
    },
    onHidden() {
      this.$emit('onHidden');
      this.resetForm();
    },
    onCancel() {
      this.hideEditModal();
    },
    async onSave() {
      if (this.$v) {
        this.$v.form.$touch();
        if (this.$v.form.$anyError) {
          return;
        }
      }
      const data = this.form;

      if (isNil(this.formData)) {
        await this.$store.dispatch(BUSINESS_CREATE, data);
      } else {
        const payload = {
          id: this.formData.id,
          data,
        };
        await this.$store.dispatch(BUSINESS_UPDATE, payload);
      }
      if (this.businessStatus === 'success') {
        this.hideEditModal();
      }
    },
    async onChangePhoto(e) {
      const file = e.target.files[0];
      await this.$store.dispatch(FILE_UPLOAD, file);
      if (this.getFileUpload.status === 'success') {
        this.form.image = this.getFileUpload.item ? this.getFileUpload.item : null;
      }
    },
    onDeletePhoto() {
      this.form.image = null;
    },
    async onChangeLogo(e) {
      const file = e.target.files[0];
      await this.$store.dispatch(FILE_UPLOAD, file);
      if (this.getFileUpload.status === 'success') {
        this.form.logo = this.getFileUpload.item ? this.getFileUpload.item : null;
      }
    },
    onDeleteLogo() {
      this.form.logo = null;
    },
  },

  components: {
    ModalMain,
    InputString,
    InputEditor,
    InputRadioButton,
    InputPhoto,
    InputLabel,
  },
};
</script>
