import UsersRepository from '@/repositories/UsersRepository';
import ProductCategoriesRepository from '@/repositories/ProductCategoriesRepository';
import StaffRepository from '@/repositories/StaffRepository';
import AddonCategoriesRepository from '@/repositories/AddonCategoriesRepository';
import AddonsRepository from '@/repositories/AddonsRepository';
import ProductsRepository from '@/repositories/ProductsRepository';
import AboutAppRepository from '@/repositories/AboutAppRepository';
import ProductSalesRepository from '@/repositories/ProductSalesRepository';
import ReportsRepository from '@/repositories/ReportsRepository';
import CustomersRepository from '@/repositories/CustomersRepository';
import PickUpDurationsRepository from '@/repositories/PickUpDurationsRepository';
import OptionTypesRepository from '@/repositories/OptionTypesRepository';
import BusinessesRepository from '@/repositories/BusinessesRepository';
import BannersRepository from '@/repositories/BannersRepository';
import BanksRepository from '@/repositories/BanksRepository';
import BusinessBanksRepository from '@/repositories/BusinessBanksRepository';
import DashboardRepository from '@/repositories/DashboardRepository';
import BlogsRepository from '@/repositories/BlogsRepository';
import DeliveryTypesRepository from '@/repositories/DeliveryTypesRepository';
import InventoriesRepository from '@/repositories/InventoriesRepository';
import OrdersRepository from '@/repositories/OrdersRepository';
import ShopsRepository from '@/repositories/ShopsRepository';

export const USERS = 'users';
export const PRODUCT_CATEGORIES = 'productCategories';
export const STAFF = 'staff';
export const ADDON_CATEGORIES = 'addonCategories';
export const ADDONS = 'addons';
export const PRODUCTS = 'products';
export const ABOUT_APP = 'aboutApp';
export const PRODUCT_SALES = 'productSales';
export const REPORTS = 'reports';
export const CUSTOMERS = 'customers';
export const PICK_UP_DURATIONS = 'pickUpDurations';
export const OPTION_TYPES = 'optionTypes';
export const BUSINESSES = 'businesses';
export const BANNERS = 'banners';
export const BANKS = 'banks';
export const BUSINESS_BANKS = 'businessBanks';
export const DASHBOARD = 'dashboard';
export const BLOGS = 'blogs';
export const DELIVERY_TYPES = 'deliveryTypes';
export const INVENTORIES = 'inventories';
export const ORDERS = 'orders';
export const SHOPS = 'shops';

const repositories = {
  [PRODUCT_CATEGORIES]: ProductCategoriesRepository,
  [USERS]: UsersRepository,
  [STAFF]: StaffRepository,
  [ADDON_CATEGORIES]: AddonCategoriesRepository,
  [ADDONS]: AddonsRepository,
  [PRODUCTS]: ProductsRepository,
  [ABOUT_APP]: AboutAppRepository,
  [PRODUCT_SALES]: ProductSalesRepository,
  [REPORTS]: ReportsRepository,
  [CUSTOMERS]: CustomersRepository,
  [PICK_UP_DURATIONS]: PickUpDurationsRepository,
  [OPTION_TYPES]: OptionTypesRepository,
  [BUSINESSES]: BusinessesRepository,
  [BANNERS]: BannersRepository,
  [BANKS]: BanksRepository,
  [BUSINESS_BANKS]: BusinessBanksRepository,
  [DASHBOARD]: DashboardRepository,
  [BLOGS]: BlogsRepository,
  [DELIVERY_TYPES]: DeliveryTypesRepository,
  [INVENTORIES]: InventoriesRepository,
  [ORDERS]: OrdersRepository,
  [SHOPS]: ShopsRepository,
};

export default {
  get: name => repositories[name],
};
