<template>
  <BaseLayout :title="title">
    <template #actions>
      <InlineButton @click="onCreate" pill shadow>
        <span class="text-uppercase">{{ $t('text.newButton') }}</span>
      </InlineButton>
    </template>

    <CashierTable :list="cashierList" :is-loading="isLoading" @delete="onConfirmDelete" />

    <CashierAddEditModal :modal-id="editModalId" :shop-id="shopId" @onUpdated="fetchData" />

    <ConfirmDialog :id="deleteModalId" @ok="remove" />
  </BaseLayout>
</template>

<script>
import { mapGetters } from 'vuex';
import { SHOP_SHOW, DELETE_CASHIER } from '@/store/actions/shop';
import meMixin from '@/mixins/meMixin';
import BaseLayout from '@/components/ui/BaseLayout';
import InlineButton from '@/components/ui/button/InlineButton';
import CashierTable from '@/components/manage/cashiers/CashierTable';
import CashierAddEditModal from '@/components/manage/cashiers/CashierAddEditModal';
import ConfirmDialog from '@/components/ui/ConfirmDialog';

export default {
  mixins: [meMixin],

  props: {
    title: String,
  },

  data() {
    return {
      shopId: parseInt(this.$route.params.shopId, 10),
      dataIdToRemove: null,
      editModalId: 'manage-form-modal',
      deleteModalId: 'manage-delete-modal',
    };
  },

  computed: {
    ...mapGetters(['shop', 'shopStatus', 'cashierStatus']),
    isLoading() {
      return this.shopStatus === 'loading';
    },
    cashierList() {
      return this.shop?.cashiers || [];
    },
  },

  created() {
    this.fetchData();
  },

  methods: {
    async fetchData() {
      await this.$store.dispatch(SHOP_SHOW, this.shopId);
    },
    onCreate() {
      this.openModal();
    },
    openModal() {
      this.$bvModal.show(this.editModalId);
    },
    onConfirmDelete(id) {
      this.dataIdToRemove = id;
      this.$bvModal.show(this.deleteModalId);
    },
    async remove() {
      const payload = {
        id: this.shopId,
        data: {
          userId: this.dataIdToRemove,
        },
      };
      await this.$store.dispatch(DELETE_CASHIER, payload);
      if (this.cashierStatus === 'deleted') {
        this.dataIdToRemove = null;
        this.fetchData();
      }
    },
  },

  components: {
    BaseLayout,
    InlineButton,
    CashierTable,
    CashierAddEditModal,
    ConfirmDialog,
  },
};
</script>
