<template>
  <TableBox :current-page="currentPage" :meta="meta" @onChangePage="$emit('onChangePage', $event)">
    <b-table
      sticky-header
      id="my-table"
      :fields="fields"
      :items="staffList"
      :busy="isLoading"
      class="custom-table"
      thead-class="custom-table-header"
      tbody-tr-class="custom-table-body"
      :empty-text="$t('staff.emptyText')"
      show-empty
    >
      <template #cell(status)="data">
        <Status :value="data.item.status" />
      </template>

      <template #cell(type)="data">
        <span class="text-nowrap">
          {{ getType(data.item.type) }}
        </span>
      </template>

      <template #cell(action)="data">
        <div class="table-action-buttons">
          <InlineButton
            variant="outline-secondary"
            size="sm"
            pill
            @click="$emit('edit', data.item.id)"
            class="has-shadow"
          >
            {{ $t('text.edit') }}
          </InlineButton>
        </div>
      </template>
    </b-table>
  </TableBox>
</template>
<script>
import TableBox from '@/components/ui/TableBox';
import Status from '@/components/ui/Status';
import InlineButton from '@/components/ui/button/InlineButton';

export default {
  props: {
    staffList: Array,
    meta: Object,
    currentPage: Number,
    isLoading: Boolean,
    typeList: Array,
  },

  data() {
    return {
      fields: [
        {
          key: 'name',
          label: this.$t('table.th.staffName'),
        },
        {
          key: 'email',
          label: this.$t('table.th.email'),
        },
        {
          key: 'type',
          label: this.$t('table.th.type'),
        },
        {
          key: 'status',
          label: this.$t('table.th.status'),
        },
        {
          key: 'action',
          label: this.$t('table.th.settings'),
        },
      ],
    };
  },

  methods: {
    getType(value) {
      return this.typeList.find(type => type.value === value).name;
    },
  },

  components: {
    TableBox,
    Status,
    InlineButton,
  },
};
</script>
