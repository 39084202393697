<template>
  <TableBox>
    <b-table
      sticky-header
      id="my-table"
      :fields="fields"
      :items="list"
      :busy="isLoading"
      class="custom-table"
      thead-class="custom-table-header"
      tbody-tr-class="custom-table-body"
      :emptyText="$t('text.emptyText')"
      show-empty
    >
      <template #cell(index)="data">
        {{ data.index + 1 }}
      </template>

      <template #cell(name)="data">
        <p class="mb-2">
          {{ data.item.name }}
        </p>
        <p v-if="isAdmin" class="text-black-50">
          {{ data.item.business ? data.item.business.name : '' }}
        </p>
      </template>

      <template #cell(action)="data">
        <div class="table-action-buttons">
          <InlineButton
            variant="outline-secondary"
            size="sm"
            pill
            dashed
            :to="{ name: 'Cashiers', params: { shopId: data.item.id } }"
          >
            {{ $t('text.seeCashiers') }}
          </InlineButton>
          <InlineButton
            variant="outline-secondary"
            size="sm"
            pill
            @click="$emit('edit', data.item.id)"
            class="has-shadow"
          >
            {{ $t('text.editButton') }}
          </InlineButton>
        </div>
      </template>
    </b-table>
  </TableBox>
</template>

<script>
import TableBox from '@/components/ui/TableBox';
import InlineButton from '@/components/ui/button/InlineButton';

export default {
  props: {
    list: Array,
    isLoading: Boolean,
    businessList: Array,
    isAdmin: Boolean,
  },

  data() {
    return {
      fields: [
        {
          key: 'index',
          label: '#',
        },
        {
          key: 'name',
          label: this.$t('table.th.name'),
        },
        {
          key: 'phone',
          label: this.$t('table.th.phone'),
        },
        {
          key: 'action',
          label: this.$t('table.th.settings'),
        },
      ],
    };
  },

  components: {
    TableBox,
    InlineButton,
  },
};
</script>
