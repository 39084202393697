import Client from '@/clients/apiClient';

const resource = 'about';

export default {
  get() {
    return Client.get(`${resource}`);
  },
  update(id, data) {
    return Client.put(`${resource}/${id}`, data);
  },
};
