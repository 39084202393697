<template>
  <BaseLayout :title="title">
    <template #actions>
      <InlineButton @click="onCreate" pill shadow>
        <span class="text-uppercase">{{ $t('text.newButton') }}</span>
      </InlineButton>
    </template>

    <OptionTypeTable
      :list="optionTypeList"
      :is-loading="isLoading"
      :type-options="typeOptions"
      @edit="onEdit"
    />

    <OptionTypeAddEditModal
      :form-data="dataToEdit"
      :modal-id="editModalId"
      :type-options="typeOptions"
      @onHidden="emptyingDataToEdit"
      @onRemove="onConfirmDelete"
    />

    <ConfirmDialog :id="deleteModalId" @ok="remove" />
  </BaseLayout>
</template>

<script>
import { mapGetters } from 'vuex';
import { OPTION_TYPE_LIST_REQUEST, OPTION_TYPE_DELETE } from '@/store/actions/optionType';
import BaseLayout from '@/components/ui/BaseLayout';
import InlineButton from '@/components/ui/button/InlineButton';
import OptionTypeTable from '@/components/manage/optionTypes/OptionTypeTable';
import OptionTypeAddEditModal from '@/components/manage/optionTypes/OptionTypeAddEditModal';
import ConfirmDialog from '@/components/ui/ConfirmDialog';

export default {
  props: {
    title: String,
  },

  data() {
    return {
      dataIdToEdit: null,
      dataIdToRemove: null,
      editModalId: 'manage-form-modal',
      deleteModalId: 'manage-delete-modal',
      typeOptions: [
        { name: this.$t('optionType.single'), value: 'SINGLE' },
        { name: this.$t('optionType.multiple'), value: 'MULTIPLE' },
      ],
    };
  },

  computed: {
    ...mapGetters([
      'optionTypeStatus',
      'optionTypeList',
      'optionTypeListStatus',
      'getOptionTypeById',
    ]),
    dataToEdit() {
      return this.dataIdToEdit ? this.getOptionTypeById(this.dataIdToEdit) : null;
    },
    isLoading() {
      return this.optionTypeListStatus === 'loading';
    },
  },

  created() {
    this.fetchData();
  },

  methods: {
    async fetchData() {
      await this.$store.dispatch(OPTION_TYPE_LIST_REQUEST);
    },
    emptyingDataToEdit() {
      this.dataIdToEdit = null;
    },
    onCreate() {
      this.emptyingDataToEdit();
      this.openModal();
    },
    onEdit(id) {
      this.dataIdToEdit = id;
      this.openModal();
    },
    openModal() {
      this.$bvModal.show(this.editModalId);
    },
    onConfirmDelete(id) {
      this.dataIdToRemove = id;
      this.$bvModal.show(this.deleteModalId);
    },
    async remove() {
      await this.$store.dispatch(OPTION_TYPE_DELETE, this.dataIdToRemove);
      if (this.optionTypeStatus === 'deleted') {
        this.emptyingDataToEdit();
        this.$bvModal.hide(this.editModalId);
        this.dataIdToRemove = null;
      }
    },
  },

  components: {
    BaseLayout,
    InlineButton,
    OptionTypeTable,
    OptionTypeAddEditModal,
    ConfirmDialog,
  },
};
</script>
