<template>
  <ModalMain
    :id="modalId"
    :title="title"
    :cancelTitle="$t('text.backButton')"
    ok-hide
    delete-disabled
    :additional-button="$t('text.changeStatus')"
    paddingless
    @hidden="$emit('onHidden')"
    @cancel="onCancel"
    @onClickAdditionalButton="onClickChangeStatus"
  >
    <template #header-content>
      <div class="modal-main-header-after">
        <template v-if="order.user">
          <div class="mb-1">
            <span>{{ $t('text.user') }}:</span>
            <span>
              {{ order.user | fullName }}
            </span>
          </div>
          <div v-if="order.user.phone" class="mb-1">
            <span>{{ $t('text.phone') }}:</span>
            <span>
              {{ order.user.phone }}
            </span>
          </div>
          <div v-if="order.user.email" class="mb-1">
            <span>{{ $t('text.email') }}:</span>
            <span>
              {{ order.user.email }}
            </span>
          </div>
          <template v-if="order.address">
            <hr v-if="order.address.phone || order.address.phone" />
            <div v-if="order.address.phone" class="mb-1">
              <span>{{ $t('text.phone') }}:</span>
              <span>
                {{ order.address.phone }}
              </span>
            </div>
            <p v-if="order.address.address" style="font-size: 14px">
              {{ order.address.address }}
            </p>
          </template>
          <hr />
        </template>
        <div class="mb-1">
          <span>{{ $t('text.date') }}:</span>
          <span>
            {{
              order.createdAt ? $options.filters.moment(order.createdAt, 'YYYY-MM-DD hh:mm') : ''
            }}
          </span>
        </div>
        <div class="mb-1">
          <span>{{ $t('text.status') }}:</span>
          <Status :value="order.status" />
        </div>
      </div>
    </template>

    <template v-if="order && order.histories">
      <b-table :fields="fieldsForHistories" :items="order.histories" class="order-details-table">
        <template #cell(status)="data">
          <Status :value="data.item.status" />
        </template>
        <template #cell(createdAt)="data">
          <span class="text-nowrap">
            {{
              data.item.createdAt
                ? $options.filters.moment(data.item.createdAt, 'YYYY-MM-DD hh:mm')
                : ''
            }}
          </span>
          <p v-if="data.item.note" class="mt-1 text-left">{{ data.item.note }}</p>
        </template>
      </b-table>
    </template>

    <template v-if="order && order.items">
      <b-table :fields="fields" :items="order.items" class="order-details-table">
        <template #cell(name)="data">
          <p class="h6 mb-2">
            {{ data.item.product && data.item.product.name }}
          </p>
          <p v-if="data.item.variant" class="text-black-50">{{ data.item.variant.sku }}</p>
        </template>
        <template #cell(quantity)="data">
          <p class="h6 text-center">
            {{ parseInt(data.item.quantity, 10) }}
          </p>
        </template>
        <template #cell(price)="data">
          <p class="h6">
            {{
              data.item.pricePerUnit ? $options.filters.currency(data.item.pricePerUnit.amount) : ''
            }}
          </p>
        </template>
      </b-table>
      <div class="order-details-footer">
        <!-- <p v-html="$t('order.totalSale', { total: totalSalePrice })"></p>
        <p v-html="$t('order.totalTax', { total: totalTax })"></p> -->
        <div class="item">
          <div>{{ $t('order.totalAmount') }}:</div>
          <div>{{ totalAmount }}</div>
        </div>
        <template v-for="(type, index) in paymentTypes">
          <div class="item" v-if="isNegativePaymentType(type) && getPayment(type) > 0" :key="index">
            <div>{{ $t(`paymentTypes.${$options.filters.snakeCaseToCamelCase(type)}`) }}:</div>
            <div>-{{ getPayment(type) | currency }}</div>
          </div>
        </template>
        <div class="item" v-if="totalDue > 0">
          <div>
            <strong>{{ $t('order.totalDue') }}:</strong>
          </div>
          <div>
            <strong>{{ totalDue | currency }}</strong>
          </div>
        </div>
        <template v-for="(type, index) in paymentTypes">
          <div class="item" v-if="!isNegativePaymentType(type) && getPayment(type)" :key="index">
            <div>
              {{
                type && type.length > 0
                  ? $t(`paymentTypes.${$options.filters.snakeCaseToCamelCase(type)}`)
                  : ''
              }}:
            </div>
            <div v-if="type === 'CASH' && receipt.change">
              {{
                $options.filters.currency(getPayment(type) + parseInt(receipt.change.amount, 10))
              }}
            </div>
            <div v-else>
              {{ getPayment(type) | currency }}
            </div>
          </div>
        </template>
      </div>
    </template>

    <OrderChangeStatusModal
      :id="changeStatusModalId"
      :current-status="order ? order.status : null"
      @update="onChangeStatus"
    />
  </ModalMain>
</template>

<script>
import { isNil } from 'lodash';
import { mapGetters } from 'vuex';
import editModalMixin from '@/mixins/editModalMixin';
import { PRODUCT_SALE_CHANGE_STATUS } from '@/store/actions/productSale';
import ModalMain from '@/components/ui/ModalMain';
import Status from '@/components/ui/Status';
import OrderChangeStatusModal from '@/components/manage/orders/OrderChangeStatusModal';

export default {
  mixins: [editModalMixin],

  props: {
    order: Object,
  },

  data() {
    return {
      changeStatusModalId: 'change-order-status',
      fields: [
        {
          key: 'name',
          label: this.$t('table.th.name'),
        },
        {
          key: 'quantity',
          label: this.$t('table.th.quantity'),
        },
        {
          key: 'price',
          label: this.$t('table.th.price'),
          tdClass: 'text-right',
        },
      ],
      fieldsForHistories: [
        {
          key: 'createdAt',
          label: this.$t('table.th.date'),
        },
        {
          key: 'status',
          label: this.$t('table.th.status'),
          tdClass: 'text-right',
        },
      ],
      paymentTypes: ['CASH', 'CARD', 'DISCOUNT', 'BANK_TRANSFER', 'QPAY', 'OTHER'],
    };
  },

  computed: {
    ...mapGetters(['categoryStatus', 'productSaleChangeStatus']),
    title() {
      return isNil(this.order) ? 'Order' : `#${this.order.receiptNumber}`;
    },
    totalSalePrice() {
      return this.order.saleAmount
        ? this.$options.filters.currency(this.order.saleAmount.amount)
        : 0;
    },
    totalTax() {
      return this.order.taxAmount ? this.$options.filters.currency(this.order.taxAmount.amount) : 0;
    },
    totalAmount() {
      return this.order.totalAmount
        ? this.$options.filters.currency(this.order.totalAmount.amount)
        : 0;
    },
    totalDue() {
      let total = 0;
      if (this.order?.payments) {
        const indexOfCash = this.order.payments.findIndex(item => item.paymentType === 'CASH');
        if (indexOfCash > -1) {
          total += parseInt(this.order.payments[indexOfCash].paidAmount.amount, 10);
        }

        const indexOfCard = this.order.payments.findIndex(item => item.paymentType === 'CARD');
        if (indexOfCard > -1) {
          total += parseInt(this.order.payments[indexOfCard].paidAmount.amount, 10);
        }

        const indexOfOther = this.order.payments.findIndex(item => item.paymentType === 'OTHER');
        if (indexOfOther > -1) {
          total += parseInt(this.order.payments[indexOfOther].paidAmount.amount, 10);
        }

        const indexOfQpay = this.order.payments.findIndex(item => item.paymentType === 'QPAY');
        if (indexOfQpay > -1) {
          total += parseInt(this.order.payments[indexOfQpay].paidAmount.amount, 10);
        }

        const indexOfBankTransfer = this.order.payments.findIndex(
          item => item.paymentType === 'BANK_TRANSFER'
        );
        if (indexOfBankTransfer > -1) {
          total += parseInt(this.order.payments[indexOfBankTransfer].paidAmount.amount, 10);
        }
      }
      return total;
    },
  },

  methods: {
    getPayment(type) {
      if (this.order?.payments) {
        const payment = this.order.payments.find(item => item.paymentType === type);
        if (payment) {
          return parseInt(payment.paidAmount.amount, 10);
        }
      }
      return 0;
    },
    isNegativePaymentType(type) {
      return !!(
        this.getPayment(type) > 0 &&
        type !== 'CASH' &&
        type !== 'CARD' &&
        type !== 'OTHER' &&
        type !== 'QPAY' &&
        type !== 'BANK_TRANSFER'
      );
    },
    onCancel() {
      this.hideEditModal();
    },
    onClickChangeStatus() {
      this.$bvModal.show(this.changeStatusModalId);
    },
    async onChangeStatus(form) {
      const payload = {
        id: this.order.id,
        data: form,
      };
      await this.$store.dispatch(PRODUCT_SALE_CHANGE_STATUS, payload);
      if (this.productSaleChangeStatus === 'success') {
        this.$bvModal.hide(this.changeStatusModalId);
        this.$bvToast.toast(this.$t('toast.changedOrderStatus'), {
          title: this.$t('toast.success'),
          variant: 'success',
          solid: true,
        });
      }
    },
  },

  components: {
    ModalMain,
    Status,
    OrderChangeStatusModal,
  },
};
</script>

<style lang="scss" scoped>
@import '../../../sass/variables';
@import '../../../sass/flexBox';

.modal-main-header-after {
  padding: 1.5rem;
  margin: 1.5rem;
  margin-top: 0;
  width: calc(100% - 3rem);
  background-color: $white;
  border-radius: $border-radius-lg;

  div {
    @include flexBox(flex, center, space-between);
  }

  span {
    color: $gray-500;

    & + span {
      margin-left: 1rem;
      color: $black;
      text-align: right;
    }
  }
}

.order-details-footer {
  border-top: 2px solid $gray-200;
  padding: 1.5rem;
  text-align: right;

  p {
    margin-bottom: 0;
    font-size: 1rem;
    color: $gray-600;

    & + p {
      margin-top: 0.5rem;
    }
  }

  .item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: $gray-600;

    & + .item {
      border-top: 1px dashed $gray-200;
      padding-top: 0.5rem;
      margin-top: 0.5rem;
    }

    strong {
      color: $black;
    }
  }
}

::v-deep {
  &.order-details-table {
    margin-bottom: 0;

    thead {
      tr > th {
        &:last-child {
          text-align: right;
        }
      }
    }

    td,
    th {
      &:nth-child(2) {
        text-align: center;
      }
      &:first-child {
        padding-left: 1.5rem;
      }
    }
  }

  &.order-details-footer {
    p {
      strong {
        color: $black;
      }
    }
  }
}
</style>
