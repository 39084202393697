<template>
  <BaseLayout :title="title">
    <template #actions>
      <InlineButton v-if="me.isAdmin" @click="onCreate" pill shadow>
        <span class="text-uppercase">{{ $t('text.newButton') }}</span>
      </InlineButton>
    </template>

    <ShopTable
      :list="shopList"
      :is-loading="isLoading"
      :business-list="businessList"
      :is-admin="me.isAdmin"
      @edit="onEdit"
    />

    <ShopAddEditModal
      :form-data="dataToEdit"
      :modal-id="editModalId"
      :business-list="businessList"
      :is-admin="me.isAdmin"
      @onHidden="emptyingDataToEdit"
      @onRemove="onConfirmDelete"
      @onUpdated="fetchData"
    />

    <ConfirmDialog :id="deleteModalId" @ok="remove" />
  </BaseLayout>
</template>

<script>
import { mapGetters } from 'vuex';
import { SHOP_LIST_REQUEST, SHOP_DELETE } from '@/store/actions/shop';
import { BUSINESS_LIST_REQUEST } from '@/store/actions/business';
import meMixin from '@/mixins/meMixin';
import BaseLayout from '@/components/ui/BaseLayout';
import InlineButton from '@/components/ui/button/InlineButton';
import ShopTable from '@/components/manage/shops/ShopTable';
import ShopAddEditModal from '@/components/manage/shops/ShopAddEditModal';
import ConfirmDialog from '@/components/ui/ConfirmDialog';

export default {
  mixins: [meMixin],

  props: {
    title: String,
  },

  data() {
    return {
      dataIdToEdit: null,
      dataIdToRemove: null,
      editModalId: 'manage-form-modal',
      deleteModalId: 'manage-delete-modal',
    };
  },

  computed: {
    ...mapGetters(['getShopById', 'shopList', 'shopListStatus', 'shopStatus', 'businessList']),
    dataToEdit() {
      return this.dataIdToEdit ? this.getShopById(this.dataIdToEdit) : null;
    },
    isLoading() {
      return this.shopListStatus === 'loading';
    },
  },

  created() {
    if (this.me.isAdmin) {
      const payloadToBusiness = {
        limit: 100,
        page: 1,
      };
      this.$store.dispatch(BUSINESS_LIST_REQUEST, payloadToBusiness);
    }
    this.fetchData();
  },

  methods: {
    async fetchData() {
      await this.$store.dispatch(SHOP_LIST_REQUEST);
    },
    emptyingDataToEdit() {
      this.dataIdToEdit = null;
    },
    onCreate() {
      this.emptyingDataToEdit();
      this.openModal();
    },
    onEdit(id) {
      this.dataIdToEdit = id;
      this.openModal();
    },
    openModal() {
      this.$bvModal.show(this.editModalId);
    },
    onConfirmDelete(id) {
      this.dataIdToRemove = id;
      this.$bvModal.show(this.deleteModalId);
    },
    async remove() {
      if (this.me.isAdmin) {
        await this.$store.dispatch(SHOP_DELETE, this.dataIdToRemove);
        if (this.shopStatus === 'deleted') {
          this.emptyingDataToEdit();
          this.$bvModal.hide(this.editModalId);
          this.dataIdToRemove = null;
        }
      }
    },
  },

  components: {
    BaseLayout,
    InlineButton,
    ShopTable,
    ShopAddEditModal,
    ConfirmDialog,
  },
};
</script>
