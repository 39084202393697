import Vue from 'vue';

import { BootstrapVue, IconsPlugin, FormSelectPlugin, NavbarPlugin } from 'bootstrap-vue';
import Vuelidate from 'vuelidate';
import moment from 'moment-timezone';
import VueMoment from 'vue-moment';
import Datetime from 'vue-datetime';
import VueCompositionApi from '@vue/composition-api';
import Toasted from 'vue-toasted';
import VueCarousel from 'vue-carousel';
import VuePusher from 'vue-pusher';
import * as VueGoogleMaps from 'vue2-google-maps';
import { lowerCase } from 'lodash';
import * as Sentry from '@sentry/browser';
import * as Integrations from '@sentry/integrations';

import VueCookies from 'vue-cookies';
import App from './App';
import router from './router';
import store from './store';
import initializeFilters from './filters';
import i18n from './i18n';

// const timeZone = moment.tz.guess();
// moment.tz.setDefault(timeZone);
moment.locale(i18n.locale);

Vue.use(BootstrapVue, {
  breakpoints: [`xs`, 'sm', 'md', 'lg', 'xl', 'xxl'],
});
Vue.use(IconsPlugin);
Vue.use(NavbarPlugin);
Vue.use(FormSelectPlugin);
Vue.use(Vuelidate);
Vue.use(VueMoment, { moment });
Vue.use(Datetime);
Vue.use(VueCompositionApi);
Vue.use(VueCarousel);
Vue.use(Toasted, {
  theme: 'toasted-primary',
  position: 'bottom-right',
  duration: 5000,
  containerClass: 'notification-bottom-right',
});
Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_GOOGLE_MAPS_EMBED_API_KEY,
    libraries: 'places, timezone',
  },
  installComponents: true,
});
Vue.use(VueCookies);
Vue.config.productionTip = false;
Vue.$cookies.config('7d', null, process.env.VUE_APP_COOKIE_HOST_NAME);
initializeFilters(Vue);
new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app');

Sentry.init({
  enabled: process.env.VUE_APP_SENTRY_ENABLED,
  environment: process.env.NODE_ENV,
  dsn: process.env.VUE_APP_SENTRY_URL,
  integrations: [new Integrations.Vue({ Vue, attachProps: true })],
});

Sentry.configureScope(scope => {
  scope.setLevel(lowerCase(process.env.VUE_APP_SENTRY_LEVEL));
});

Vue.use(VuePusher, {
  api_key: process.env.VUE_APP_PUSHER_APP_KEY,
  options: {
    cluster: process.env.VUE_APP_PUSHER_APP_CLUSTER,
    encrypted: true,
    authEndpoint: `${process.env.VUE_APP_API_URL}/broadcasting/auth`,
    auth: {
      headers: {
        Authorization: `Bearer ${store.state.auth.token}`,
      },
    },
  },
});
