import { find, findIndex } from 'lodash';
import {
  DELIVERY_TYPE_LIST_REQUEST,
  DELIVERY_TYPE_LIST_REQUEST_ERROR,
  DELIVERY_TYPE_LIST_REQUEST_SUCCESS,
  DELIVERY_TYPE_CREATE,
  DELIVERY_TYPE_CREATE_SUCCESS,
  DELIVERY_TYPE_CREATE_ERROR,
  DELIVERY_TYPE_UPDATE,
  DELIVERY_TYPE_UPDATE_SUCCESS,
  DELIVERY_TYPE_UPDATE_ERROR,
  DELIVERY_TYPE_DELETE,
  DELIVERY_TYPE_DELETE_SUCCESS,
  DELIVERY_TYPE_DELETE_ERROR,
} from '@/store/actions/deliveryType';
import { handleRequestError } from '@/utils/error';
import Repository, { DELIVERY_TYPES } from '@/repositories/RepositoryFactory';

const DeliveryTypesRepository = Repository.get(DELIVERY_TYPES);

const state = {
  status: '',
  deliveryType: {},
  deliveryTypeList: [],
  deliveryTypeListMeta: {},
  deliveryTypeListStatus: '',
};

const getters = {
  deliveryType: state => state.deliveryType,
  deliveryTypeStatus: state => state.status,
  deliveryTypeList: state => state.deliveryTypeList,
  deliveryTypeListMeta: state => state.deliveryTypeListMeta,
  deliveryTypeListStatus: state => state.deliveryTypeListStatus,
  getDeliveryTypeById: (state, getters) => id => {
    if (getters.deliveryTypeList.length > 0) {
      return find(getters.deliveryTypeList, { id });
    }
    return state.deliveryType;
  },
};

const actions = {
  [DELIVERY_TYPE_LIST_REQUEST]: async ({ commit }, payload) => {
    commit(DELIVERY_TYPE_LIST_REQUEST);
    try {
      const response = await DeliveryTypesRepository.get(payload);
      commit(DELIVERY_TYPE_LIST_REQUEST_SUCCESS, response.data);
    } catch (error) {
      commit(DELIVERY_TYPE_LIST_REQUEST_ERROR);
      handleRequestError(error);
    }
  },
  [DELIVERY_TYPE_CREATE]: async ({ commit }, payload) => {
    commit(DELIVERY_TYPE_CREATE);
    try {
      const response = await DeliveryTypesRepository.create(payload);
      commit(DELIVERY_TYPE_CREATE_SUCCESS, response.data.data);
    } catch (error) {
      commit(DELIVERY_TYPE_CREATE_ERROR);
      handleRequestError(error);
    }
  },
  [DELIVERY_TYPE_UPDATE]: async ({ commit }, { id, data }) => {
    commit(DELIVERY_TYPE_UPDATE);
    try {
      const response = await DeliveryTypesRepository.update(id, data);
      commit(DELIVERY_TYPE_UPDATE_SUCCESS, response.data.data);
    } catch (error) {
      commit(DELIVERY_TYPE_UPDATE_ERROR);
      handleRequestError(error);
    }
  },
  [DELIVERY_TYPE_DELETE]: async ({ commit }, id) => {
    commit(DELIVERY_TYPE_DELETE);
    try {
      await DeliveryTypesRepository.delete(id);
      commit(DELIVERY_TYPE_DELETE_SUCCESS, id);
    } catch (error) {
      commit(DELIVERY_TYPE_DELETE_ERROR);
      handleRequestError(error);
    }
  },
};

/* eslint-disable no-param-reassign */
const mutations = {
  [DELIVERY_TYPE_LIST_REQUEST]: state => {
    state.deliveryTypeListStatus = 'loading';
  },
  [DELIVERY_TYPE_LIST_REQUEST_SUCCESS]: (state, payload) => {
    state.deliveryTypeListStatus = 'success';
    state.deliveryTypeList = payload.data;
    if (payload.meta) {
      state.deliveryTypeListMeta = payload.meta;
    }
  },
  [DELIVERY_TYPE_LIST_REQUEST_ERROR]: state => {
    state.deliveryTypeListStatus = 'error';
  },
  [DELIVERY_TYPE_CREATE]: state => {
    state.status = 'loading';
  },
  [DELIVERY_TYPE_CREATE_SUCCESS]: (state, payload) => {
    state.status = 'success';
    state.deliveryTypeList.push(payload);
  },
  [DELIVERY_TYPE_CREATE_ERROR]: state => {
    state.status = 'error';
  },
  [DELIVERY_TYPE_UPDATE]: state => {
    state.status = 'loading';
  },
  [DELIVERY_TYPE_UPDATE_SUCCESS]: (state, payload) => {
    state.status = 'success';
    state.deliveryTypeList = state.deliveryTypeList.map(item => {
      if (item.id === payload.id) {
        return payload;
      }
      return item;
    });
  },
  [DELIVERY_TYPE_UPDATE_ERROR]: state => {
    state.status = 'error';
  },
  [DELIVERY_TYPE_DELETE]: state => {
    state.status = 'deleting';
  },
  [DELIVERY_TYPE_DELETE_SUCCESS]: (state, payload) => {
    state.status = 'deleted';
    const index = findIndex(state.deliveryTypeList, { id: payload });
    state.deliveryTypeList.splice(index, 1);
  },
  [DELIVERY_TYPE_DELETE_ERROR]: state => {
    state.status = 'error';
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
