<template>
  <BaseLayout :title="title">
    <template #actions>
      <InputDatepicker
        v-model="dateStr"
        :placeholder="$t('placeholder.dateStart')"
        :max="today"
        @input="onChangeDate"
        class="form-group-rounded mb-0 purchase-date-picker"
      />
    </template>

    <DashboardTotalList :dashboard-list="dashboard" />

    <div class="dashboard-charts mb-4 mb-md-0">
      <SpinLoader class="loader" v-if="isLoading" :size="1.5" />
      <DashboardTicketSales v-else :chart="dashboard" />
    </div>
  </BaseLayout>
</template>

<script>
import { mapGetters } from 'vuex';
import { DASHBOARD_REQUEST } from '@/store/actions/dashboard';
import BaseLayout from '@/components/ui/BaseLayout';
import SpinLoader from '@/components/ui/SpinLoader';
import InputDatepicker from '@/components/ui/input/InputDatepicker';
import DashboardTotalList from '@/components/manage/dashboard/DashboardTotalList';
import DashboardTicketSales from '@/components/manage/dashboard/DashboardTicketSales';

export default {
  props: {
    title: String,
  },

  data() {
    return {
      today: this.$moment().format('YYYY-MM-DD'),
      date: '',
      dateStr: '',
    };
  },

  computed: {
    ...mapGetters(['dashboardStatus', 'dashboard']),
    isLoading() {
      return this.dashboardStatus === 'loading';
    },
  },

  created() {
    this.fetchData();
  },

  methods: {
    async fetchData() {
      const payload = {
        start: this.today,
        filter: {},
      };

      const startDate = this.date;

      payload.start = startDate;

      await this.$store.dispatch(DASHBOARD_REQUEST, payload);
    },
    onChangeDate(dateStr) {
      this.date = dateStr;
      this.fetchData(true);
    },
  },

  components: {
    BaseLayout,
    DashboardTotalList,
    DashboardTicketSales,
    InputDatepicker,
    SpinLoader,
  },
};
</script>

<style lang="scss" scoped>
@import '../../sass/variables';

::v-deep {
  .purchase-date-picker {
    width: 14.5rem;

    .form-control {
      text-align: center;
    }
  }
}

.dashboard-charts {
  padding: 0 1.5rem;

  .loader {
    margin-top: 20%;
  }
}
</style>
