import { find, findIndex } from 'lodash';
import {
  ADDON_REQUEST,
  ADDON_REQUEST_ERROR,
  ADDON_REQUEST_SUCCESS,
  ADDON_LIST_REQUEST,
  ADDON_LIST_REQUEST_ERROR,
  ADDON_LIST_REQUEST_SUCCESS,
  ADDON_CREATE,
  ADDON_CREATE_SUCCESS,
  ADDON_CREATE_ERROR,
  ADDON_UPDATE,
  ADDON_UPDATE_SUCCESS,
  ADDON_UPDATE_ERROR,
  ADDON_DELETE,
  ADDON_DELETE_SUCCESS,
  ADDON_DELETE_ERROR,
} from '@/store/actions/addon';
import { handleRequestError } from '@/utils/error';
import Repository, { ADDONS } from '@/repositories/RepositoryFactory';

const AddonsRepository = Repository.get(ADDONS);

const state = {
  status: '',
  addon: {},
  addonList: [],
  addonListMeta: {},
  addonListStatus: '',
};

const getters = {
  addonStatus: state => state.status,
  addonList: state => state.addonList,
  addonListMeta: state => state.addonListMeta,
  addonListStatus: state => state.addonListStatus,
  getAddonById: (state, getters) => id => {
    if (getters.addonList.length > 0) {
      return find(getters.addonList, { id });
    }
    return state.addon;
  },
};

const actions = {
  [ADDON_REQUEST]: async ({ commit }, id) => {
    commit(ADDON_REQUEST);
    try {
      const response = await AddonsRepository.show(id);
      commit(ADDON_REQUEST_SUCCESS, response.data);
    } catch (error) {
      commit(ADDON_REQUEST_ERROR);
      handleRequestError(error);
    }
  },
  [ADDON_LIST_REQUEST]: async ({ commit }, payload) => {
    commit(ADDON_LIST_REQUEST);
    try {
      const response = await AddonsRepository.get(payload);
      commit(ADDON_LIST_REQUEST_SUCCESS, response.data);
    } catch (error) {
      commit(ADDON_LIST_REQUEST_ERROR);
      handleRequestError(error);
    }
  },
  [ADDON_CREATE]: async ({ commit }, payload) => {
    commit(ADDON_CREATE);
    try {
      const response = await AddonsRepository.create(payload);
      commit(ADDON_CREATE_SUCCESS, response.data.data);
    } catch (error) {
      commit(ADDON_CREATE_ERROR);
      handleRequestError(error);
    }
  },
  [ADDON_UPDATE]: async ({ commit }, { id, data }) => {
    commit(ADDON_UPDATE);
    try {
      const response = await AddonsRepository.update(id, data);
      commit(ADDON_UPDATE_SUCCESS, response.data.data);
    } catch (error) {
      commit(ADDON_UPDATE_ERROR);
      handleRequestError(error);
    }
  },
  [ADDON_DELETE]: async ({ commit }, id) => {
    commit(ADDON_DELETE);
    try {
      await AddonsRepository.delete(id);
      commit(ADDON_DELETE_SUCCESS, id);
    } catch (error) {
      commit(ADDON_DELETE_ERROR);
      handleRequestError(error);
    }
  },
};

/* eslint-disable no-param-reassign */
const mutations = {
  [ADDON_REQUEST]: state => {
    state.status = 'loading';
  },
  [ADDON_REQUEST_SUCCESS]: (state, payload) => {
    state.status = 'success';
    state.addon = payload.data;
  },
  [ADDON_REQUEST_ERROR]: state => {
    state.status = 'error';
  },
  [ADDON_LIST_REQUEST]: state => {
    state.addonListStatus = 'loading';
  },
  [ADDON_LIST_REQUEST_SUCCESS]: (state, payload) => {
    state.addonListStatus = 'success';
    state.addonList = payload.data;
    if (payload.meta) {
      state.addonListMeta = payload.meta;
    }
  },
  [ADDON_LIST_REQUEST_ERROR]: state => {
    state.addonListStatus = 'error';
  },
  [ADDON_CREATE]: state => {
    state.status = 'loading';
  },
  [ADDON_CREATE_SUCCESS]: (state, payload) => {
    state.status = 'success';
    state.addonList.push(payload);
  },
  [ADDON_CREATE_ERROR]: state => {
    state.status = 'error';
  },
  [ADDON_UPDATE]: state => {
    state.status = 'loading';
  },
  [ADDON_UPDATE_SUCCESS]: (state, payload) => {
    state.status = 'success';
    state.addonList = state.addonList.map(item => {
      if (item.id === payload.id) {
        return payload;
      }
      return item;
    });
  },
  [ADDON_UPDATE_ERROR]: state => {
    state.status = 'error';
  },
  [ADDON_DELETE]: state => {
    state.status = 'deleting';
  },
  [ADDON_DELETE_SUCCESS]: (state, payload) => {
    state.status = 'deleted';
    const index = findIndex(state.addonList, { id: payload });
    state.addonList.splice(index, 1);
  },
  [ADDON_DELETE_ERROR]: state => {
    state.status = 'error';
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
