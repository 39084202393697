import { SALE_REQUEST, SALE_REQUEST_SUCCESS, SALE_REQUEST_ERROR } from '@/store/actions/order';
import { handleRequestError } from '@/utils/error';
import Repository, { ORDERS } from '@/repositories/RepositoryFactory';

const OrdersRepository = Repository.get(ORDERS);

const state = {
  status: '',
  saleResponse: {},
};

const getters = {
  saleStatus: state => state.status,
  saleResponse: state => state.saleResponse,
};

const actions = {
  [SALE_REQUEST]: async ({ commit }, payload) => {
    commit(SALE_REQUEST);
    try {
      const response = await OrdersRepository.sale(payload);
      commit(SALE_REQUEST_SUCCESS, response.data.data);
    } catch (error) {
      commit(SALE_REQUEST_ERROR);
      handleRequestError(error);
    }
  },
};

/* eslint-disable no-param-reassign */
const mutations = {
  [SALE_REQUEST]: state => {
    state.status = 'loading';
  },
  [SALE_REQUEST_SUCCESS]: (state, payload) => {
    state.status = 'success';
    state.saleResponse = payload;
  },
  [SALE_REQUEST_ERROR]: state => {
    state.status = 'error';
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
