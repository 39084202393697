<template>
  <div class="tabs">
    <ul>
      <li v-for="(item, index) in items" :key="index">
        <router-link
          v-if="item.to"
          :to="item.to"
          exact-active-class="is-active"
          class="tabs-link"
          role="button"
        >
          {{ item.name }}
        </router-link>
        <a
          v-else
          @click="$emit('click', item.value)"
          :class="['tabs-link', { 'is-active': item.value === currentTab }]"
          role="button"
        >
          {{ item.name }}
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    items: Array,
    currentTab: {
      type: String,
      default: '',
    },
  },
};
</script>
