import { find, findIndex } from 'lodash';
import {
  SHOP_LIST_REQUEST,
  SHOP_LIST_REQUEST_ERROR,
  SHOP_LIST_REQUEST_SUCCESS,
  SHOP_CREATE,
  SHOP_CREATE_SUCCESS,
  SHOP_CREATE_ERROR,
  SHOP_UPDATE,
  SHOP_UPDATE_SUCCESS,
  SHOP_UPDATE_ERROR,
  SHOP_DELETE,
  SHOP_DELETE_SUCCESS,
  SHOP_DELETE_ERROR,
  SHOP_SHOW,
  SHOP_SHOW_SUCCESS,
  SHOP_SHOW_ERROR,
  ADD_CASHIER,
  ADD_CASHIER_SUCCESS,
  ADD_CASHIER_ERROR,
  DELETE_CASHIER,
  DELETE_CASHIER_SUCCESS,
  DELETE_CASHIER_ERROR,
} from '@/store/actions/shop';
import { handleRequestError } from '@/utils/error';
import Repository, { SHOPS } from '@/repositories/RepositoryFactory';

const ShopsRepository = Repository.get(SHOPS);

const state = {
  status: '',
  shop: {},
  shopList: [],
  shopListMeta: {},
  shopListStatus: '',
  cashierStatus: '',
};

const getters = {
  shop: state => state.shop,
  shopStatus: state => state.status,
  shopList: state => state.shopList,
  shopListMeta: state => state.shopListMeta,
  shopListStatus: state => state.shopListStatus,
  getShopById: (state, getters) => id => {
    if (getters.shopList.length > 0) {
      return find(getters.shopList, { id });
    }
    return state.shop;
  },
  cashierStatus: state => state.cashierStatus,
};

const actions = {
  [SHOP_LIST_REQUEST]: async ({ commit }) => {
    commit(SHOP_LIST_REQUEST);
    try {
      const response = await ShopsRepository.get();
      commit(SHOP_LIST_REQUEST_SUCCESS, response.data);
    } catch (error) {
      commit(SHOP_LIST_REQUEST_ERROR);
      handleRequestError(error);
    }
  },
  [SHOP_SHOW]: async ({ commit }, id) => {
    commit(SHOP_SHOW);
    try {
      const response = await ShopsRepository.show(id);
      commit(SHOP_SHOW_SUCCESS, response.data);
    } catch (error) {
      commit(SHOP_SHOW_ERROR);
      handleRequestError(error);
    }
  },
  [SHOP_CREATE]: async ({ commit }, payload) => {
    commit(SHOP_CREATE);
    try {
      const response = await ShopsRepository.create(payload);
      commit(SHOP_CREATE_SUCCESS, response.data.data);
    } catch (error) {
      commit(SHOP_CREATE_ERROR);
      handleRequestError(error);
    }
  },
  [SHOP_UPDATE]: async ({ commit }, { id, data }) => {
    commit(SHOP_UPDATE);
    try {
      const response = await ShopsRepository.update(id, data);
      commit(SHOP_UPDATE_SUCCESS, response.data.data);
    } catch (error) {
      commit(SHOP_UPDATE_ERROR);
      handleRequestError(error);
    }
  },
  [SHOP_DELETE]: async ({ commit }, id) => {
    commit(SHOP_DELETE);
    try {
      await ShopsRepository.delete(id);
      commit(SHOP_DELETE_SUCCESS, id);
    } catch (error) {
      commit(SHOP_DELETE_ERROR);
      handleRequestError(error);
    }
  },
  [ADD_CASHIER]: async ({ commit }, { id, data }) => {
    commit(ADD_CASHIER);
    try {
      await ShopsRepository.addCashier(id, data);
      commit(ADD_CASHIER_SUCCESS);
    } catch (error) {
      commit(ADD_CASHIER_ERROR);
      handleRequestError(error);
    }
  },
  [DELETE_CASHIER]: async ({ commit }, { id, data }) => {
    commit(DELETE_CASHIER);
    try {
      await ShopsRepository.deleteCashier(id, data);
      commit(DELETE_CASHIER_SUCCESS);
    } catch (error) {
      commit(DELETE_CASHIER_ERROR);
      handleRequestError(error);
    }
  },
};

/* eslint-disable no-param-reassign */
const mutations = {
  [SHOP_LIST_REQUEST]: state => {
    state.shopListStatus = 'loading';
  },
  [SHOP_LIST_REQUEST_SUCCESS]: (state, payload) => {
    state.shopListStatus = 'success';
    state.shopList = payload.data;
    if (payload.meta) {
      state.shopListMeta = payload.meta;
    }
  },
  [SHOP_LIST_REQUEST_ERROR]: state => {
    state.shopListStatus = 'error';
  },
  [SHOP_SHOW]: state => {
    state.status = 'loading';
  },
  [SHOP_SHOW_SUCCESS]: (state, payload) => {
    state.status = 'success';
    state.shop = payload.data;
  },
  [SHOP_SHOW_ERROR]: state => {
    state.status = 'error';
  },
  [SHOP_CREATE]: state => {
    state.status = 'loading';
  },
  [SHOP_CREATE_SUCCESS]: (state, payload) => {
    state.status = 'success';
    state.shopList.push(payload);
  },
  [SHOP_CREATE_ERROR]: state => {
    state.status = 'error';
  },
  [SHOP_UPDATE]: state => {
    state.status = 'loading';
  },
  [SHOP_UPDATE_SUCCESS]: (state, payload) => {
    state.status = 'success';
    state.shopList = state.shopList.map(item => {
      if (item.id === payload.id) {
        return payload;
      }
      return item;
    });
  },
  [SHOP_UPDATE_ERROR]: state => {
    state.status = 'error';
  },
  [SHOP_DELETE]: state => {
    state.status = 'deleting';
  },
  [SHOP_DELETE_SUCCESS]: (state, payload) => {
    state.status = 'deleted';
    const index = findIndex(state.shopList, { id: payload });
    state.shopList.splice(index, 1);
  },
  [SHOP_DELETE_ERROR]: state => {
    state.status = 'error';
  },
  [ADD_CASHIER]: state => {
    state.cashierStatus = 'loading';
  },
  [ADD_CASHIER_SUCCESS]: state => {
    state.cashierStatus = 'success';
  },
  [ADD_CASHIER_ERROR]: state => {
    state.cashierStatus = 'error';
  },
  [DELETE_CASHIER]: state => {
    state.cashierStatus = 'deleting';
  },
  [DELETE_CASHIER_SUCCESS]: state => {
    state.cashierStatus = 'deleted';
  },
  [DELETE_CASHIER_ERROR]: state => {
    state.cashierStatus = 'error';
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
