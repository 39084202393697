<template>
  <BaseLayout :title="title">
    <template #actions>
      <InputSearch
        v-model="search"
        @input="onSearch"
        @clear="onClearSearch"
        class="product-search-input mb-0"
      />
    </template>
    <CustomerTable
      :list="customerList"
      :current-page="currentPage"
      :meta="customerListMeta"
      :is-loading="isLoading"
      @onShowDetails="onShowDetails"
      @onChangePage="onChangePage"
    />
  </BaseLayout>
</template>
<script>
import { mapGetters } from 'vuex';
import { CUSTOMER_LIST_REQUEST } from '@/store/actions/customer';
import { KEY_FILTER_FIRST_NAME } from '@/utils/filter';
import BaseLayout from '@/components/ui/BaseLayout';
import InputSearch from '@/components/ui/input/InputSearch';
import CustomerTable from '@/components/manage/customers/CustomerTable';
import { DEBOUNCE_MILLISECONDS } from '@/config';

export default {
  props: {
    title: String,
  },

  data() {
    return {
      currentPage: 1,
      customerIdToEdit: null,
      search: '',
      timeout: null,
    };
  },

  computed: {
    ...mapGetters(['customerList', 'customerListMeta', 'customerListStatus']),
    isLoading() {
      return this.customerListStatus === 'loading';
    },
  },

  created() {
    this.fetchData();
  },

  methods: {
    async fetchData(search) {
      const payload = {
        limit: 100,
        page: search ? 1 : this.currentPage,
        filter: {},
      };
      if (this.search) {
        payload.filter[`${KEY_FILTER_FIRST_NAME}`] = this.search;
      }
      await this.$store.dispatch(CUSTOMER_LIST_REQUEST, payload);
    },
    onClearSearch() {
      this.search = '';
      this.fetchData();
    },
    onSearch() {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.fetchData(true);
      }, DEBOUNCE_MILLISECONDS);
    },
    onChangePage(page) {
      this.currentPage = page;
      this.fetchData();
    },
    onShowDetails(customerId) {
      this.$router.push({ name: 'CustomerDetails', params: { id: customerId } });
    },
  },

  components: {
    BaseLayout,
    CustomerTable,
    InputSearch,
  },
};
</script>
