import { findIndex } from 'lodash';
import {
  CATEGORY_LIST_REQUEST,
  CATEGORY_LIST_REQUEST_ERROR,
  CATEGORY_LIST_REQUEST_SUCCESS,
  CATEGORY_CREATE,
  CATEGORY_CREATE_SUCCESS,
  CATEGORY_CREATE_ERROR,
  CATEGORY_UPDATE,
  CATEGORY_UPDATE_SUCCESS,
  CATEGORY_UPDATE_ERROR,
  CATEGORY_DELETE,
  CATEGORY_DELETE_SUCCESS,
  CATEGORY_DELETE_ERROR,
} from '@/store/actions/productCategory';
import { handleRequestError } from '@/utils/error';
import Repository, { PRODUCT_CATEGORIES } from '@/repositories/RepositoryFactory';

const ProductCategoriesRepository = Repository.get(PRODUCT_CATEGORIES);

function findItemNested(array, searchString, nestingKey) {
  return array.reduce((acc, { [nestingKey]: nested, ...o }) => {
    if (o.id === searchString) acc.push(o);

    if (nested) acc.push(...findItemNested(nested, searchString, nestingKey));

    return acc;
  }, []);
}

const state = {
  status: '',
  category: {},
  categoryList: [],
  categoryListStatus: '',
};

const getters = {
  categoryStatus: state => state.status,
  categoryList: state => state.categoryList,
  categoryListStatus: state => state.categoryListStatus,
  getCategoryById: (state, getters) => id => {
    if (getters.categoryList.length > 0) {
      const resultArray = findItemNested(getters.categoryList, id, 'children');
      return resultArray && resultArray.length > 0 ? resultArray[0] : null;
    }
    return state.category;
  },
};

const actions = {
  [CATEGORY_LIST_REQUEST]: async ({ commit }, payload) => {
    commit(CATEGORY_LIST_REQUEST);
    try {
      const response = await ProductCategoriesRepository.get(payload);
      commit(CATEGORY_LIST_REQUEST_SUCCESS, response.data);
    } catch (error) {
      commit(CATEGORY_LIST_REQUEST_ERROR);
      handleRequestError(error);
    }
  },
  [CATEGORY_CREATE]: async ({ commit }, payload) => {
    commit(CATEGORY_CREATE);
    try {
      await ProductCategoriesRepository.create(payload);
      commit(CATEGORY_CREATE_SUCCESS);
    } catch (error) {
      commit(CATEGORY_CREATE_ERROR);
      handleRequestError(error);
    }
  },
  [CATEGORY_UPDATE]: async ({ commit }, { id, data }) => {
    commit(CATEGORY_UPDATE);
    try {
      await ProductCategoriesRepository.update(id, data);
      commit(CATEGORY_UPDATE_SUCCESS);
    } catch (error) {
      commit(CATEGORY_UPDATE_ERROR);
      handleRequestError(error);
    }
  },
  [CATEGORY_DELETE]: async ({ commit }, id) => {
    commit(CATEGORY_DELETE);
    try {
      await ProductCategoriesRepository.delete(id);
      commit(CATEGORY_DELETE_SUCCESS);
    } catch (error) {
      commit(CATEGORY_DELETE_ERROR);
      handleRequestError(error);
    }
  },
};

/* eslint-disable no-param-reassign */
const mutations = {
  [CATEGORY_LIST_REQUEST]: state => {
    state.categoryListStatus = 'loading';
  },
  [CATEGORY_LIST_REQUEST_SUCCESS]: (state, payload) => {
    state.categoryListStatus = 'success';
    state.categoryList = payload;
  },
  [CATEGORY_LIST_REQUEST_ERROR]: state => {
    state.categoryListStatus = 'error';
  },
  [CATEGORY_CREATE]: state => {
    state.status = 'loading';
  },
  [CATEGORY_CREATE_SUCCESS]: state => {
    state.status = 'success';
  },
  [CATEGORY_CREATE_ERROR]: state => {
    state.status = 'error';
  },
  [CATEGORY_UPDATE]: state => {
    state.status = 'loading';
  },
  [CATEGORY_UPDATE_SUCCESS]: state => {
    state.status = 'success';
  },
  [CATEGORY_UPDATE_ERROR]: state => {
    state.status = 'error';
  },
  [CATEGORY_DELETE]: state => {
    state.status = 'deleting';
  },
  [CATEGORY_DELETE_SUCCESS]: (state, payload) => {
    state.status = 'deleted';
    const index = findIndex(state.categoryList, { id: payload });
    state.categoryList.splice(index, 1);
  },
  [CATEGORY_DELETE_ERROR]: state => {
    state.status = 'error';
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
