import Client from '@/clients/apiClient';
import { stringify } from 'query-string';
import moment from 'moment';
import fileClient from '@/clients/fileClient';

export default {
  sales(payload) {
    const startAt = moment(payload.startAt).format('YYYY-MM-DDT00:00:00ZZ');
    const endAt = moment(payload.endAt).format('YYYY-MM-DDT00:00:00ZZ');
    return Client.get(
      `reports/sales?${stringify({
        'filter[start_at]': startAt,
        'filter[end_at]': endAt,
      })}&filter[type]=${payload.type}&page=${payload.page}&limit=${payload.limit}`
    );
  },
  async salesExport(payload) {
    const startAt = moment(payload.startAt).format('YYYY-MM-DDT00:00:00ZZ');
    const endAt = moment(payload.endAt).format('YYYY-MM-DDT00:00:00ZZ');
    const url = `reports/sales?${stringify({
      'filter[start_at]': startAt,
      'filter[end_at]': endAt,
    })}&filter[type]=${payload.type}&page=${payload.page}&limit=${payload.limit}`;

    return fileClient.fetchCSV(url);
  },
  getChart(payload) {
    const startAt = moment(payload.startAt).format('YYYY-MM-DDT00:00:00ZZ');
    const endAt = moment(payload.endAt).format('YYYY-MM-DDT00:00:00ZZ');
    return Client.get(
      `reports/chart?${stringify({
        'filter[start_at]': startAt,
        'filter[end_at]': endAt,
      })}`
    );
  },
};
