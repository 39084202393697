<template>
  <TableBox>
    <b-table
      sticky-header
      id="my-table"
      :fields="fields"
      :items="list"
      :busy="isLoading"
      class="custom-table"
      thead-class="custom-table-header"
      tbody-tr-class="custom-table-body"
      :emptyText="$t('text.emptyText')"
      show-empty
    >
      <template #cell(index)="data">
        {{ data.index + 1 }}
      </template>

      <template #cell(name)="data">
        {{ data.item | fullName }}
      </template>

      <template #cell(action)="data">
        <div class="table-action-buttons">
          <InlineButton
            variant="outline-danger"
            size="sm"
            pill
            @click="$emit('delete', data.item.id)"
          >
            {{ $t('text.deleteButton') }}
          </InlineButton>
        </div>
      </template>
    </b-table>
  </TableBox>
</template>

<script>
import TableBox from '@/components/ui/TableBox';
import InlineButton from '@/components/ui/button/InlineButton';

export default {
  props: {
    list: Array,
    isLoading: Boolean,
  },

  data() {
    return {
      fields: [
        {
          key: 'index',
          label: '#',
        },
        {
          key: 'name',
          label: this.$t('table.th.name'),
        },
        {
          key: 'email',
          label: this.$t('table.th.email'),
        },
        {
          key: 'phone',
          label: this.$t('table.th.phone'),
        },
        {
          key: 'action',
          label: this.$t('table.th.settings'),
        },
      ],
    };
  },

  components: {
    TableBox,
    InlineButton,
  },
};
</script>
