import { findIndex, find } from 'lodash';
import {
  BUSINESS_LIST_REQUEST,
  BUSINESS_LIST_REQUEST_SUCCESS,
  BUSINESS_LIST_REQUEST_ERROR,
  BUSINESS_CREATE,
  BUSINESS_CREATE_SUCCESS,
  BUSINESS_CREATE_ERROR,
  BUSINESS_UPDATE,
  BUSINESS_UPDATE_SUCCESS,
  BUSINESS_UPDATE_ERROR,
  BUSINESS_DELETE,
  BUSINESS_DELETE_SUCCESS,
  BUSINESS_DELETE_ERROR,
} from '@/store/actions/business';
import { USER_BUSINESS_UPDATE } from '@/store/actions/user';

import { handleRequestError } from '@/utils/error';
import Repository, { BUSINESSES } from '@/repositories/RepositoryFactory';

const BusinessesRepository = Repository.get(BUSINESSES);

const state = {
  status: '',
  business: {},
  businessList: [],
  businessListMeta: {},
  businessListStatus: '',
};

const getters = {
  businessStatus: state => state.status,
  businessList: state => state.businessList,
  businessListMeta: state => state.businessListMeta,
  businessListStatus: state => state.businessListStatus,
  getBusinessById: (state, getters) => id => {
    if (getters.businessList.length > 0) {
      return find(getters.businessList, { id });
    }
    return state.business;
  },
};

const actions = {
  [BUSINESS_LIST_REQUEST]: async ({ commit }, payload) => {
    commit(BUSINESS_LIST_REQUEST);
    try {
      const response = await BusinessesRepository.get(payload);
      commit(BUSINESS_LIST_REQUEST_SUCCESS, response.data);
    } catch (error) {
      commit(BUSINESS_LIST_REQUEST_ERROR);
      handleRequestError(error);
    }
  },
  [BUSINESS_CREATE]: async ({ commit }, payload) => {
    commit(BUSINESS_CREATE);
    try {
      const response = await BusinessesRepository.create(payload);
      commit(BUSINESS_CREATE_SUCCESS, response.data.data);
    } catch (error) {
      commit(BUSINESS_CREATE_ERROR);
      handleRequestError(error);
    }
  },
  [BUSINESS_UPDATE]: async ({ commit, rootGetters }, payload) => {
    commit(BUSINESS_UPDATE);
    try {
      const response = await BusinessesRepository.update(payload.id, payload.data);
      commit(BUSINESS_UPDATE_SUCCESS, response.data.data);
      if (rootGetters.me?.businessId && rootGetters.me.businessId === payload.id) {
        commit(USER_BUSINESS_UPDATE, response.data.data);
      }
    } catch (error) {
      commit(BUSINESS_UPDATE_ERROR);
      handleRequestError(error);
    }
  },
  [BUSINESS_DELETE]: async ({ commit }, id) => {
    commit(BUSINESS_DELETE);
    try {
      await BusinessesRepository.delete(id);
      commit(BUSINESS_DELETE_SUCCESS);
    } catch (error) {
      commit(BUSINESS_DELETE_ERROR);
      handleRequestError(error);
    }
  },
};
const mutations = {
  [BUSINESS_LIST_REQUEST]: state => {
    state.businessListStatus = 'loading';
  },
  [BUSINESS_LIST_REQUEST_SUCCESS]: (state, payload) => {
    state.businessListStatus = 'success';
    state.businessList = payload.data;

    if (payload.meta) {
      state.businessListMeta = payload.meta;
    }
  },
  [BUSINESS_LIST_REQUEST_ERROR]: state => {
    state.businessListStatus = 'error';
  },
  [BUSINESS_CREATE]: state => {
    state.status = 'loading';
  },
  [BUSINESS_CREATE_SUCCESS]: (state, payload) => {
    state.status = 'success';
    state.businessList.unshift(payload);
  },
  [BUSINESS_CREATE_ERROR]: state => {
    state.status = 'error';
  },
  [BUSINESS_UPDATE]: state => {
    state.status = 'loading';
  },
  [BUSINESS_UPDATE_SUCCESS]: (state, payload) => {
    state.status = 'success';
    state.businessList = state.businessList.map(item => {
      if (item.id === payload.id) {
        return payload;
      }
      return item;
    });
  },
  [BUSINESS_UPDATE_ERROR]: state => {
    state.status = 'error';
  },
  [BUSINESS_DELETE]: state => {
    state.status = 'deleting';
  },
  [BUSINESS_DELETE_SUCCESS]: (state, payload) => {
    state.status = 'deleted';
    const index = findIndex(state.businessList, { id: payload });
    state.businessList.splice(index, 1);
  },
  [BUSINESS_DELETE_ERROR]: state => {
    state.status = 'error';
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
