<template>
  <TableBox :current-page="currentPage" :meta="meta" @onChangePage="$emit('onChangePage', $event)">
    <b-table
      sticky-header
      id="my-table"
      :fields="fields"
      :items="list"
      :busy="isLoading"
      class="custom-table"
      thead-class="custom-table-header"
      tbody-tr-class="custom-table-body"
      :empty-text="$t('customers.emptyText')"
      show-empty
    >
      <template #cell(name)="data">
        {{ getBusinessName(data.item.businessId) }}
      </template>
      <template #cell(bank)="data">
        <p class="text-nowrap">{{ getBankName(data.item.bankId) }}</p>
      </template>
      <template #cell(account)="data">
        <p class="text-nowrap mb-2">{{ data.item.accountHolder }}</p>
        <p class="text-nowrap">{{ data.item.accountNumber }}</p>
      </template>
      <template #cell(createdAt)="data">
        <span class="text-nowrap">
          {{
            data.item.createdAt
              ? $options.filters.moment(data.item.createdAt, 'YYYY-MM-DD HH:mm')
              : '-'
          }}
        </span>
      </template>

      <template #cell(details)="data">
        <div class="table-action-buttons">
          <InlineButton
            variant="outline-secondary"
            size="sm"
            pill
            @click="$emit('edit', data.item.id)"
            class="has-shadow"
          >
            {{ $t('text.editButton') }}
          </InlineButton>
        </div>
      </template>
    </b-table>
  </TableBox>
</template>
<script>
import TableBox from '@/components/ui/TableBox';
import InlineButton from '@/components/ui/button/InlineButton';

export default {
  props: {
    isLoading: Boolean,
    meta: Object,
    currentPage: Number,
    list: Array,
    businessList: Array,
    bankList: Array,
  },
  data() {
    return {
      fields: [
        {
          key: 'name',
          label: this.$t('table.th.name'),
        },
        {
          key: 'bank',
          label: this.$t('table.th.bank'),
        },
        {
          key: 'account',
          label: this.$t('table.th.account'),
        },
        {
          key: 'createdAt',
          label: this.$t('table.th.registered'),
        },
        {
          key: 'details',
          label: '',
        },
      ],
    };
  },

  methods: {
    getBusinessName(businessId) {
      const business = this.businessList.find(item => item.id === businessId);
      return business ? business.name : '';
    },
    getBankName(bankId) {
      const bank = this.bankList.find(item => item.id === bankId);
      return bank ? bank.name : '';
    },
  },

  components: {
    TableBox,
    InlineButton,
  },
};
</script>
