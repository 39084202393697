import {
  SALES_REPORT_REQUEST,
  SALES_REPORT_REQUEST_SUCCESS,
  SALES_REPORT_REQUEST_ERROR,
  SALES_REPORT_EXPORT,
  SALES_REPORT_EXPORT_SUCCESS,
  SALES_REPORT_EXPORT_ERROR,
  CHART_REQUEST,
  CHART_REQUEST_SUCCESS,
  CHART_REQUEST_ERROR,
} from '@/store/actions/report';
import { handleRequestError } from '@/utils/error';
import Repository, { REPORTS } from '@/repositories/RepositoryFactory';

const ReportRepository = Repository.get(REPORTS);

const state = {
  status: '',
  reportList: [],
  reportMeta: {},
  reportLink: '',
  chartStatus: '',
  chart: {},
};

const getters = {
  reportStatus: state => state.status,
  reportList: state => state.reportList,
  reportMeta: state => state.reportMeta,
  reportLink: state => state.reportLink,
  chartStatus: state => state.chartStatus,
  chart: state => state.chart,
};

const actions = {
  [SALES_REPORT_REQUEST]: async ({ commit }, payload) => {
    commit(SALES_REPORT_REQUEST);
    try {
      const response = await ReportRepository.sales(payload);
      commit(SALES_REPORT_REQUEST_SUCCESS, response.data);
    } catch (error) {
      commit(SALES_REPORT_REQUEST_ERROR);
      handleRequestError(error);
    }
  },
  [SALES_REPORT_EXPORT]: async ({ commit }, payload) => {
    commit(SALES_REPORT_EXPORT);
    try {
      const response = await ReportRepository.salesExport(payload);
      commit(SALES_REPORT_EXPORT_SUCCESS, await response.json());
    } catch (error) {
      commit(SALES_REPORT_EXPORT_ERROR);
      handleRequestError(error);
    }
  },
  [CHART_REQUEST]: async ({ commit }, payload) => {
    commit(CHART_REQUEST);
    try {
      const response = await ReportRepository.getChart(payload);
      commit(CHART_REQUEST_SUCCESS, response.data);
    } catch (error) {
      commit(CHART_REQUEST_ERROR);
      handleRequestError(error);
    }
  },
};

const mutations = {
  [SALES_REPORT_REQUEST]: state => {
    state.status = 'loading';
  },
  [SALES_REPORT_REQUEST_SUCCESS]: (state, payload) => {
    state.status = 'success';
    state.reportList = payload.data;
    state.reportMeta = payload.meta;
  },
  [SALES_REPORT_REQUEST_ERROR]: state => {
    state.status = 'error';
  },
  [SALES_REPORT_EXPORT]: state => {
    state.status = 'downloading';
  },
  [SALES_REPORT_EXPORT_SUCCESS]: (state, payload) => {
    state.status = 'success';
    state.reportLink = payload.url;
  },
  [SALES_REPORT_EXPORT_ERROR]: state => {
    state.status = 'error';
  },
  [CHART_REQUEST]: state => {
    state.chartStatus = 'loading';
  },
  [CHART_REQUEST_SUCCESS]: (state, payload) => {
    state.chartStatus = 'success';
    state.chart = payload.data;
  },
  [CHART_REQUEST_ERROR]: state => {
    state.chartStatus = 'error';
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
