export const CUSTOMER_LIST_REQUEST = 'CUSTOMER_LIST_REQUEST';
export const CUSTOMER_LIST_REQUEST_SUCCESS = 'CUSTOMER_LIST_REQUEST_SUCCESS';
export const CUSTOMER_LIST_REQUEST_ERROR = 'CUSTOMER_LIST_REQUEST_ERROR';

export const CUSTOMER_DETAILS_REQUEST = 'CUSTOMER_DETAILS_REQUEST';
export const CUSTOMER_DETAILS_REQUEST_SUCCESS = 'CUSTOMER_DETAILS_REQUEST_SUCCESS';
export const CUSTOMER_DETAILS_REQUEST_ERROR = 'CUSTOMER_DETAILS_REQUEST_ERROR';

export const CUSTOMER_PROFILE_UPDATE = 'CUSTOMER_PROFILE_UPDATE';
export const CUSTOMER_PROFILE_UPDATE_SUCCESS = 'CUSTOMER_PROFILE_UPDATE_SUCCESS';
export const CUSTOMER_PROFILE_UPDATE_ERROR = 'CUSTOMER_PROFILE_UPDATE_ERROR';
