export const SALES_REPORT_REQUEST = 'SALES_REPORT_REQUEST';
export const SALES_REPORT_REQUEST_SUCCESS = 'SALES_REPORT_REQUEST_SUCCESS';
export const SALES_REPORT_REQUEST_ERROR = 'SALES_REPORT_REQUEST_ERROR';

export const SALES_REPORT_EXPORT = 'SALES_REPORT_EXPORT';
export const SALES_REPORT_EXPORT_SUCCESS = 'SALES_REPORT_EXPORT_SUCCESS';
export const SALES_REPORT_EXPORT_ERROR = 'SALES_REPORT_EXPORT_ERROR';

export const CHART_REQUEST = 'CHART_REQUEST';
export const CHART_REQUEST_SUCCESS = 'CHART_REQUEST_SUCCESS';
export const CHART_REQUEST_ERROR = 'CHART_REQUEST_ERROR';
