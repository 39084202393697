<template>
  <BaseLayout :title="title">
    <template #actions>
      <InlineButton @click="onCreate" pill shadow>
        <span class="text-uppercase">{{ $t('text.newButton') }}</span>
      </InlineButton>
    </template>

    <InventoriesTable
      :list="inventoryList"
      :meta="inventoryListMeta"
      :current-page="currentPage"
      :is-loading="isLoading"
      @edit="onEdit"
      @onChangePage="onChangePage"
    />

    <InventoryAddEditModal
      :form-data="dataToEdit"
      :modal-id="editModalId"
      :product-list="productList"
      :business-id="me.businessId"
      @onHidden="emptyingDataToEdit"
      @onRemove="onConfirmDelete"
    />

    <ConfirmDialog :id="deleteModalId" @ok="remove" />
  </BaseLayout>
</template>

<script>
import { mapGetters } from 'vuex';
import { INVENTORY_LIST_REQUEST, INVENTORY_DELETE } from '@/store/actions/inventory';
import { PRODUCT_LIST_REQUEST } from '@/store/actions/product';
import meMixin from '@/mixins/meMixin';
import BaseLayout from '@/components/ui/BaseLayout';
import InlineButton from '@/components/ui/button/InlineButton';
import InventoriesTable from '@/components/manage/inventories/InventoriesTable';
import InventoryAddEditModal from '@/components/manage/inventories/InventoryAddEditModal';
import ConfirmDialog from '@/components/ui/ConfirmDialog';

export default {
  mixins: [meMixin],

  props: {
    title: String,
  },

  data() {
    return {
      currentPage: 1,
      dataIdToEdit: null,
      dataIdToRemove: null,
      editModalId: 'manage-form-modal',
      deleteModalId: 'manage-delete-modal',
    };
  },

  computed: {
    ...mapGetters([
      'inventoryList',
      'inventoryListMeta',
      'inventoryListStatus',
      'getInventoryById',
      'inventoryStatus',
      'productList',
    ]),
    dataToEdit() {
      return this.dataIdToEdit ? this.getInventoryById(this.dataIdToEdit) : null;
    },
    isLoading() {
      return this.inventoryListStatus === 'loading';
    },
  },

  created() {
    this.fetchData();
  },

  methods: {
    fetchData() {
      const payload = {
        limit: 10,
        page: this.currentPage,
        businessId: !this.me.isAdmin ? this.me.businessId : null,
        filter: {},
      };
      this.$store.dispatch(INVENTORY_LIST_REQUEST, payload);

      const payloadToProduct = {
        limit: 1000,
        page: 1,
        filter: {},
      };
      this.$store.dispatch(PRODUCT_LIST_REQUEST, payloadToProduct);
    },
    emptyingDataToEdit() {
      this.dataIdToEdit = null;
    },
    onCreate() {
      this.emptyingDataToEdit();
      this.openModal();
    },
    onEdit(id) {
      this.dataIdToEdit = id;
      this.openModal();
    },
    openModal() {
      this.$bvModal.show(this.editModalId);
    },
    onChangePage(page) {
      this.currentPage = page;
      this.fetchData();
    },
    onConfirmDelete(id) {
      this.dataIdToRemove = id;
      this.$bvModal.show(this.deleteModalId);
    },
    async remove() {
      await this.$store.dispatch(INVENTORY_DELETE, this.dataIdToRemove);
      if (this.inventoryStatus === 'deleted') {
        this.emptyingDataToEdit();
        this.$bvModal.hide(this.editModalId);
        this.dataIdToRemove = null;
      }
    },
  },

  components: {
    BaseLayout,
    InlineButton,
    InventoriesTable,
    InventoryAddEditModal,
    ConfirmDialog,
  },
};
</script>
