import Vue from 'vue';
import Vuex from 'vuex';
import auth from '@/store/modules/auth';
import file from '@/store/modules/file';
import resetPassword from '@/store/modules/resetPassword';
import user from '@/store/modules/user';
import productCategory from '@/store/modules/productCategory';
import staff from '@/store/modules/staff';
import addonCategory from '@/store/modules/addonCategory';
import addon from '@/store/modules/addon';
import product from '@/store/modules/product';
import aboutApp from '@/store/modules/aboutApp';
import productSale from '@/store/modules/productSale';
import report from '@/store/modules/report';
import customer from '@/store/modules/customer';
import pickUpDuration from '@/store/modules/pickUpDuration';
import optionType from '@/store/modules/optionType';
import business from '@/store/modules/business';
import banner from '@/store/modules/banner';
import bank from '@/store/modules/bank';
import businessBank from '@/store/modules/businessBank';
import dashboard from '@/store/modules/dashboard';
import blog from '@/store/modules/blog';
import deliveryType from '@/store/modules/deliveryType';
import inventory from '@/store/modules/inventory';
import order from '@/store/modules/order';
import shop from '@/store/modules/shop';

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== 'production';

export default new Vuex.Store({
  modules: {
    auth,
    file,
    resetPassword,
    user,
    productCategory,
    staff,
    addonCategory,
    addon,
    product,
    aboutApp,
    productSale,
    report,
    customer,
    pickUpDuration,
    optionType,
    business,
    banner,
    bank,
    businessBank,
    dashboard,
    blog,
    deliveryType,
    inventory,
    order,
    shop,
  },
  strict: debug,
});
