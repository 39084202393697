export const PRODUCT_REQUEST = 'PRODUCT_REQUEST';
export const PRODUCT_REQUEST_SUCCESS = 'PRODUCT_REQUEST_SUCCESS';
export const PRODUCT_REQUEST_ERROR = 'PRODUCT_REQUEST_ERROR';

export const PRODUCT_LIST_REQUEST = 'PRODUCT_LIST_REQUEST';
export const PRODUCT_LIST_REQUEST_SUCCESS = 'PRODUCT_LIST_REQUEST_SUCCESS';
export const PRODUCT_LIST_REQUEST_ERROR = 'PRODUCT_LIST_REQUEST_ERROR';

export const PRODUCT_CREATE = 'PRODUCT_CREATE';
export const PRODUCT_CREATE_SUCCESS = 'PRODUCT_CREATE_SUCCESS';
export const PRODUCT_CREATE_ERROR = 'PRODUCT_CREATE_ERROR';

export const PRODUCT_UPDATE = 'PRODUCT_UPDATE';
export const PRODUCT_UPDATE_SUCCESS = 'PRODUCT_UPDATE_SUCCESS';
export const PRODUCT_UPDATE_ERROR = 'PRODUCT_UPDATE_ERROR';

export const PRODUCT_DELETE = 'PRODUCT_DELETE';
export const PRODUCT_DELETE_SUCCESS = 'PRODUCT_DELETE_SUCCESS';
export const PRODUCT_DELETE_ERROR = 'PRODUCT_DELETE_ERROR';
