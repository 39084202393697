<template>
  <div>
    <BoxCard
      :title="$t('dashboard.totalOrders')"
      title-class="text-primary font-weight-bold"
      no-button
      class="dashboard-box"
    >
      <LineChart :height="140" :chart-data="chartData" :options="options" />
    </BoxCard>
  </div>
</template>

<script>
import BoxCard from '@/components/ui/BoxCard';
import LineChart from '@/components/charts/LineChart';

export default {
  props: {
    chart: Object,
  },

  computed: {
    chartData() {
      return {
        labels: this.chart.prices.map(item => {
          return item.date1;
        }),
        datasets: [
          {
            data: this.chart.prices.map(item => {
              return item.orders;
            }),
            borderWidth: 2,
            backgroundColor: 'rgba(255, 105, 97, 0.2)',
            borderColor: '#FF6961',
          },
        ],
      };
    },
    options() {
      return {
        legend: {
          display: false,
        },
        title: { display: false },
        tooltips: {
          mode: 'y',
        },
        scales: {
          xAxes: [
            {
              ticks: {
                fontSize: '12',
                fontColor: '#777777',
              },
              gridLines: {
                display: false,
              },
            },
          ],
          yAxes: [
            {
              ticks: {
                fontSize: '12',
                fontColor: '#777777',
                callback(value) {
                  return `${value}`;
                },
              },
              gridLines: {
                color: '#F0F0F0',
                zeroLineColor: '#000000',
                zeroLineWidth: 2,
                drawBorder: false,
              },
            },
          ],
        },
      };
    },
  },

  components: {
    BoxCard,
    LineChart,
  },
};
</script>
