<template>
  <section class="checkout-compute">
    <div class="total-payment">
      <strong>{{ $t('checkout.total') }}</strong>
      <strong>{{ total | currency }}</strong>
    </div>
    <div class="input-container mb-2">
      <label>{{ $t('checkout.pay') }}</label>
      <InputMoney
        ref="inputPay"
        v-model="types[selectedTypeIndex].amount"
        :value-as-integer="false"
        @input="onChangeInput(selectedTypeIndex)"
      />
    </div>

    <b-row>
      <template v-for="(type, typeIndex) in types">
        <b-col :key="`type-button-${typeIndex}`" cols="6">
          <InlineButton
            :variant="typeIndex === selectedTypeIndex ? 'outline-primary' : 'light'"
            size="md"
            block
            :class="['checkout-compute-button', { 'is-qpay': type.paymentType === 'QPAY' }]"
            @click="onChangeType(typeIndex)"
          >
            <img
              v-if="type.paymentType === 'QPAY'"
              src="@/assets/icons/qpay_white.svg"
              height="19"
              class="d-block"
            />
            <strong v-else>{{ type.name }}</strong>
          </InlineButton>
        </b-col>
      </template>
    </b-row>

    <hr />

    <template v-for="(type, typeIndex) in types">
      <div v-if="type.amount > 0" :key="`type-${typeIndex}`" class="space-between">
        <p class="text-capitalize">{{ type.name.toLowerCase() }}</p>
        <div>
          <p>{{ (type.amount * 100) | currency }}</p>
          <div @click="clearAmount(type)" class="item-close">
            <img src="@/assets/icons/ic_close.svg" />
          </div>
        </div>
      </div>
    </template>
  </section>
</template>

<script>
import InlineButton from '@/components/ui/button/InlineButton';
import InputMoney from '@/components/ui/input/InputMoney';

export default {
  props: {
    total: Number,
    types: Array,
  },

  components: {
    InlineButton,
    InputMoney,
  },

  data() {
    return {
      selectedTypeIndex: 1,
    };
  },

  methods: {
    onChangeInput(index) {
      // 'CASH' хэлбэртэй төлөлтийн index
      const cashIndex = this.types.findIndex(item => item.paymentType === 'CASH');

      // Оруулж буй дүнгээс шалтгаалан
      // 'Илүү төлөлт' үүссэн,
      // оруулж буй төлөлтийн хэлбэр нь 'CASH'-аас ялгаатай бол
      if (this.difference > 0 && index !== cashIndex) {
        // Хэрэв 'CASH' хэлбэртэй оруулсан дүн нь
        // 'Илүү төлөлт'-өөс их эсвэл тэнцүү бол
        if (this.types[cashIndex].amount >= this.difference) {
          // 'CASH' хэлбэртэй оруулсан дүнгээс 'Илүү төлөлт'-г хасах
          this.types[cashIndex].amount -= this.difference;
          return;
        }
        // Оруулж буй дүнгээс 'Илүү төлөлт'-г хасах
        this.types[index].amount -= this.difference;
      }
    },
    onChangeType(index) {
      this.selectedTypeIndex = index;
      this.$refs.inputPay.$el.querySelector('.form-control').focus();
    },
    clearAmount(type) {
      type.amount = 0;
    },
  },
  computed: {
    typesTotalAmount() {
      return this.types.reduce((total, item) => total + item.amount, 0);
    },
    // 'Нийт оруулсан дүн' болон 'Төлөх ёстой дүн'-гийн зөрүү
    difference() {
      return this.typesTotalAmount - this.total / 100;
    },
  },
};
</script>

<style lang="scss">
@import '../../../sass/variables';
@import '../../../sass/flexBox';

.checkout-compute {
  p {
    margin-bottom: 0;
  }

  .form-control {
    text-align: center;
  }

  .input-container {
    position: relative;
    text-align: center;

    label {
      position: absolute;
      top: 0.25rem;
      left: 50%;
      transform: translateX(-50%);
      text-transform: uppercase;
      color: $primary;
      margin-bottom: 0;
      font-size: 0.75rem;
      z-index: 4;
    }

    .form-control {
      padding-top: 1rem;
      padding-bottom: 0;
      height: auto;
      font-size: 2.5rem;
      color: $black;
    }
  }

  .row {
    margin: -0.5rem !important;

    & > div {
      padding: 0.5rem !important;
    }
  }

  .total-payment {
    background-color: rgba($gray-300, 0.5);
    font-size: 1.25rem;
    border-radius: 0.25rem;
    padding: 1rem 1.5rem;
    margin-bottom: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .space-between {
    margin-top: 0.75rem;
    @include flexBox(flex, flex-start, space-between);

    & > * {
      @include flexBox(flex, center, flex-start);

      & + * {
        margin-left: 0.5rem;
      }
    }

    .item-close {
      height: 24px;
      width: 24px;
      background-color: $red;
      border-radius: 50%;
      margin-left: 0.5rem;
      cursor: pointer;
      @include flexBox(flex, center, center);

      img {
        height: 10px;
        width: 10px;
      }

      &:hover {
        background-color: darken($red, 20%);
      }
    }
  }

  [class*=' border-'] {
    border-width: 2px !important;
  }

  .border-bottom + .border-top {
    border-top-width: 0 !important;
    padding-top: 0 !important;
  }

  .checkout-compute-button {
    height: 100%;
    line-height: 1.2;
    font-weight: 500;

    &.is-qpay {
      color: white;
      display: flex;
      justify-content: center;

      img {
        display: block;
      }

      &.btn-dark {
        border-color: #092158;
        background-color: #092158;
      }
    }

    strong {
      font-weight: 500;
    }
  }
}
</style>
