<template>
  <ModalMain
    :id="modalId"
    :title="title"
    :ok-title="saveButtonTitle"
    :loading="false"
    delete-disabled
    @shown="onShown"
    @hidden="onHidden"
    @ok="onSave"
    @cancel="onCancel"
  >
    <b-form @submit.prevent="onSave" class="product-variant-form">
      <b-row>
        <b-col cols="6" sm="6">
          <InputString
            class="sku"
            :label="$t('label.sku')"
            v-model="$v.form.sku.$model"
            :vuelidate="$v.form.sku"
          />
        </b-col>
        <b-col cols="6" sm="6">
          <InputString class="sizeString" :label="$t('label.size')" v-model="form.sizeString" />
        </b-col>
        <b-col cols="12">
          <InputSelect
            class="delivery-type"
            :label="$t('label.deliveryType')"
            v-model="form.deliveryTypeId"
            :options="deliveryTypeList"
            :disabled="deliveryTypeListStatus === 'loading'"
            :first-item-label="''"
            :first-item-disabled="false"
          />
        </b-col>
        <!-- <b-col cols="6" sm="6">
          <InputNumber
            class="width"
            :label="$t('label.width')"
            v-model.number="$v.form.width.$model"
            :vuelidate="$v.form.width"
          />
        </b-col>
        <b-col cols="6" sm="6">
          <InputNumber
            class="depth"
            :label="$t('label.depth')"
            v-model.number="$v.form.depth.$model"
            :vuelidate="$v.form.depth"
          />
        </b-col>
        <b-col cols="6" sm="6">
          <InputNumber
            class="height"
            :label="$t('label.height')"
            v-model.number="$v.form.height.$model"
            :vuelidate="$v.form.height"
          />
        </b-col>
        <b-col cols="6" sm="6">
          <InputNumber
            class="weight"
            :label="$t('label.weight')"
            v-model.number="$v.form.weight.$model"
            :vuelidate="$v.form.weight"
          />
        </b-col> -->
      </b-row>

      <h4 class="mt-4 mb-3 font-weight-bold">{{ $t('label.price') }}</h4>

      <InputMoney
        class="master-price"
        :label="$t('label.masterPrice')"
        v-model="$v.form.masterPrice.$model"
        :vuelidate="$v.form.masterPrice"
      />

      <template v-for="(price, priceIndex) in $v.form.prices.$each.$iter">
        <div v-if="form.prices[priceIndex].status" :key="`price-${priceIndex}`">
          <hr />
          <InlineButton
            @click="onRemove(priceIndex)"
            size="sm"
            variant="outline-danger"
            class="mb-2"
          >
            {{ $t('text.deleteButton') }}
          </InlineButton>
          <b-row class="product-variant-form-row">
            <b-col cols="12" sm="4">
              <InputMoney
                class="price mb-0"
                :label="$t('label.salePrice')"
                v-model="price.amount.$model"
                :vuelidate="price.amount"
              />
            </b-col>
            <b-col cols="6" sm="4">
              <InputDatepicker
                class="start-date mb-0"
                :label="$t('label.startAt')"
                v-model="price.startDate.$model"
                :vuelidate="price.startDate"
              />
            </b-col>
            <b-col cols="6" sm="4">
              <InputDatepicker
                class="end-date mb-0"
                :label="$t('label.endAt')"
                v-model="price.endDate.$model"
                :vuelidate="price.endDate"
                :min="form.prices[priceIndex].startDate"
              />
            </b-col>
          </b-row>
        </div>
      </template>

      <div class="text-right mt-2">
        <InlineButton @click="addSalePrice">
          {{ $t('product.addSalePrice') }}
        </InlineButton>
      </div>

      <h4 class="mt-4 mb-3 font-weight-bold">{{ $t('label.optionTypes') }}</h4>
      <div
        v-for="(optionType, optionTypeIndex) in formOptionTypes"
        :key="optionTypeIndex"
        class="mb-3"
      >
        <p class="mb-0">{{ optionType.name }}</p>
        <template v-if="optionType.type === 'MULTIPLE'">
          <b-form-checkbox
            v-for="option in optionType.values"
            v-model="optionType.selectedValues"
            :key="option.id"
            :value="option.id"
            class="mt-1"
          >
            {{ option.name }}
          </b-form-checkbox>
        </template>
        <div v-else class="d-flex align-items-center">
          <InputSelect
            v-model="optionType.selectedValues"
            :options="optionType.values"
            class="mb-0 flex-fill"
          />
          <InlineButton
            v-if="optionType.selectedValues"
            @click="optionType.selectedValues = null"
            variant="link"
          >
            <b-icon icon="x-circle" variant="danger"></b-icon>
          </InlineButton>
        </div>
      </div>

      <b-form-invalid-feedback :state="!$v.form.optionValues.$error">
        {{ $t('validation.optionValueRequired') }}
      </b-form-invalid-feedback>

      <ConfirmDialog :id="deleteModalId" @ok="remove" />
    </b-form>
  </ModalMain>
</template>

<script>
import { isNil } from 'lodash';
import { validationMixin } from 'vuelidate';
import { required, minValue } from 'vuelidate/lib/validators';
import editModalMixin from '@/mixins/editModalMixin';
import ModalMain from '@/components/ui/ModalMain';
import InputString from '@/components/ui/input/InputString';
import InputMoney from '@/components/ui/input/InputMoney';
import InputSelect from '@/components/ui/input/InputSelect';
import InputDatepicker from '@/components/ui/input/InputDatepicker';
import InlineButton from '@/components/ui/button/InlineButton';
import ConfirmDialog from '@/components/ui/ConfirmDialog';

const defaultValuesForForm = () => ({
  masterPrice: 0,
  prices: [
    {
      amount: 0,
      type: 'SALE',
      startDate: '',
      endDate: '',
      status: true,
    },
  ],
  optionValues: [],
  sku: '',
  sizeString: '',
  deliveryTypeId: null,
  // width: null,
  // depth: null,
  // height: null,
  // weight: null,
});

export default {
  mixins: [validationMixin, editModalMixin],

  props: {
    optionTypes: Array,
    formData: {
      type: Object,
      default: null,
    },
    deliveryTypeList: Array,
    deliveryTypeListStatus: String,
  },

  data() {
    return {
      form: defaultValuesForForm(),
      formOptionTypes: [],
      deleteModalId: 'remove-price',
      priceIndexToRemove: null,
    };
  },

  validations: {
    form: {
      masterPrice: {
        required,
        minValue: minValue(100000),
      },
      prices: {
        $each: {
          amount: {
            required,
            minValue: minValue(100000),
          },
          startDate: {
            required,
          },
          endDate: {
            required,
          },
        },
      },
      optionValues: {
        required,
      },
      sku: {
        required,
      },
      // width: {
      //   required,
      // },
      // depth: {
      //   required,
      // },
      // height: {
      //   required,
      // },
      // weight: {
      //   required,
      // },
    },
  },

  watch: {
    formOptionTypes: {
      handler(newValue) {
        this.form.optionValues = newValue.reduce((array, item) => {
          if (item.type === 'MULTIPLE') {
            item.selectedValues.forEach(value => array.push({ id: value }));
          } else if (item.selectedValues) {
            array.push({ id: item.selectedValues });
          }
          return array;
        }, []);
      },
      deep: true,
    },
  },

  computed: {
    title() {
      return this.$t('product.addVariant');
    },
    saveButtonTitle() {
      return isNil(this.formData) ? this.$t('text.addButton') : this.$t('text.updateButton');
    },
  },

  methods: {
    updateForm() {
      if (this.formData) {
        const masterPrice = this.formData.prices.find(item => item.type === 'MASTER');
        const salePrices = this.formData.prices.filter(item => item.type === 'SALE');
        this.form = {
          deliveryTypeId: this.formData.deliveryTypeId ? this.formData.deliveryTypeId : null,
          masterPrice: masterPrice ? masterPrice.amount : 0,
          prices: salePrices,
          // optionValues: this.formData.optionValues,
          optionValues: [],
          sku: this.formData.sku,
          sizeString: this.formData.sizeString,
          // width: this.formData.width,
          // depth: this.formData.depth,
          // height: this.formData.height,
          // weight: this.formData.weight,
        };
      } else {
        this.form = defaultValuesForForm();
      }
      this.$v.$reset();
    },
    resetForm() {
      this.form = defaultValuesForForm();
      this.$v.$reset();
    },
    async onShown() {
      await this.updateForm();

      if (isNil(this.formData)) {
        this.formOptionTypes = this.optionTypes.map(item => {
          return {
            ...item,
            selectedValues: item.type === 'MULTIPLE' ? [] : null,
          };
        });

        return;
      }

      this.formOptionTypes = this.optionTypes.map(item => {
        if (item.type === 'MULTIPLE') {
          const selected = [];
          this.formData.optionValues.forEach(optionValue => {
            const found = item.values.find(value => value.id === optionValue.id);
            if (found) {
              selected.push(found.id);
            }
          });
          return {
            ...item,
            selectedValues: selected,
          };
        }

        let selected = null;
        this.formData.optionValues.forEach(optionValue => {
          const found = item.values.find(value => value.id === optionValue.id);
          if (found) {
            selected = found.id;
          }
        });
        return {
          ...item,
          selectedValues: selected,
        };
      });
    },
    onHidden() {
      this.$emit('onHidden');
      this.resetForm();
    },
    onCancel() {
      this.hideEditModal();
    },
    async onSave() {
      if (this.$v) {
        this.$v.form.$touch();
        if (this.$v.form.$anyError) {
          return;
        }
      }

      const payload = {
        id: this.formData ? this.formData.id : null,
        ...this.form,
        prices: [
          ...this.form.prices,
          {
            amount: this.form.masterPrice,
            type: 'MASTER',
            startDate: '',
            endDate: '',
            status: true,
          },
        ],
      };

      this.$emit('click', payload);
      this.hideEditModal();
    },
    addSalePrice() {
      this.form.prices.push({
        amount: 0,
        type: 'SALE',
        startDate: '',
        endDate: '',
        status: true,
      });
    },
    onRemove(index) {
      this.priceIndexToRemove = index;
      this.$bvModal.show(this.deleteModalId);
    },
    remove() {
      this.form.prices.splice(this.priceIndexToRemove, 1);
    },
  },

  components: {
    ModalMain,
    InputMoney,
    InputSelect,
    InlineButton,
    InputString,
    InputDatepicker,
    ConfirmDialog,
  },
};
</script>

<style lang="scss" scoped>
@import '../../../sass/variables';
@import '../../../sass/flexBox';

.product-variant-form-row {
  margin: -0.5rem;

  & > div {
    padding: 0.5rem;
  }
}
</style>
