export const PRODUCT_SALE_REQUEST = 'PRODUCT_SALE_REQUEST';
export const PRODUCT_SALE_REQUEST_SUCCESS = 'PRODUCT_SALE_REQUEST_SUCCESS';
export const PRODUCT_SALE_REQUEST_ERROR = 'PRODUCT_SALE_REQUEST_ERROR';

export const PRODUCT_SALE_LIST_REQUEST = 'PRODUCT_SALE_LIST_REQUEST';
export const PRODUCT_SALE_LIST_REQUEST_SUCCESS = 'PRODUCT_SALE_LIST_REQUEST_SUCCESS';
export const PRODUCT_SALE_LIST_REQUEST_ERROR = 'PRODUCT_SALE_LIST_REQUEST_ERROR';

export const PRODUCT_SALE_CHANGE_STATUS = 'PRODUCT_SALE_CHANGE_STATUS';
export const PRODUCT_SALE_CHANGE_STATUS_SUCCESS = 'PRODUCT_SALE_CHANGE_STATUS_SUCCESS';
export const PRODUCT_SALE_CHANGE_STATUS_ERROR = 'PRODUCT_SALE_CHANGE_STATUS_ERROR';
