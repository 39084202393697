import {
  ABOUT_APP_REQUEST,
  ABOUT_APP_REQUEST_SUCCESS,
  ABOUT_APP_REQUEST_ERROR,
  ABOUT_APP_UPDATE,
  ABOUT_APP_UPDATE_SUCCESS,
  ABOUT_APP_UPDATE_ERROR,
} from '@/store/actions/aboutApp';

import { handleRequestError } from '@/utils/error';
import Repository, { ABOUT_APP } from '@/repositories/RepositoryFactory';

const AboutAppRepository = Repository.get(ABOUT_APP);

const state = {
  status: '',
  aboutApp: {},
};

const getters = {
  aboutApp: state => state.aboutApp,
  aboutAppStatus: state => state.status,
};
const actions = {
  [ABOUT_APP_REQUEST]: async ({ commit }) => {
    commit(ABOUT_APP_REQUEST);
    try {
      const response = await AboutAppRepository.get();
      commit(ABOUT_APP_REQUEST_SUCCESS, response.data);
    } catch (error) {
      commit(ABOUT_APP_REQUEST_ERROR);
      handleRequestError(error);
    }
  },

  [ABOUT_APP_UPDATE]: async ({ commit }, payload) => {
    commit(ABOUT_APP_UPDATE);
    try {
      const response = await AboutAppRepository.update(payload.id, payload.data);
      commit(ABOUT_APP_UPDATE_SUCCESS, response.data);
    } catch (error) {
      commit(ABOUT_APP_UPDATE_ERROR);
      handleRequestError(error);
    }
  },
};

const mutations = {
  [ABOUT_APP_REQUEST]: state => {
    state.status = 'loading';
  },
  [ABOUT_APP_REQUEST_SUCCESS]: (state, payload) => {
    state.status = 'success';
    state.aboutApp = payload.data;
  },
  [ABOUT_APP_REQUEST_ERROR]: state => {
    state.status = 'error';
  },
  [ABOUT_APP_UPDATE]: state => {
    state.status = 'loading';
  },
  [ABOUT_APP_UPDATE_SUCCESS]: (state, payload) => {
    state.status = 'success';
    state.aboutApp = payload.data;
  },
  [ABOUT_APP_UPDATE_ERROR]: state => {
    state.status = 'error';
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
