<template>
  <BaseLayout :title="title">
    <template #actions>
      <InputSelect
        v-model="category"
        :options="categoryList"
        :disabled="isLoadingCategories"
        :first-item-label="$t('label.allCategory')"
        :first-item-disabled="false"
        pill
        @input="onChangeCategory"
        class="product-select-category mb-0"
      />
      <InputSearch
        v-model="search"
        @input="onSearch"
        @clear="onClearSearch"
        class="product-search-input mb-0"
      />
      <InlineButton @click="onCreate" pill shadow>
        <span class="text-uppercase">{{ $t('text.newButton') }}</span>
      </InlineButton>
    </template>

    <ProductTable
      :products="productList"
      :meta="productListMeta"
      :current-page="currentPage"
      :is-loading="isLoading"
      @edit="onEdit"
      @remove="onRemove"
      @onChangePage="onChangePage"
    />

    <ConfirmDialog
      id="delete-confirm-dialog"
      :message="$t('modal.areYouSureYouWantToRemove')"
      :ok-title="$t('text.deleteButton')"
      ok-variant="danger"
      @ok="removeProduct"
    />
  </BaseLayout>
</template>

<script>
import { mapGetters } from 'vuex';
import { PRODUCT_LIST_REQUEST, PRODUCT_DELETE } from '@/store/actions/product';
import { CATEGORY_LIST_REQUEST } from '@/store/actions/productCategory';
import { KEY_FILTER_NAME, KEY_FILTER_CATEGORY_ID } from '@/utils/filter';
import BaseLayout from '@/components/ui/BaseLayout';
import InputSelect from '@/components/ui/input/InputSelect';
import InputSearch from '@/components/ui/input/InputSearch';
import InlineButton from '@/components/ui/button/InlineButton';
import ProductTable from '@/components/manage/products/ProductTable';
import ConfirmDialog from '@/components/ui/ConfirmDialog';
import { DEBOUNCE_MILLISECONDS } from '@/config';

export default {
  props: {
    title: String,
  },

  data() {
    return {
      currentPage: 1,
      productIdToRemove: null,
      category: null,
      search: '',
      timeout: null,
    };
  },

  computed: {
    ...mapGetters([
      'productList',
      'productListMeta',
      'productListStatus',
      'categoryList',
      'categoryListStatus',
    ]),
    isLoading() {
      return this.productListStatus === 'loading';
    },
    isLoadingCategories() {
      return this.categoryListStatus === 'loading';
    },
  },

  created() {
    const payload = {
      limit: 1000,
      page: 1,
      filter: {},
    };
    this.$store.dispatch(CATEGORY_LIST_REQUEST, payload);
    this.fetchData();
  },

  methods: {
    fetchData(search) {
      const payload = {
        limit: 20,
        page: search ? 1 : this.currentPage,
        filter: {},
      };
      if (this.search) {
        payload.filter[`${KEY_FILTER_NAME}`] = this.search;
      }
      if (this.category) {
        payload.filter[`${KEY_FILTER_CATEGORY_ID}`] = this.category;
      }
      this.$store.dispatch(PRODUCT_LIST_REQUEST, payload);
    },
    onChangeCategory() {
      this.fetchData();
    },
    onClearSearch() {
      this.search = '';
      this.fetchData();
    },
    onSearch() {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.fetchData(true);
      }, DEBOUNCE_MILLISECONDS);
    },
    onCreate() {
      this.$router.push({ name: 'ProductAdd' });
    },
    onEdit(productId) {
      this.$router.push({ name: 'ProductEdit', params: { productId } });
    },
    openModal() {
      this.$bvModal.show('manage-category-modal');
    },
    onChangePage(page) {
      this.currentPage = page;
      this.fetchData();
    },
    onRemove(productId) {
      this.productIdToRemove = productId;
      this.$bvModal.show('delete-confirm-dialog');
    },
    async removeProduct() {
      try {
        await this.$store.dispatch(PRODUCT_DELETE, this.productIdToRemove);
      } finally {
        this.productIdToRemove = null;
      }
    },
  },

  components: {
    BaseLayout,
    InputSelect,
    InputSearch,
    InlineButton,
    ProductTable,
    ConfirmDialog,
  },
};
</script>
