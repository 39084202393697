<template>
  <TableBox :current-page="currentPage" :meta="meta" @onChangePage="$emit('onChangePage', $event)">
    <b-table
      sticky-header
      id="my-table"
      :fields="fields"
      :items="orders"
      :busy="isLoading"
      :emptyText="$t('text.emptyText')"
      show-empty
    >
      <template #cell(receiptNumber)="data">
        <span class="text-nowrap">
          {{ data.item.receiptNumber }}
        </span>
      </template>

      <template #cell(createdAt)="data">
        <span class="text-nowrap">
          {{
            data.item.createdAt
              ? $options.filters.moment(data.item.createdAt, 'YYYY-MM-DD hh:mm')
              : ''
          }}
        </span>
      </template>

      <template #cell(createdBy)="data">
        <p v-if="data.item.shop" class="text-nowrap">
          {{ data.item.shop.name }}
        </p>
        <p v-if="data.item.creator" class="text-black-50 text-nowrap">
          {{ data.item.creator | fullName }}
        </p>
      </template>

      <template #cell(productQuantity)="data">
        {{ data.item.items.length }}
      </template>

      <template #cell(total)="data">
        {{ totalDue(data.item) | currency }}
      </template>

      <template #cell(status)="data">
        <Status :value="data.item.status" />
      </template>

      <template #cell(customer)="data">
        <span v-if="data.item.user" class="text-nowrap">
          {{ data.item.user | fullName }}
          <template v-if="data.item.user.phone">, {{ data.item.user.phone }}</template>
        </span>
      </template>

      <template #cell(action)="data">
        <div class="table-action-buttons">
          <InlineButton
            variant="outline-secondary"
            size="sm"
            pill
            @click="$emit('show', data.item.id)"
            class="has-shadow"
          >
            {{ $t('text.viewButton') }}
          </InlineButton>
        </div>
      </template>
    </b-table>
  </TableBox>
</template>

<script>
import TableBox from '@/components/ui/TableBox';
import Status from '@/components/ui/Status';
import InlineButton from '@/components/ui/button/InlineButton';

export default {
  props: {
    orders: Array,
    meta: Object,
    currentPage: Number,
    isLoading: Boolean,
  },

  data() {
    return {
      fields: [
        {
          key: 'receiptNumber',
          label: this.$t('table.th.id'),
        },
        {
          key: 'productQuantity',
          label: this.$t('table.th.productQuantity'),
        },
        {
          key: 'total',
          label: this.$t('table.th.totalPaid'),
        },
        {
          key: 'status',
          label: this.$t('table.th.status'),
        },
        {
          key: 'customer',
          label: this.$t('table.th.customer'),
        },
        {
          key: 'createdAt',
          label: this.$t('table.th.date'),
        },
        {
          key: 'createdBy',
          label: this.$t('table.th.createdBy'),
        },
        {
          key: 'action',
          label: this.$t('table.th.settings'),
        },
      ],
    };
  },

  methods: {
    totalDue(payload) {
      let total = 0;
      if (payload?.payments) {
        const indexOfCash = payload.payments.findIndex(item => item.paymentType === 'CASH');
        if (indexOfCash > -1) {
          total += parseInt(payload.payments[indexOfCash].paidAmount.amount, 10);
        }

        const indexOfCard = payload.payments.findIndex(item => item.paymentType === 'CARD');
        if (indexOfCard > -1) {
          total += parseInt(payload.payments[indexOfCard].paidAmount.amount, 10);
        }

        const indexOfOther = payload.payments.findIndex(item => item.paymentType === 'OTHER');
        if (indexOfOther > -1) {
          total += parseInt(payload.payments[indexOfOther].paidAmount.amount, 10);
        }

        const indexOfQpay = payload.payments.findIndex(item => item.paymentType === 'QPAY');
        if (indexOfQpay > -1) {
          total += parseInt(payload.payments[indexOfQpay].paidAmount.amount, 10);
        }

        const indexOfBankTransfer = payload.payments.findIndex(
          item => item.paymentType === 'BANK_TRANSFER'
        );
        if (indexOfBankTransfer > -1) {
          total += parseInt(payload.payments[indexOfBankTransfer].paidAmount.amount, 10);
        }
      }
      return total;
    },
  },

  components: {
    TableBox,
    Status,
    InlineButton,
  },
};
</script>
