<template>
  <BaseLayout :title="title">
    <template #actions>
      <InlineButton @click="onCreate" pill shadow>
        <span class="text-uppercase">{{ $t('text.newButton') }}</span>
      </InlineButton>
    </template>

    <BlogTable
      :list="blogList"
      :meta="blogListMeta"
      :current-page="currentPage"
      :is-loading="isLoading"
      :business-list="businessList"
      :is-admin="me.isAdmin"
      @edit="onEdit"
      @onChangePage="onChangePage"
    />

    <BlogAddEditModal
      :form-data="dataToEdit"
      :modal-id="editModalId"
      :business-list="businessList"
      :business-id="me.businessId"
      :is-admin="me.isAdmin"
      @onHidden="emptyingDataToEdit"
      @onRemove="onConfirmDelete"
    />

    <ConfirmDialog :id="deleteModalId" @ok="remove" />
  </BaseLayout>
</template>

<script>
import { mapGetters } from 'vuex';
import { BLOG_LIST_REQUEST, BLOG_DELETE } from '@/store/actions/blog';
import { BUSINESS_LIST_REQUEST } from '@/store/actions/business';
import meMixin from '@/mixins/meMixin';
import BaseLayout from '@/components/ui/BaseLayout';
import InlineButton from '@/components/ui/button/InlineButton';
import BlogTable from '@/components/manage/blogs/BlogTable';
import BlogAddEditModal from '@/components/manage/blogs/BlogAddEditModal';
import ConfirmDialog from '@/components/ui/ConfirmDialog';

export default {
  mixins: [meMixin],

  props: {
    title: String,
  },

  data() {
    return {
      currentPage: 1,
      dataIdToEdit: null,
      dataIdToRemove: null,
      editModalId: 'manage-form-modal',
      deleteModalId: 'manage-delete-modal',
    };
  },

  computed: {
    ...mapGetters([
      'blogList',
      'blogListMeta',
      'blogListStatus',
      'getBlogById',
      'blogStatus',
      'businessList',
    ]),
    dataToEdit() {
      return this.dataIdToEdit ? this.getBlogById(this.dataIdToEdit) : null;
    },
    isLoading() {
      return this.blogListStatus === 'loading';
    },
  },

  created() {
    this.fetchData();
  },

  methods: {
    async fetchData() {
      const payload = {
        limit: 10,
        page: this.currentPage,
        businessId: !this.me.isAdmin ? this.me.businessId : null,
        filter: {},
      };
      await this.$store.dispatch(BLOG_LIST_REQUEST, payload);

      if (this.me.isAdmin) {
        const payloadToBusiness = {
          limit: 100,
          page: 1,
        };
        this.$store.dispatch(BUSINESS_LIST_REQUEST, payloadToBusiness);
      }
    },
    emptyingDataToEdit() {
      this.dataIdToEdit = null;
    },
    onCreate() {
      this.emptyingDataToEdit();
      this.openModal();
    },
    onEdit(id) {
      this.dataIdToEdit = id;
      this.openModal();
    },
    openModal() {
      this.$bvModal.show(this.editModalId);
    },
    onChangePage(page) {
      this.currentPage = page;
      this.fetchData();
    },
    onConfirmDelete(id) {
      this.dataIdToRemove = id;
      this.$bvModal.show(this.deleteModalId);
    },
    async remove() {
      await this.$store.dispatch(BLOG_DELETE, this.dataIdToRemove);
      if (this.blogStatus === 'deleted') {
        this.emptyingDataToEdit();
        this.$bvModal.hide(this.editModalId);
        this.dataIdToRemove = null;
      }
    },
  },

  components: {
    BaseLayout,
    InlineButton,
    BlogTable,
    BlogAddEditModal,
    ConfirmDialog,
  },
};
</script>
