<template>
  <div class="dashboard-header">
    <b-row class="dashboard-header-row">
      <b-col cols="12" md="6" xl="">
        <div class="dashboard-header-item">
          <p>{{ dashboardData.products }}</p>
          <p>{{ $t('dashboard.activeProducts') }}</p>
        </div>
      </b-col>
      <b-col cols="12" md="6" xl="" class="mt-4 mt-md-0">
        <div class="dashboard-header-item">
          <p>{{ dashboardData.orders }}</p>
          <p>{{ $t('dashboard.totalOrders') }}</p>
        </div>
      </b-col>
      <b-col cols="12" md="6" xl="" class="mt-4 mt-xl-0">
        <div class="dashboard-header-item">
          <p>{{ dashboardData.completedOrders }}</p>
          <p>{{ $t('dashboard.totalCompletedOrders') }}</p>
        </div>
      </b-col>
      <b-col cols="12" md="6" xl="" class="mt-4 mt-xl-0">
        <div class="dashboard-header-item">
          <p>{{ dashboardData.pendingOrders }}</p>
          <p>{{ $t('dashboard.totalPendingOrders') }}</p>
        </div>
      </b-col>
      <b-col cols="12" md="6" xl="" class="mt-4 mt-xl-0">
        <div class="dashboard-header-item">
          <p>{{ dashboardData.users }}</p>
          <p>{{ $t('dashboard.totalUsers') }}</p>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  props: {
    dashboardList: Object,
  },

  computed: {
    dashboardData() {
      const dashboard = this.dashboardList.count;
      if (dashboard) {
        return {
          products: dashboard.products ? dashboard.products : 0,
          orders: dashboard.orders ? dashboard.orders : 0,
          completedOrders: dashboard.completedOrders ? dashboard.completedOrders : 0,
          pendingOrders: dashboard.pendingOrders ? dashboard.pendingOrders : 0,
          users: dashboard.users ? dashboard.users : 0,
          newUsers: dashboard.newUsers ? dashboard.newUsers : 0,
        };
      }

      return {
        products: 0,
        orders: 0,
        completedOrders: 0,
        users: 0,
        newUsers: 0,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../../sass/variables';

.dashboard-header {
  padding-bottom: 1.5rem;
}

.dashboard-header-row {
  margin: 0 0.75rem;

  & > div {
    padding: 0;
  }
}

.dashboard-header-item {
  border: 2px solid black;
  padding: 1.5rem;
  margin: 0 0.75rem;
  border-radius: 1.25rem;
  box-shadow: 0 5px 12px rgba($black, 0.1);
  border: solid 1px $light;
  height: 100%;

  p {
    text-transform: uppercase;
    color: black;
    margin-bottom: 0;

    & + p {
      font-size: $font-size-sm;
      text-transform: uppercase;
      color: $gray-500;
    }

    // & + p {
    //   color: $white;
    // }
  }
  p {
    &:first-child {
      font-weight: bold;
    }
  }
}

@media (min-width: $breakpoint-sm) {
  .dashboard-header-item {
    p:first-child {
      font-size: 1.25rem;
    }
  }
}

@media (min-width: $breakpoint-xxl) {
  .dashboard-header-item {
    p:first-child {
      font-size: 1.5rem;
    }
  }
}
</style>
