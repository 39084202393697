import Vue from 'vue';
import VueRouter from 'vue-router';
import manageRoutes from '@/router/manage';
import NotFound from '@/views/NotFound';
import Other from '@/views/Other';

Vue.use(VueRouter);

const routes = [
  ...manageRoutes,
  {
    path: '/terms-condition',
    name: 'TermsCondition',
    component: Other,
  },
  {
    path: '/privacy-policy',
    name: 'PrivacyPolicy',
    component: Other,
  },
  {
    // will match everything
    path: '*',
    name: 'NotFound',
    component: NotFound,
  },
];

const router = new VueRouter({
  mode: 'history',
  routes,
});

export default router;
