<template>
  <ModalMain
    :id="modalId"
    :title="$t('customers.editProfile')"
    :ok-title="$t('customers.updateProfileButton')"
    :loading="isLoading"
    delete-disabled
    @show="onShow"
    @hidden="onHidden"
    @cancel="onCancel"
    @ok="onSave"
  >
    <b-form>
      <InputString
        class="customer-details-name"
        :label="$t('label.name')"
        size="lg"
        v-model="$v.form.firstName.$model"
        :vuelidate="$v.form.firstName"
      />
      <InputString
        class="customer-details-last-name"
        :label="$t('label.lastName')"
        size="lg"
        v-model="$v.form.lastName.$model"
        :vuelidate="$v.form.lastName"
      />
      <InputString
        class="customer-details-email"
        :label="$t('label.email')"
        size="lg"
        v-model="$v.form.email.$model"
        :vuelidate="$v.form.email"
        :disabled="!me.isAdmin"
      />
      <InputString
        class="customer-details-phone"
        :label="$t('label.phone')"
        size="lg"
        v-model="$v.form.phone.$model"
        :vuelidate="$v.form.phone"
        :disabled="!me.isAdmin"
      />
      <InputSelect
        v-if="me.isAdmin"
        class="customer-details-business"
        :label="$t('label.business')"
        size="lg"
        v-model="form.businessId"
        :options="businessList"
        :first-item-label="$t('label.notSelectBusiness')"
        :first-item-disabled="false"
      />
    </b-form>
  </ModalMain>
</template>

<script>
import { mapGetters } from 'vuex';
import editModalMixin from '@/mixins/editModalMixin';
import meMixin from '@/mixins/meMixin';
import { validationMixin } from 'vuelidate';
import { required, helpers, email as isEmail } from 'vuelidate/lib/validators';
import { CUSTOMER_PROFILE_UPDATE } from '@/store/actions/customer';
import { BUSINESS_LIST_REQUEST } from '@/store/actions/business';
import phoneRegex from '@/utils/phone';
import ModalMain from '@/components/ui/ModalMain';
import InputString from '@/components/ui/input/InputString';
import InputSelect from '@/components/ui/input/InputSelect';

export default {
  mixins: [editModalMixin, validationMixin, meMixin],

  props: {
    customerDetails: Object,
  },

  data() {
    return {
      form: {
        businessId: null,
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        isActive: true,
      },
    };
  },

  validations() {
    return {
      form: {
        firstName: {
          required,
        },
        lastName: {
          required,
        },
        email: {
          required,
          isEmail,
        },
        phone: {
          phone: helpers.regex('phone', phoneRegex),
        },
      },
    };
  },

  computed: {
    ...mapGetters(['customerStatus', 'businessList', 'businessListStatus']),
    isLoading() {
      return this.customerStatus === 'loading';
    },
  },

  methods: {
    async fetchData() {
      const payload = {
        limit: 100,
        page: 1,
      };
      this.$store.dispatch(BUSINESS_LIST_REQUEST, payload);
    },
    onShow() {
      const { businessId, firstName, lastName, email, phone, isActive } = this.customerDetails;
      this.form = {
        businessId,
        firstName,
        lastName,
        email,
        phone,
        isActive,
      };
      this.$v.$reset();

      if (this.me.isAdmin) {
        this.fetchData();
      }
    },
    onHidden() {
      this.$emit('onHidden');
    },
    onCancel() {
      this.hideEditModal();
    },
    async onSave() {
      if (this.$v) {
        this.$v.form.$touch();
        if (this.$v.form.$anyError) {
          return;
        }
      }

      const data = {
        ...this.form,
      };
      const payload = {
        id: this.customerDetails.id,
        data,
      };
      await this.$store.dispatch(CUSTOMER_PROFILE_UPDATE, payload);
      if (this.customerStatus === 'success') {
        this.hideEditModal();
      }
    },
  },

  components: {
    ModalMain,
    InputString,
    InputSelect,
  },
};
</script>
