<template>
  <ModalMain
    :id="modalId"
    :title="title"
    :ok-title="saveButtonTitle"
    :loading="isLoading"
    :delete-disabled="!formData"
    @hidden="onHidden"
    @show="onShow"
    @ok="onSave"
    @cancel="onCancel"
    @remove="$emit('onRemove', formData.id)"
  >
    <b-form @submit.prevent="onSave">
      <CategorySelectInput
        class="category"
        :label="$t('label.category')"
        size="lg"
        v-model="form.parentId"
        :options="categoryList"
        value-field="id"
      />
      <InputString
        class="name"
        :label="$t('label.name')"
        size="lg"
        v-model="$v.form.name.$model"
        :vuelidate="$v.form.name"
      />

      <b-row class="mb-3">
        <b-col cols="6" sm="4">
          <InputLabel>{{ $t('label.photo') }}</InputLabel>
          <InputPhoto
            :is-loading="isLoading"
            :photo="form.image"
            @change="onChangePhoto"
            @remove="onDeletePhoto"
          />
        </b-col>
        <b-col cols="6" sm="8">
          <InputString class="icon" :label="$t('label.icon')" size="lg" v-model="form.icon" />
        </b-col>
      </b-row>

      <InputRadioButton
        class="status"
        :label="$t('label.status')"
        size="lg"
        v-model="form.status"
        :options="statusOptions"
      />
      <InputCheckBoxGroup
        class="option-types"
        :label="$t('label.pleaseChooseTypesToUse')"
        size="lg"
        check-all-option
        :options="optionTypeList"
        v-model="form.optionTypes"
        :get-label="option => option.name"
        :get-value="option => option.id"
      />
    </b-form>
  </ModalMain>
</template>

<script>
import { isNil } from 'lodash';
import { mapGetters } from 'vuex';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import { OPTION_TYPE_LIST_REQUEST } from '@/store/actions/optionType';
import {
  CATEGORY_CREATE,
  CATEGORY_UPDATE,
  CATEGORY_LIST_REQUEST,
} from '@/store/actions/productCategory';
import { FILE_UPLOAD } from '@/store/actions/file';
import { STATUS_OPTIONS } from '@/utils/constants';
import editModalMixin from '@/mixins/editModalMixin';
import ModalMain from '@/components/ui/ModalMain';
import CategorySelectInput from '@/components/manage/categories/CategorySelectInput';
import InputString from '@/components/ui/input/InputString';
import InputRadioButton from '@/components/ui/input/InputRadioButton';
import InputCheckBoxGroup from '@/components/ui/input/InputCheckBoxGroup';
import InputPhoto from '@/components/ui/input/InputPhoto';
import InputLabel from '@/components/ui/input/InputLabel';

const defaultValuesForForm = () => ({
  name: '',
  icon: '',
  image: null,
  parentId: null,
  status: true,
  optionTypes: [],
});

export default {
  mixins: [validationMixin, editModalMixin],

  props: {
    formData: Object,
  },

  data() {
    return {
      form: defaultValuesForForm(),
      statusOptions: STATUS_OPTIONS,
    };
  },

  validations: {
    form: {
      name: {
        required,
      },
    },
  },

  watch: {
    formData() {
      this.updateForm();
    },
  },

  computed: {
    ...mapGetters([
      'categoryStatus',
      'categoryList',
      'categoryListStatus',
      'optionTypeList',
      'optionTypeListStatus',
      'getFileUpload',
    ]),
    isLoading() {
      return this.categoryStatus === 'loading';
    },
    title() {
      return isNil(this.formData)
        ? this.$t('category.newCategory')
        : this.$t('category.editCategory');
    },
    saveButtonTitle() {
      return isNil(this.formData) ? this.$t('text.addButton') : this.$t('text.updateButton');
    },
    isUploadingPhoto() {
      return this.getFileUpload.status === 'loading';
    },
  },

  methods: {
    async onShow() {
      const payload = {
        filter: {},
      };
      await this.$store.dispatch(CATEGORY_LIST_REQUEST, payload);
      this.$store.dispatch(OPTION_TYPE_LIST_REQUEST);
    },
    updateForm() {
      this.form = this.formData
        ? {
            name: this.formData.name,
            icon: this.formData.icon,
            image: this.formData.image,
            parentId: this.formData.parentId,
            status: this.formData.status,
            optionTypes: this.formData.optionTypes
              ? this.formData.optionTypes.map(item => item.id)
              : [],
          }
        : defaultValuesForForm();
      this.$v.$reset();
    },
    resetForm() {
      this.form = defaultValuesForForm();
      this.$v.$reset();
    },
    onHidden() {
      this.$emit('onHidden');
      this.resetForm();
    },
    onCancel() {
      this.hideEditModal();
    },
    async onSave() {
      if (this.$v) {
        this.$v.form.$touch();
        if (this.$v.form.$anyError) {
          return;
        }
      }

      const optionTypes = this.form.optionTypes.map(optionTypeId => {
        return { id: optionTypeId };
      });

      const data = { ...this.form, optionTypes };

      if (isNil(this.formData)) {
        await this.$store.dispatch(CATEGORY_CREATE, data);
      } else {
        const payload = {
          id: this.formData.id,
          data,
        };
        await this.$store.dispatch(CATEGORY_UPDATE, payload);
      }
      if (this.categoryStatus === 'success') {
        this.hideEditModal();
      }
    },
    async onChangePhoto(e) {
      const file = e.target.files[0];
      await this.$store.dispatch(FILE_UPLOAD, file);
      if (this.getFileUpload.status === 'success') {
        this.form.image = this.getFileUpload.item ? this.getFileUpload.item : null;
      }
    },
    onDeletePhoto() {
      this.form.image = null;
    },
  },

  components: {
    ModalMain,
    CategorySelectInput,
    InputString,
    InputRadioButton,
    InputCheckBoxGroup,
    InputPhoto,
    InputLabel,
  },
};
</script>
