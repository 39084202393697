export const OPTION_TYPE_LIST_REQUEST = 'OPTION_TYPE_LIST_REQUEST';
export const OPTION_TYPE_LIST_REQUEST_SUCCESS = 'OPTION_TYPE_LIST_REQUEST_SUCCESS';
export const OPTION_TYPE_LIST_REQUEST_ERROR = 'OPTION_TYPE_LIST_REQUEST_ERROR';

export const OPTION_TYPE_CREATE = 'OPTION_TYPE_CREATE';
export const OPTION_TYPE_CREATE_SUCCESS = 'OPTION_TYPE_CREATE_SUCCESS';
export const OPTION_TYPE_CREATE_ERROR = 'OPTION_TYPE_CREATE_ERROR';

export const OPTION_TYPE_UPDATE = 'OPTION_TYPE_UPDATE';
export const OPTION_TYPE_UPDATE_SUCCESS = 'OPTION_TYPE_UPDATE_SUCCESS';
export const OPTION_TYPE_UPDATE_ERROR = 'OPTION_TYPE_UPDATE_ERROR';

export const OPTION_TYPE_DELETE = 'OPTION_TYPE_DELETE';
export const OPTION_TYPE_DELETE_SUCCESS = 'OPTION_TYPE_DELETE_SUCCESS';
export const OPTION_TYPE_DELETE_ERROR = 'OPTION_TYPE_DELETE_ERROR';
